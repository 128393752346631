<template>
	<div id="new-order-modal-component">
		<modal-component ref="modal" @onModalClose="onModalClose()">
			<div class="container">
				<div class="content">
					<div class="title">
						{{ isEdit ? 'Edit table number' : 'Start new order'}}
					</div>
					<label>
						Table
						<input type="number" v-model="tableNumber" placeholder="Table number" @keyup="onKeyUp"/>
					</label>
					<div class="seat-wrapper ">
						<div class="text seat-text bold">Seat:</div>
						<div class="changer">
							<button @click="changeSeatNumber('decrease')">
								<img v-if="design === 'ecruise'" src="@/assets/img/minus-big.svg">
								<img v-else-if="design === 'margaritaville'" src="@/assets/img/btninmodal-m-big.svg">
							</button>
							<!--<input type="number" v-model="seat" step="1" min="1" max="100" id="age"/>-->
							<span>{{seat}}</span>
							<button @click="changeSeatNumber('increase')">
								<img v-if="design === 'ecruise'" src="@/assets/img/plus-big.svg">
								<img v-else-if="design === 'margaritaville'" src="@/assets/img/btninmodal-plus-m-big.svg">
							</button>
						</div>
					</div>
					<keypad :dot="false" @clickOnKey="keypadClick" @clear="keypadClear"></keypad>
				</div>
				<div class="controls">
					<button class="def-btn cancel-action-btn uppercase medium border-2" @click="closeModal">Cancel</button>
					<button class="def-btn secondary-action-btn uppercase medium border-2" @click="createOrder(true)">Skip</button>
					<button class="def-btn main-action-btn uppercase medium border-2" :class="{'disabled': !tableNumber}" @click="createOrder(false, false)">{{ isEdit ? 'Save changes' : 'Create Order'}}</button>
				</div>
			</div>
		</modal-component>
		<confirmation-modal-component ref="confirmation-modal" :has-cancel-btn="false"></confirmation-modal-component>
		<set-the-payer-modal ref="set-the-payer-modal" :new-order-creation="true" :selected-order="null" @onSetPayer="setPayer"></set-the-payer-modal>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import Keypad from "@/components/_shared/Keypad";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import {orderService} from "@/_api/order.api";
import SetThePayerModal from "@/components/_modals/SetThePayerModal";
export default {
	name: 'NewOrderModalComponent',
	emits: ['onTableSetup'],
	props: {
		isEdit: {
			default: false,
			required: false
		},
		setPayerAfter: {
			default: 0,
			required: false,
			type: Number
		},
		noBusinessLogic: {
			default: false,
			required: false,
			type: Boolean
		}
    },
	components: {SetThePayerModal, ConfirmationModalComponent, Keypad, ModalComponent},
	data() {
		return {
			tableNumber: '',
            allTables:null,
			seat: 1,
			showSetThePayerModal: false
		}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
		getPosData: function () {
			return this.$store.state.auth.posData
		}
	},
	created() {},
	mounted() {
		this.emmiter.on('closeModal', () => {
			if (this.$refs['modal']) {
				this.$refs['modal'].close()
			}
		});
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
	methods: {
		changeSeatNumber(type) {
			if (type === 'decrease') {
				this.seat > 1 ? this.seat -= 1 : null
			} else {
				this.seat += 1;
			}
		},
		onKeyUp(e, keypad) {
			let q = keypad ? parseInt(e) : parseInt(e.target.value);
			if ((q === 0) && this.tableNumber.toString().length === 1) {
				this.tableNumber = '';
			}
		},
		onModalClose() {
			this.tableNumber = ''
		},
		openModal(data) {
			if (this.isEdit) {
				this.tableNumber = data.table ? data.table.toString() : '';
				this.seat = data.seat ? data.seat : 1;
				this.$forceUpdate();
			}
			this.$refs['modal'].show()
		},
		closeModal() {
			this.$refs['modal'].close()
		},
		openConfirmationModal() {
			this.$refs['confirmation-modal'].show({
				message: `Table number ${this.tableNumber} is already in use`,
				okButton: 'OK'
			})
		},
        openConfirmationModalNotTable() {
            this.$refs['confirmation-modal'].show({
                message: `There are only ${this.allTables.length} tables`,
                okButton: 'OK'
            })
        },
		setPayer(data) {
			this.createOrder(false, data);
		},
        createOrder(skip, payer_data) {
	        console.log(skip);
			
	        let guestRequired = this.getPosData.department.flow_ordering_guest;
			
			if (this.noBusinessLogic) {
				this.$emit('onTableSetup', {
					table: skip ? '' : this.tableNumber,
					seat: skip ? null : this.seat
				});
				this.$refs['modal'].close();
			} else {
				if (this.isEdit) {
					this.emmiter.emit('changeOrderTable', {
						table: skip ? '' : this.tableNumber,
						seat: skip ? null : this.seat
					});
				} else {
					orderService.getOccupiedTables().then((res) => {
						console.log(res.includes(parseInt(this.tableNumber)));
						
						let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
						if (res.includes(parseInt(this.tableNumber)) || (tempOrders && tempOrders.findIndex((o) => {return o.table === this.tableNumber}) !== -1)) {
							this.openConfirmationModal()
						} else {
							if (guestRequired && !payer_data) {
								this.showSetThePayerModal = true;
								this.$nextTick(() => {
									this.$refs['set-the-payer-modal'].openModal();
								})
							} else {
								this.emmiter.emit('addTempOrder', {
									table: skip ? '' : this.tableNumber,
									seat: skip ? null : this.seat,
									payers: payer_data ? [payer_data] : null
								});
								this.$refs['modal'].close();
							}
						}
					}).catch((err) => {
						console.log(err);
					})
				}
			}
			
	       
	       
        },
		keypadClick(e) {
			this.tableNumber += e.toString();
			this.onKeyUp(e, true)
		},
		keypadClear() {
			this.tableNumber = this.tableNumber.slice(0, -1)
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>
#new-order-modal-component {
	.container {
		width: 500px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
	}
	.title {
		// color: var(--theme_color);
		font-size: 42px;
		text-align: center;
		font-weight: 400;
		margin-bottom: 20px;
	}
	label {
		width: 100%;
		margin-bottom: 20px;
		text-align: left;
		font-weight: 600;
		font-size: 14px;
		color: #1C282D;
		justify-content: flex-start;
		display: flex;
		flex-wrap: wrap;
		input {
			width: 100%;
			box-sizing: border-box;
			border: none;
			// border-bottom: 2px solid var(--theme_color);
			font-size: 18px;
			padding: 10px;
			color: #1C282D;
			-moz-appearance:textfield; /* Firefox */
			&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
				/* display: none; <- Crashes Chrome on hover */
				-webkit-appearance: none;
				margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
			}
		}
	}
	.seat-wrapper {
		margin: 0 auto 20px 0;
		.text {
			width: 100%;
			margin-bottom: 10px;
			text-align: left;
			font-weight: 600;
			font-size: 14px;
			color: #1C282D;
		}
		button {
			border: none;
			background: white;
			cursor: pointer;
		}
		.changer {
			display: flex;
			align-items: center;
			span {
				margin: 0 20px;
			}
		}
	}
	::v-deep(.keypad) {
		border: none;
		background: none;
		margin: 0 auto;
		.flex-row {
			padding: 0;
		}
	}
	.controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		width: 100%;
		button {
			width: 32%;
			/*&:first-child {
				margin-right: 10px;
			}*/
		}
	}
	::v-deep(#confirmation-modal) {
		.window {
			width: 455px;
			.message {
				// color: @theme_color;
				font-size: 42px;
				text-align: center;
				font-weight: 400;
			}
			.btns {
				justify-content: center;
				.main-action-btn {
					width: 180px;
				}
			}
		}
	}
}

</style>
