<template>
    <div id="pos-root" class="pos-root">
        <orders-list-component
	        ref="orders-list-component"
            @onOrderSelect="changSelectOrder"
            :orders="orders"
            :selectOrder="selectOrder"
            @infinityScroll="ordersListScrollHandle"
        ></orders-list-component>
	    <menu-component
			    @addItem="addItem"
			    :selectOrder="selectOrder"
	    ></menu-component>
	    <selected-order-component
			    ref="selected-order-component"
			    @upload="getOrders()"
			    @deleteItem="deleteItem"
			    @editItems="editItems"
			    @changeItemQty="changeItemQty"
			    :selectOrder="selectOrder"
			    @addRound="addRound"
	    ></selected-order-component>
    </div>
</template>

<script>
import SelectedOrderComponent from "@/components/pos/SelectedOrder";
import OrdersListComponent from "@/components/pos/OrdersList";
import MenuComponent from "@/components/pos/Menu";
import ModalComponent from "@/components/_shared/Modal";
import AddEditItemModal from "@/components/_modals/AddEditItemModal";
import {orderService} from "@/_api/order.api";
import {menuService} from "@/_api/menu.api";
import {auth} from "@/store/auth.module";
import store from "@/store";

export default {
    name: 'PosRootComponent',
    props: {},
    components: {
	    AddEditItemModal, MenuComponent, ModalComponent, OrdersListComponent, SelectedOrderComponent
    },
    data() {
        return {
            selectOrder: null,
            orders: [],
            total: 0,
            page: 0,
            per_page: 9,
            searchOrder: null,
	        loading: false
        }
    },
    computed: {
	    getUser () {
            return this.$store.state.auth.user
        },
    },
    created() {
        this.getOrders(true);
    },
    mounted() {
        this.emmiter.on('refreshOrderList', (reset) => {
	        console.log(reset);
            this.getOrders(reset);
        });
        this.emmiter.on('addTempOrder', (data) => {
			console.log(data);
            this.createTempOrder(data.table, data.seat, data.payers ? data.payers[0] : null)
        });
        this.emmiter.on('setPayer', (data) => {
            this.setPayer(data);
        });
		this.emmiter.on('setWaiter', (data) => {
			console.log(data);
            this.setWaiter(data);
        });
        this.emmiter.on('changeOrderTable', (data) => {
            this.changeOrderTable(data.table, data.seat);
        });
        this.emmiter.on('debounceSearch', (e) => {
            this.debounceSearch(e);
        });
        this.emmiter.on('changeItemStatus', (e) => {
            this.changeItemStatus(e);
        });
		this.emmiter.on('combineOrders', (data) => {
            this.combineOrders(data);
        });
		this.emmiter.on('splitOrder', (data) => {
            this.splitOrders(data);
        });
		this.emmiter.on('closeOrder', (data) => {
            this.closeOrder(data);
        });
		this.emmiter.on('toggleDiscount', () => {
            this.toggleDiscount();
        });
		this.emmiter.on('cancelOrder', (data) => {
            this.cancelOrder(data);
        });
    },
    beforeUnmount() {
		this.emmiter.off('refreshOrderList');
		this.emmiter.off('addTempOrder');
		this.emmiter.off('setPayer');
		this.emmiter.off('changeOrderTable');
		this.emmiter.off('debounceSearch');
		this.emmiter.off('changeItemStatus');
		this.emmiter.off('combineOrders');
		this.emmiter.off('splitOrder');
		this.emmiter.off('closeOrder');
		this.emmiter.off('toggleDiscount');
		this.emmiter.off('cancelOrder');
    },
    methods: {
        getOrders(reset, findAndSelect) {
            if (!this.loading) {
	            this.loading = true;
	            if (reset) {
		            this.page = 0;
		            this.orders = [];
		            this.selectOrder = null;
	            }
	            orderService.getOrders({
		            search: this.searchOrder ? this.searchOrder : null,
		            status: 1,
		            page: !this.page ? this.page += 1 : this.page,
		            per_page: this.per_page,
	            }).then(res => {
		            this.loading = false;
		            res.data.forEach(el => {
			            this.orders.push(el);
		            });
		            if (res.data.length > 0) {
			            this.page += 1;
		            }
					
		            if (reset) {
			            let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
			            if (tempOrders) {
				            if (this.searchOrder !== null) {
					            tempOrders.forEach((i) => {
						            if (i.table.toString().includes(this.searchOrder) || (i.payers[0] && i.payers[0].cabin.includes(this.searchOrder)) ) {
							            this.orders.unshift(i);
						            }
					            })
				            } else {
					            this.orders = tempOrders.concat(this.orders);
				            }
				            this.orders.forEach((o) => {
					            o['active'] = false
				            })
			            }
		            }
		            if (this.selectOrder) {
			            this.parseItemCondiments(this.selectOrder);
			            this.orders.forEach((o) => {
				            o['active'] = false;
				            if (o.id !== null) {
					            if (o.id === this.selectOrder.id) {
						            o['active'] = true;
						            this.selectOrder = o;
						            this.parseItemCondiments(this.selectOrder);
					            }
				            } else {
					            if (o.tempId === this.selectOrder.tempId) {
						            o['active'] = true;
									this.selectOrder = o;
					            }
				            }
			            })
		            }
		
		            if (findAndSelect) {
			            this.orders.forEach((o) => {
				            o['active'] = false;
				            if (o.id === findAndSelect) {
					            o['active'] = true;
					            this.selectOrder = o;
								this.parseItemCondiments(this.selectOrder);
				            }
			            })
		            }
		
		            console.log(this.orders)
	            })
            }
        },
	    
	    /***** ORDERS LIST DEBOUNCE SEARCH LOGIC START */
        debounceSearch(e) {
            this.searchOrder = null;
            clearTimeout(this.debounce);
            this.debounce = setTimeout((event) => {
                this.searchOrder = e.target.value;
                this.getOrders(true);
            }, 500)
        },
        ordersListScrollHandle(e) {
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
                console.log(this.searchOrder);
                if (!this.searchOrder) {
                    this.getOrders(false);
                }
            }
        },
	    /***** END */
	    
        changSelectOrder(el) {
            this.selectOrder = el;
		    this.parseItemCondiments(this.selectOrder);
            this.orders.forEach((o) => {
                o['active'] = false;
                if (o.id !== null) {
                    if (o.id === el.id) {
                        o['active'] = true;
                    }
                } else {
                    if (o.tempId === el.tempId) {
                        o['active'] = true;
                    }
                }
            })
        },
        editOrderTable() {
            this.openAddNewOrderModal(this.selectOrder)
        },
        handlePayload(items, createOrder) {
			let obj1 = JSON.parse(JSON.stringify(items));
	        console.log(items);
            return obj1.map((obj) => {
				let condiments = [];
				if (obj.condiments_parsed && obj.condiments_parsed.length) {
					obj.condiments_parsed.forEach((o) => {
						if (o.type === 1) {
							o.items.forEach((oo) => {
								if (oo.checked) {
									condiments.push({
										menu_item_condiment_id: oo.parent_id,
										count: 1
									})
								}
							})
						}
					})
				}
				const q = {
					id: obj.id,
					seat: obj.seat ? obj.seat : 1,
					count: obj.count,
					status: obj.status,
					condiments: condiments,
					orderable_id: obj.orderable_id,
					orderable_type: obj.orderable_type,
				}
				if (createOrder || obj.from_menu) {
					delete q['id'];
				}
                return q
            })
        },
        countTotal() {
            this.selectOrder.total_amount = 0;
            this.selectOrder.items.forEach((o) => {
                this.selectOrder.total_amount += o.price * o.count;
                this.$forceUpdate();
            })
        },
	    handleResponse(data, refreshList, closeModal, resetSelected) {
		    if (closeModal) {
			    this.emmiter.emit('closeModal');
		    }
		    if (data) {
			    this.selectOrder = data;
				this.orders.forEach((yy, index) => {
					if (yy.id === this.selectOrder.id) {
						this.orders[index] = {...this.selectOrder, active: true};
					}
				});
			    console.log(this.orders)
			    this.parseItemCondiments(this.selectOrder);
		    }
		    if (refreshList) {
			    this.emmiter.emit('refreshOrderList', resetSelected);
		    }
	    },
	    parseItemCondiments(data) {
		    data.items.forEach(el => {
			    el['condiments_parsed'] = [];
			    if (el.available_condiments.length) {
				    el.available_condiments.forEach((o) => {
					    let index = el.condiments_parsed.findIndex((i) => {return i.name === o.name});
					    if (index === -1) {
						    let tempObj = JSON.parse(JSON.stringify(o));
						    tempObj['items'] = [{...o.menu_item, parent_id: o.id}];
						    el.condiments_parsed.push(tempObj)
					    } else {
						    el.condiments_parsed[index].items.push({...o.menu_item, parent_id: o.id})
					    }
				    });
			    }
			    el.condiments_parsed.forEach((o) => {
				    if (o.type === 1) {
					    o.items[0].checked = true;
				    }
			    });
			    el.condiments.forEach((q) => {
				    let f = el.condiments_parsed.findIndex((item) => {return item.name === q.name});
				    if (f !== -1) {
					    if (q.type === 1) {
						    el.condiments_parsed[f].items.forEach((qe) => {
							    qe['checked'] = qe.parent_id === q.id;
						    })
					    } else {
						    el.condiments_parsed[f].items[0]['checked'] = true;
						    el.condiments_parsed[f]['checked'] = true;
					    }
					    el.condiments_parsed[f].count = q.count;
				    }
			    })
			    el.condiments_parsed.sort((a,b) => {return a.type - b.type})
		    })
	    },
	    
	    
	    // API METHODS
        changeItemQty(i, type) {
            if (type === 'minus') {
                if (i.count > 1) {
                    i.count -= 1;
                } else {
                    console.log('MINUS');
                    /*if (this.selectOrder.items.length === 1) {
                        this.$refs['selected-order-component'].openCancelOrderModal()
                    }*/
                }
            } else {
                i.count += 1;
            }
            let index = this.selectOrder.items.findIndex((obj) => {
                return obj.id === i.id
            });
            if (index !== -1) {
                this.selectOrder.items[index] = i;
                this.countTotal();
                orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder).then((res) => {
	                console.log(res.data.items);
	                this.handleResponse(res.data, false, false, false);
                })
            }
        },
        setPayer(data) {
            this.selectOrder['payers'][0] = data;
			console.log(this.selectOrder);
            if (this.selectOrder.id === null) {
                let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
                let index = tempOrders.findIndex((o) => {
                    return o.tempId === this.selectOrder.tempId
                });
                if (index !== -1) {
                    tempOrders[index] = this.selectOrder;
                    localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
                    this.emmiter.emit('closeModal')
                }
            } else {
                orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder).then((res) => {
                    if (res) {
	                    this.handleResponse(res.data, true, true, false);
                    }
                })
            }
        },
	    setWaiter(data) {
		    console.log(this.selectOrder);
            this.selectOrder['opened_by'] = data;
		    console.log(this.selectOrder);
            if (this.selectOrder.id === null) {
                let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
                let index = tempOrders.findIndex((o) => {
                    return o.tempId === this.selectOrder.tempId
                });
                if (index !== -1) {
                    tempOrders[index] = this.selectOrder;
                    localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
                    this.emmiter.emit('closeModal')
                }
            } else {
                orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder).then((res) => {
                    if (res) {
	                    this.handleResponse(res.data, true, true, this.getUser.user.role !== 'manager');
                    }
                })
            }
        },
        addItem(el) {
			let index = this.selectOrder.items.findIndex((i) => {return i.orderable_id === el.orderable_id && i.seat === el.seat && i.status === 1});
			let hasCondiments = el.condiments_parsed.filter((i) => {return i.type === 1 ? i : (i.type === 2 && i.checked ? i : false)});
			let deepCopy = JSON.parse(JSON.stringify(this.selectOrder));
			
			if (index !== -1) {
				if (hasCondiments.length) {
					deepCopy.items.push(el);
				} else {
					deepCopy.items[index].count += el.count;
				}
			} else {
				deepCopy.items.push(el);
			}
			
			hasCondiments.forEach((o) => {
				if (o.type === 2) {
					let index1 = this.selectOrder.items.findIndex((i) => {return (o.combo ? i.combo_menu_item_id : i.menu_item_id) === o.menu_item.id && i.seat === el.seat && i.status === 1});
					if (index1 !== -1) {
						deepCopy.items[index1].count += o.count;
					} else {
						deepCopy.items.push({
							id: o.menu_item.id,
							from_menu: true,
							seat: el.seat,
							status: 1,
							count: o.count,
							condiments_parsed: []
						});
					}
					o.checked = false;
				}
			})
	        
	        /*if (this.selectOrder.id === null) {
		        console.log(this.handlePayload(deepCopy.items, true));
	        } else {
		        console.log(this.handlePayload(deepCopy.items));
	        }*/
	        // console.log(this.handlePayload(deepCopy.items, true));
            this.countTotal();
            if (this.selectOrder.id === null) {
                orderService.createNewOrder(deepCopy.id, this.handlePayload(deepCopy.items, true), deepCopy).then((res) => {
                    console.log(res);
                    if (res) {
                        let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
                        let index = tempOrders.findIndex((o) => {
                            return o.tempId === this.selectOrder.tempId
                        });
						console.log(index);
                        if (index !== -1) {
	                        tempOrders.splice(index, 1);
                            localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
	                        this.handleResponse(res.data, true, false, false);
                        }
                    }
                })
            } else {
				// let parsedItems = this.handlePayload(deepCopy.items);
	            // console.log(parsedItems)
                orderService.updateOrder(this.selectOrder.id, this.handlePayload(deepCopy.items), this.selectOrder).then((res) => {
	                console.log(res.data.items);
	                this.handleResponse(res.data, false, false, false);
                })
            }
        },
        deleteItem(id) {
	        this.selectOrder.items.splice(id, 1);
	        this.countTotal();
	        orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder).then((res) => {
		        console.log(res.data.items);
		        this.handleResponse(res.data, false, false, false);
	        })
        },
        editItems(id, item) {
            this.selectOrder.items[id] = item;
	        let deepCopy = JSON.parse(JSON.stringify(this.selectOrder));
	        let hasCondiments = item.condiments_parsed.filter((i) => {return i.type === 1 ? i : (i.type === 2 && i.checked ? i : false)});
	        hasCondiments.forEach((o) => {
		        if (o.type === 2) {
			        let index1 = this.selectOrder.items.findIndex((i) => {return (o.combo ? i.combo_menu_item_id : i.menu_item_id) === o.menu_item.id && i.seat === item.seat && i.status === 1});
			        if (index1 !== -1) {
				        deepCopy.items[index1].count += o.count;
			        } else {
				        deepCopy.items.push({
					        id: o.menu_item.id,
					        from_menu: true,
					        seat: item.seat,
					        status: 1,
					        count: o.count,
					        condiments_parsed: []
				        });
			        }
			        o.checked = false;
		        }
	        })
            this.countTotal();
            orderService.updateOrder(this.selectOrder.id, this.handlePayload(deepCopy.items), this.selectOrder).then((res) => {
	            console.log(res.data.items);
	            this.handleResponse(res.data, false, true, false);
            })
        },
        changeOrderTable(table, seat) {
            const prevTable = this.selectOrder.tempId;
            this.selectOrder.table = parseInt(table);
            this.selectOrder.seat = seat;
			this.$forceUpdate();
            if (this.selectOrder.id === null) {
                let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
                let index = tempOrders.findIndex((o) => {
                    return o.tempId === prevTable
                });
	            tempOrders[index] = this.selectOrder;
	            localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
	            this.emmiter.emit('closeModal');
            } else {
                orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder, true).then((res) => {
                    if (res) {
	                    this.$forceUpdate();
	                    this.handleResponse(res.data, true, true, false);
                    }
                })
            }

        },
        addRound() {
			if (this.selectOrder.items.length > 0) {
				let indexesToCopy = [];
				this.selectOrder.items.forEach((o, index) => {
					if (o.status === 1) {
						o.count += 1;
					} else {
						indexesToCopy.push({
							...o,
							id: null,
							status: 1,
							count: 1
						});
					}
				});
				if (indexesToCopy.length) {
					this.selectOrder.items = [...this.selectOrder.items, ...indexesToCopy];
				}
				this.countTotal();
				orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder).then((res) => {
					this.handleResponse(res.data, false, false, false);
				})
			}
        },
        createTempOrder(table, seat, payers) {
            let tempOrder = {
                payers: payers ? [payers] : [],
                id: null,
                active: true,
                items: [],
                opened_by: {
                    name: `${this.getUser.user.first_name} ${this.getUser.user.last_name}`
                },
	            seat: seat,
                status: 1,
                table: table ? parseInt(table) : null,
                total_amount: 0,
	            tempId: window.crypto.getRandomValues(new Uint8Array(16)).join('')
            };
            console.log(tempOrder);
            this.orders.forEach((o) => {
                o['active'] = false;
            })
            this.orders.unshift(tempOrder);
            this.selectOrder = tempOrder;
            let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
            console.log(tempOrders);
            if (tempOrders) {
                tempOrders.unshift(tempOrder);
                localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
            } else {
                localStorage.setItem('tempOrders', JSON.stringify([tempOrder]))
            }
        },
	    changeItemStatus(data) {
			console.log(data);
			let index = this.selectOrder.items.findIndex((o) => {return o.id === data.id});
			if (index !== -1) {
				this.selectOrder.items[index] = data;
				orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder).then((res) => {
					console.log(res.data.items);
					this.handleResponse(res.data, false, true, false);
				})
			}
	    },
	    combineOrders(data) {
		    let orderCombineInto = data.ordersCombineDetails;
		    let ordersToCombine = data.ordersToCombine;
			let itemsToCombine = [];
		    ordersToCombine.forEach((o) => {o.items.forEach((o) => {itemsToCombine.push(o)})});
			let tempArr = [];
		    itemsToCombine.forEach((o) => {
			    console.log(o);
				let index = orderCombineInto.items.findIndex((oo) => {return ((oo.menu_item_id && oo.menu_item_id === o.menu_item_id) || (oo.combo_menu_item_id && oo.combo_menu_item_id === o.combo_menu_item_id)) && oo.status === 1 && (oo.seat === o.seat)});
				if (index !== -1) {
					if (orderCombineInto.items[index].status === 1 && o.status === 1 && !o.condiments.length) {
						orderCombineInto.items[index].count += o.count;
					} else {
						tempArr.push(o);
					}
				} else {
					tempArr.push(o);
				}
			});
		    orderCombineInto.items = [...orderCombineInto.items, ...tempArr];
			this.selectOrder = null;
		 
			console.log(orderCombineInto);
			if (orderCombineInto.id === null) {
				console.log('IFFFFFFFFFFF');
				let parsedItems = this.handlePayload(orderCombineInto.items);
				orderService.createNewOrder(orderCombineInto.id, parsedItems, orderCombineInto).then((res) => {
					console.log(res);
					if (res) {
						let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
						let index = tempOrders.findIndex((o) => {
							return o.tempId === orderCombineInto.tempId
						});
						console.log(index);
						if (index !== -1) {
							tempOrders.splice(index, 1);
							localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
						}
						
						let count = ordersToCombine.length;
						ordersToCombine.forEach((o) => {
							orderService.cancelOrder(o).then((res) => {
								console.log(res);
								count -= 1;
								if (!count) {
									this.getOrders(true, orderCombineInto.id);
									this.handleResponse(null, false, true, false);
									this.$refs['orders-list-component'].turnOffCombineOrder();
									this.$forceUpdate();
								}
							})
						})
					}
				})
			} else {
				console.log('ELSEEEEEE')
				let parsedItems = this.handlePayload(orderCombineInto.items);
				orderService.updateOrder(orderCombineInto.id, parsedItems, orderCombineInto).then((res) => {
					let count = ordersToCombine.length;
					ordersToCombine.forEach((o) => {
						if (o.id) {
							orderService.cancelOrder(o).then((res) => {
								console.log(res);
								count -= 1;
								if (!count) {
									this.getOrders(true, orderCombineInto.id);
									this.handleResponse(null, false, true, false);
									this.$refs['orders-list-component'].turnOffCombineOrder();
									this.$forceUpdate();
								}
							})
						} else {
							let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
							let index = tempOrders.findIndex((oi) => {
								return oi.tempId === o.tempId
							});
							console.log(index);
							if (index !== -1) {
								tempOrders.splice(index, 1);
								localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
							}
						}
					})
				})
			}
	    },
	    splitOrders(data) {
			console.log(data);
			if (!this.selectOrder.items.length) {
				orderService.cancelOrder(this.selectOrder).then((res) => {
					if (res) {
						this.handleResponse(null, false, false, false);
						this.createTempOrder(this.selectOrder.table, this.selectOrder.seat, this.selectOrder.payers[0])
					}
				});
			} else {
				orderService.updateOrder(data.update.id, this.handlePayload(data.update.items), data.update).then((res) => {
					if (res) {
						this.handleResponse(res.data, false, false, false);
					}
				});
			}
		    
		    orderService.createNewOrder(data.create.id, this.handlePayload(data.create.items), data.create, true).then((res) => {
			    console.log(res);
			    if (res) {
				    this.handleResponse(null, true, true, true);
			    }
		    })
	    },
	    closeOrder(data) {
			if (data.payment_type === 2) {
				orderService.closeOrder(data).then((res) => {
					if (res) {
						this.handleResponse(null, true, true, false);
					}
				})
			} else {
				orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder).then((res) => {
					if (res) {
						orderService.closeOrder(data).then((res1) => {
							if (res1) {
								this.handleResponse(null, true, true, true);
							}
						})
					}
				})
			}
	    },
	    cancelOrder(data) {
		    if (data.id === null) {
			    let tempOrders = JSON.parse(localStorage.getItem('tempOrders'));
			    let index = tempOrders.findIndex((o) => {return o.table === data.table});
			    if (index !== -1) {
				    tempOrders.splice(index, 1);
				    localStorage.setItem('tempOrders', JSON.stringify(tempOrders));
				    this.handleResponse(null, true, true, true);
			    }
		    } else {
			    orderService.cancelOrder(data).then((res) => {
				    this.handleResponse(null, true, true, true);
			    })
		    }
	    },
	    toggleDiscount() {
			this.selectOrder.with_discount = !this.selectOrder.with_discount;
		    orderService.updateOrder(this.selectOrder.id, this.handlePayload(this.selectOrder.items), this.selectOrder).then((res) => {
			    this.handleResponse(res.data, false, false, false);
		    })
	    }
    },

}
</script>

<style lang="less" scoped>
#pos-root {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 10px 0 10px 0;
    height: 100%;
    //height: calc(100% - 20px);
    overflow-y: hidden;
}
</style>
