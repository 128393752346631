<template>
    <div id="menu" class="menu">
        <div class="controls-wrapper">
            <div class="left">
                <div class="refresh-btn square-btn" @click="breadcrumbsChange(null, 0)">
                    <img class="square-btn-img" src="../../assets/img/house.png" alt="logout-btn"
                         v-if="design==='ecruise'"/>
                    <img class="square-btn-img" src="../../assets/img/redHouse.png" alt="logout-btn"
                         v-if="design==='margaritaville'"/>
                </div>
            </div>
            <div class="section-title">
                <div class="breadcrumbs" v-for="(i, index) of breadcrumbs"
                     :class="{'active-breadcrumb': index === breadcrumbs.length - 1}"
                     @click="breadcrumbsChange(i, index)">
                    <span class="breadcrumb-name">{{ i.name }}</span>
                    <span class="breadcrumb-separator"
                          v-if="(breadcrumbs.length > 1 && index !== breadcrumbs.length - 1)">></span>
                </div>
            </div>
            <refresh-btn-component @clickEvent="refreshData(true)" parentComp="ordersList"></refresh-btn-component>
        </div>
        <search-bar-component
            @onInput="onInput"
            :placeholder="'Code, name, category, etc.'"
            :clearInput="clearInput"
        >
        </search-bar-component>
        <div class="all-menu" v-if="step===1">
            <!--<div class="category-changer" @click="getMenuCategory(true)">
                <div class="category-changer-icon">
                    <img v-if="design === 'ecruise'" class="square-btn-img" src="../../assets/img/dinner-serving-dish-svgrepo-com.png"
                         alt="dinner"/>
	                <img v-if="design === 'margaritaville'" class="square-btn-img" src="../../assets/img/72category.svg"
                         alt="dinner"/>
	                <div class="description">Categories</div>
                </div>
            </div>-->
            <div class="list intro" @scroll="menuListScrollHandle">
	            <div class="category-changer" @click="getMenuCategory(true)">
		            <div class="category-changer-icon">
			            <img v-if="design === 'ecruise'" class="square-btn-img" src="../../assets/img/dinner-serving-dish-svgrepo-com.png"
			                 alt="dinner"/>
			            <img v-if="design === 'margaritaville'" class="square-btn-img" src="../../assets/img/72category.svg"
			                 alt="dinner"/>
			            <div class="description">Categories</div>
		            </div>
	            </div>
                <div class="order" v-for="(i, index) in menuItems" :key="index" @click="selectItem(i)">
                    <div class="price">${{ i.price }}</div>
                    <div class="img" :class="{'no-icon': !i.photo_url}" :style="{backgroundImage: `url(${i.photo_url || (design === 'ecruise' ? require('@/assets/img/dinner-serving-dish-svgrepo-com.png') : require('@/assets/img/72category.svg'))})`}">
	                    <!--<img class="items-icons" :class="{'no-icon': !i.photo_url}" :src="i.photo_url || (design === 'ecruise' ? require('@/assets/img/dinner-serving-dish-svgrepo-com.png') : require('@/assets/img/72category.svg'))" alt="items-icons">-->
                    </div>
                    <div class="description">{{ i.name }}</div>
                </div>
            </div>
        </div>
        <div class="categories" v-if="step===2">
            <div class="list">
                <div class="order" v-for="(i, index) in isDeptCategory ? depthCategory :  categories" :key="index"
                     @click="getSingleCategory(i)">
                    <div class="img">
	                    <img v-if="design === 'ecruise'" class="square-btn-img" src="../../assets/img/dinner-serving-dish-svgrepo-com.png"
	                         alt="dinner"/>
	                    <img v-if="design === 'margaritaville'" class="square-btn-img" src="../../assets/img/72category.svg"
	                         alt="dinner"/>
                    </div>
                    <div class="description">{{ i.name }}</div>
                </div>
            </div>
        </div>
        <div class="category-items" v-if="step===3">
            <div class="list">
                <div class="order" v-for="(i, index) in itemsFilter.length ? itemsFilter : items" :key="index" @click="selectItem(i)">
                    <div class="price">${{ i.price }}</div>
                    <div class="img" :class="{'no-icon': !i.photo_url}" :style="{backgroundImage: `url(${i.photo_url || (design === 'ecruise' ? require('@/assets/img/dinner-serving-dish-svgrepo-com.png') : require('@/assets/img/72category.svg'))})`}">
	                    <!--<img class="items-icons" :class="{'no-icon': !i.photo_url}" :src="i.photo_url || (design === 'ecruise' ? require('@/assets/img/dinner-serving-dish-svgrepo-com.png') : require('@/assets/img/72category.svg'))" alt="items-icons">-->
                    </div>
                    <div class="description">{{ i.name }}</div>
                </div>
            </div>
        </div>
    </div>
	
	<add-edit-item-modal
			@addEditItem="addItem"
			:selectedItem="selectedItem"
			:selected-order="selectOrder"
			ref="add-edit-item-modal"
			v-if="showAddNewOrderModal"
	/>
</template>

<script>
import SearchBarComponent from "@/components/_shared/SearchBar";
import ModalComponent from "@/components/_shared/Modal";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import RefreshBtnComponent from "@/components/_shared/RefreshButton";
import {menuService} from "@/_api/menu.api";
import AddEditItemModal from "@/components/_modals/AddEditItemModal";

export default {
    name: 'MenuComponent',
    props: ['selectOrder'],
    emits: ['addItem'],
    components: {RefreshBtnComponent, ConfirmationModalComponent, ModalComponent, SearchBarComponent, AddEditItemModal},
    data() {
        return {
			selectedItem: null,
	        showAddNewOrderModal: false,
            step: 1,
            breadcrumbs: [{
                id: null,
                name: 'Menu'
            }],
            menuItems: [],
            categories: [],
            items: [],
            search: '',
            itemsFilter: [],
            isDeptCategory: false,
            depthCategory: [],
            clearInput:false
        }
    },
    computed: {
        design() {
            return this.$store.state.UI;
        }
    },
    created() {
    },
    mounted() {
	    this.menuItems = [];
        this.getMenuItems();
		this.getComboMenuItems();
    },
    beforeUnmount() {
    },
    methods: {
		handlePayload(item, isCombo) {
			const q = {
				...item,
				from_menu: true,
				count: 1,
				seat: null,
				status: 1,
				combo: isCombo,
				condiments_parsed: [],
				orderable_id: item.id,
				orderable_type: isCombo ? 'App\\Models\\ComboMenuItem' : 'App\\Models\\MenuItem'
			}
			
			if (!isCombo) {
				q.condiments.forEach((o) => {
					let index = q.condiments_parsed.findIndex((i) => {return i.name === o.name});
					if (index === -1) {
						let tempObj = JSON.parse(JSON.stringify(o));
						tempObj['items'] = [{...o.menu_item, parent_id: o.id}];
						q.condiments_parsed.push(tempObj)
					} else {
						q.condiments_parsed[index].items.push({...o.menu_item, parent_id: o.id})
					}
				});
				q.condiments_parsed.forEach((o) => {
					if (o.type === 1) {
						o.items[0].checked = true;
					}
				});
				q.condiments_parsed.sort((a,b) => {return a.type - b.type})
			}
			
			return q
		},
		
		addItem(data) {
			this.$emit('addItem', data);
		},
        selectItem(i) {
            if (this.selectOrder) {
				this.selectedItem = i;
				this.openAddNewOrderModal();
            }
        },
        onInput(e) {
            if (this.step === 1) {
                this.debounceSearch(e, 'getMenuItems');
            } else if (this.step === 2 && this.isDeptCategory) {
                this.debounceSearch(e, 'depthFilter');
            } else if (this.step === 2) {
                this.debounceSearch(e, 'getMenuCategory');
            } else if (this.step === 3) {
                this.debounceSearch(e, 'filterItems');
            }
        },
        debounceSearch(e, func) {
            this.clearInput = false;
            this.search = '';
            clearTimeout(this.debounce);
            this.debounce = setTimeout((event) => {
                this.search = e.target.value;
                switch (func) {
                    case 'getMenuItems':
	                    this.menuItems = [];
                        this.getMenuItems();
						this.getComboMenuItems();
                        break;
                    case 'getMenuCategory':
                        this.getMenuCategory();
                        break;
                    case 'depthFilter':
                        this.depthCategory = this.categories;
                        this.depthCategory = this.categories.filter(el => el.name.toLowerCase().includes(e.target.value.toLowerCase()));
                        break;
					case 'filterItems':
                        this.itemsFilter = this.items.filter(el => el.name.toLowerCase().includes(e.target.value.toLowerCase()));
                        break;
                }
            }, 500);
        },
        getMenuItems() {
            menuService.getMenuItems({
                page: 1,
                per_page: 1000,
                search: this.search
            }).then(res => {
                if (res.data) {
                    res.data.forEach(el => {
                        this.menuItems.push(this.handlePayload(el, false));
                    });
                }
            })
        },
	    getComboMenuItems() {
            menuService.getSingleComboCategory({
                page: 1,
                per_page: 1000,
                search: this.search
            }).then(res => {
                if (res.data) {
                    res.data.forEach(el => {
                        this.menuItems.unshift(this.handlePayload(el, true));
                    });
	                console.log(this.menuItems)
                }
            })
        },
        getMenuCategory(refresh) {
            this.categories = [];
			if (refresh) {
				this.search = '';
				this.clearInput = true;
			}
            menuService.getMenuCategories({
                page: 1,
                per_page: 1000,
                search: this.search
            }).then(res => {
                if (res.data) {
                    res.data.forEach(el => {
                        this.categories.push(el)
                    });
                    this.isDeptCategory = false;
                    this.step = 2;
                    if (!this.breadcrumbs.some(el => el.name === 'Categories')) {
                        this.breadcrumbs.push({
                            id: null,
                            name: 'Categories'
                        });
                    }
                }
            })
        },
        getSingleCategory(data, refresh) {
            this.clearInput = true;
            this.categories = [];
            this.depthCategory = [];
            this.isDeptCategory = false;
            this.items = [];
			if (data.combo) {
				menuService.getSingleComboCategory({
					menu_category: data.id,
					search: '',
					order_by: '',
					sort: '',
					page: 1,
					per_page: 1000
				}).then((res) => {
					res.data.forEach(el => {
						this.items.push(this.handlePayload(el, true));
					})
					this.step = 3;
					if (!refresh) {
						this.breadcrumbs.push({
							id: data.id,
							name: data.name,
							combo: data.combo
						})
					}
				})
			} else {
				menuService.getSingleCategory(data.id).then(res => {
					if (res.items_count === 0) {
						res.children.forEach(el => {
							this.categories.push(el);
							this.depthCategory.push(el);
						})
						this.step = 2;
						this.isDeptCategory = true
					} else {
						res.items.forEach(el => {
							this.items.push(this.handlePayload(el, false));
						})
						this.step = 3
					}
					if (!refresh) {
						this.breadcrumbs.push({
							id: res.id,
							name: res.name
						})
					}
				})
			}
            
            // this.itemsFilter = this.items
        },
        breadcrumbsChange(i, index) {
            this.search = '';
            this.clearInput = true;
            if (index === 0) {
                this.breadcrumbs.splice(1);
                this.step = 1;
            } else if (index === 1) {
                this.breadcrumbs.splice(2);
                this.getMenuCategory(true);
            } else {
                if (index !== this.breadcrumbs.length - 1) {
                    this.breadcrumbs.splice(index + 1);
                }
                this.refreshData(false, index);
            }
        },
        refreshData(manually, index) {
            if (manually) {
                if (this.step === 2 && this.breadcrumbs.length === 2) {
                    this.getMenuCategory(true);
                } else if (this.step === 2 || this.step === 3) {
                    this.getSingleCategory(this.breadcrumbs[this.breadcrumbs.length - 1], true);
                } else {
					this.menuItems = [];
                    this.getMenuItems();
                    this.getComboMenuItems();
                }
            } else {
                if (index === 1) {
                    this.getMenuCategory(true);
                } else if (this.step === 2 || this.step === 3) {
                    this.getSingleCategory(this.breadcrumbs[this.breadcrumbs.length - 1], true);
                } else {
	                this.menuItems = [];
                    this.getMenuItems();
	                this.getComboMenuItems();
                }
            }
        },
	    menuListScrollHandle(e) {
		    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
			    if (!this.searchOrder) {
				    // this.getOrders(false);
			    }
		    }
	    },
	    openAddNewOrderModal() {
		    this.showAddNewOrderModal = true;
		    this.$nextTick(() => {
			    this.$refs['add-edit-item-modal'].openModal();
		    })
	    },
    },
    watch: {}
}
</script>

<style lang="less" scoped>
#menu {
    height: 100%;
    // overflow-y: scroll;
    width: 50%;
    margin-right: 10px;
    .controls-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
	    box-sizing: border-box;
	    padding: 0 5px;
        .left {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            .refresh-btn {
                margin-right: 10px;
            }

        }

        .section-title {
            // font-size: 14px;
            // line-height: 19px;
            // color: #424242;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .breadcrumbs {
                font-size: 14px;
                color: #424242;
                font-weight: 400;
                cursor: pointer;
                display: flex;

                &.active-breadcrumb {
                    font-weight: 700;
                }

                &:first-child {
                    text-transform: uppercase;
                }

                .breadcrumb-separator {
                    padding: 0 5px;
                }
            }

            /*button {
                border: none;
                margin: -3px;
                background-color: transparent;
            }*/
        }
    }
	::v-deep(#search-bar) {
		margin-bottom: 10px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 5px;
	}
    .category-changer {
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 1px 3px -1px #000;
        height: 110px;
        width: 100%;
        display: flex;
        cursor: pointer;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        /*&-icon {
            width: 72px;
            height: 72px;
        }*/
    }
    .list {
        // height: 1000px;
        overflow-y: scroll;
        /*display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 75px);
        column-gap: 9px;
        grid-row-gap: 70px;
        -ms-overflow-style: none; !* IE and Edge *!
        scrollbar-width: none; !* Firefox *!*/
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: flex-start;
	    align-items: flex-start;
	    align-content: flex-start;
	    height: calc(100vh - 240px);
	    padding: 0 5px;
	    box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
	    &.intro {
		    .category-changer {
			    width: 49%;
			    margin-right: 2%;
			    height: 125px;
		    }
		    .order {
			    &:nth-child(4n + 3) {
				    margin-right: 0;
			    }
			    &:nth-child(4n) {
				    margin-right: 2%;
			    }
		    }
	    }
		
        .order {
	        width: 23.5%;
            height: 125px;
            background-color: #fff;
            margin-bottom: 10px;
            border-radius: 6px;
            box-shadow: 0 1px 3px -1px #000;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
	        margin-right: 2%;
	        &:nth-child(4n) {
		        margin-right: 0;
	        }
	        /*&:nth-child(3) {
		        margin-right: 0;
	        }
	        &:nth-child(4) {
		        margin-right: 2%;
	        }*/
            .price {
                // height: 24px;
                width: 100%;
            }

            .img {
                height: 72px;
	            width: 100%;
	            background-repeat: no-repeat;
	            background-position: center;
	            background-size: cover;
	            &.no-icon {
		            background-size: contain;
	            }
                img {
	                &.items-icons {
		                &.no-icon {
			                width: unset;
		                }
		                height: 72px;
		                width: 100%;
	                }
	                &.category-icons {
		                height: 72px;
		                // width: 100%;
	                }
                }
            }

            .description {
                width: 80%;
                // height: 28px;
	            white-space: nowrap;
	            overflow: hidden !important;
	            text-overflow: ellipsis;
            }
        }
    }
    .categories, .category-items {
        .list {
	        // height: calc(100vh - 237px);
        }
    }
}

@media (max-width: 1200px) {
	#menu {
		min-width: 350px;
	}
}
@media (max-width: 850px) {
	#menu {
		min-width: 480px;
	}
}
</style>

