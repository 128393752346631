<template>
    <div class="new-order-modal-component">
        <modal-component ref="modal">
            <div class="container">
                <div class="header">
                    <div class="header-text">Print food runner chit</div>
                    <div class="subheader bold">Items to print:</div>
                </div>
                <div class="selected-items">
                    <div class="item item-header bold">
                        <div class="item-header-wrapper">
                            <div class="state">print</div>
                            <div class="name">name</div>
                        </div>
                        <div class="qty">qty</div>
                    </div>
                    <div class="item" v-for="(i,index) in listSelected" :key="index" @click="selectItem(i)">
                        <div class="item-block-wrapper">
	                        <input type="checkbox" :id="index" name="vehicle1" v-model="i.selected">
                            <label> {{ i.name }}</label>
                        </div>
                        <span>{{ i.qty }}</span>
                    </div>
                </div>
                <div class="line-block">
                    <div class="line"></div>
                    <span>Processed items</span>
                    <div class="line"></div>

                </div>

                <div class="selected-items">
                    <div class="item" v-for="(i,index) in listUnselected" :key="index" @click="selectItem(i)">
                        <div class="item-block-wrapper">
                            <input type="checkbox" :id="index" name="vehicle1" v-model="i.selected">
                            <label> {{ i.name }}</label>
                        </div>
                        <span>1</span>
                    </div>
                </div>
                <div class="print" v-for="i in selectedPrinter" @click="selectPrinter">
                    <div class="icon"><img src="@/assets/img/36printer.png"></div>
                    <div class="text">{{ i.name }}</div>
                </div>

            </div>
            <div class="postpone">
                <div class="input-wrap">
                    <label for="postpone">
	                    <input type="checkbox" id="postpone" v-model="isPostponeEnabled">
	                    Postpone
                    </label>
                </div>
                <div @click="openPostponeModal" class="time">
                    <span>{{postpone}}</span>
                </div>
            </div>
            <div class="buttons-navigate">
                <button @click="close" class="def-btn cancel-action-btn uppercase medium width100">CANCEL</button>
                <button @click="printCheck" class="def-btn main-action-btn uppercase medium width100">PRINT</button>
            </div>
        </modal-component>
        <postpone-modal
            @setPostpone="setPostpone"
            :selected-pospone-timer="postpone"
            v-if="showPostponeModal"
            ref="postpone-modal"
        ></postpone-modal>
    </div>
</template>

<script>

import ModalComponent from "@/components/_shared/Modal";
import PostponeModal from "@/components/_modals/PostponeModal";

export default {
    name: "FoodRunnerModal",
    emits: ['selectedPrinter'],
    components: {PostponeModal, ModalComponent},
    props: {
        selectedOrder: {
            type: Object,
            require: true
        },
        selectedPrinter: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            box: [],
            order: null,
            showPostponeModal: false,
            postpone: '00:00',
	        isPostponeEnabled: false
        }
    },
	computed: {
		listSelected() {
			return this.selectedOrder.items.filter((o) => {return o.selected})
		},
		listUnselected() {
			return this.selectedOrder.items.filter((o) => {return !o.selected})
		},
	},
    watch: {
        /*box: function (item, old) {
            item.forEach(el => {
                this.order = this.order.filter(order => order.id !== el.id);
            })

            old.forEach(el => {
                if (item.indexOf((el)) === -1) this.order.push(el);
            })
        }*/
    },
    methods: {
        openModal() {
            this.$refs['modal'].show();
        },
        close() {
            this.$refs['modal'].close();
            this.box = [];
        },
	    selectItem(i) {
		    i.selected = !i.selected;
			console.log(this.selectedOrder)
	    },
	    setPostpone(e) {
		    this.postpone = e;
	    },
        printCheck() {
            if (this.box.length > 0) {
                this.$refs['modal'].close();
            }
        },
        selectPrinter() {
            this.$refs['modal'].close();
            // this.$emit('selectPrinter');
        },
        openPostponeModal() {
            this.showPostponeModal = true;
            this.$nextTick(() => {
                this.$refs['postpone-modal'].openModal();
            })
        },
    },
    mounted() {
        this.order = this.orderComponents;
    }
}
</script>

<style scoped lang="less">
.container {
    height: 500px;
    position: relative;
	margin-bottom: 15px;
    .header {
        margin: 0 0 25px 0;

        &-text {
            font-weight: normal;
            font-size: 42px;
            line-height: 56px;
            color: #3F8EC5;
        }

        .subheader {
            margin-top: 12px;
        }
    }

    .print {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 492px;
        height: 48px;
        border: 1px solid #3F8EC5;
        box-sizing: border-box;
        border-radius: 8px;
        cursor: pointer;
        align-items: center;
        justify-content: flex-start;

        .icon {
            margin: 6px 12px;
        }
    }

    .selected-items {

        .item {
            padding: 0 10px;
            width: 490px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid rgba(53, 131, 197, .2);

            &:last-child {
                border-bottom: 1px solid rgba(53, 131, 197, .2);
            }

            height: 41px;

            &:active:not(:first-child) {
                background: #8DC7E1;
            }
        }

        .item-header {
            &-wrapper {
                display: flex;

            }

            display: flex;
            justify-content: space-between;

            .state {
                margin-right: 15px;
            }

            .qty {
                text-align: right;
            }
        }


    }

    .line-block {
        display: flex;
        align-items: center;
        margin: 25px 0;
        justify-content: center;

        .line {
            margin-top: 4px;
            border-top: 1px dashed #777E81;
            width: 37%;
        }

        span {
            color: #777E81;
            width: 25%;
        }
    }

    label {
        margin-left: 30px;
    }

    .bold {
        font-weight: bold;
        font-size: 12px;
        line-height: 148%;
        text-transform: uppercase;
    }


}
.postpone {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
	margin-bottom: 30px;
    .input-wrap {
       
        label {
            // margin-left: 15px;
	        display: flex;
	        align-items: center;
	        justify-content: center;
	        cursor: pointer;
        }
        input {
            height: 18px;
            width: 18px;
	        margin-right: 10px;
        }
    }
    .time {
        width: 53px;
        height: 20px;
        background: #ECF4F9;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #777E81;
        padding: 6px;
        border-radius: 4px 4px 2px 2px;
        cursor: pointer;
    }
}
.buttons-navigate{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	button {
		&:first-child {
			margin-right: 10px;
		}
	}
}

</style>

