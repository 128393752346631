<template>
	<modal-component :anotherBackground="true" ref="modal">
		<div id="postpone-modal" class="container">
			<div class="header">
				<div class="text">Postpone printing</div>
			</div>
			<div class="input-wrap">
				<label for="time">Time</label>
				<input ref="input" type="text" v-model="postpone" :maxlength="4" placeholder="00:00" id="time">
			</div>
			<keypad
					class="keypad"
					:dot="false"
					@clickOnKey="clickHandler"
					@clear="clickHandler"
			></keypad>
			<div class="buttons-navigate">
				<button @click="close" class="def-btn cancel-action-btn uppercase medium width100">CANCEL</button>
				<button @click="setPostpone" class="def-btn main-action-btn uppercase medium width100" :class="{'disabled': this.postpone.length <= 4}">OK</button>
			</div>
		</div>
	</modal-component>
</template>
<script>
import ModalComponent from "@/components/_shared/Modal";
import Keypad from "@/components/_shared/Keypad";

export default {
	emits: ['setPostpone'],
	name: "PostponeModal",
	props: {
		selectedPosponeTimer: {
			default: '00:00',
			required: false
		}
	},
	data() {
		return {
			postpone: '',
		}
	},
	mounted() {
	
	},
	components: {Keypad, ModalComponent},
	methods: {
		openModal(data) {
			this.$refs['modal'].show();
			if (this.selectedPosponeTimer !== '00:00') {
				this.postpone = this.selectedPosponeTimer
			} else {
				this.postpone = ''
			}
		},
		close() {
			this.$refs['modal'].close();
		},
		clickHandler(e) {
			console.log(e);
			if (e !== undefined) {
				if (this.postpone.length <= 4) {
					this.postpone += e;
					console.log(this.postpone);
				}
			} else {
				this.postpone = this.postpone.substring(0, this.postpone.length - 1);
				/*if (!this.postpone.length) {
					this.postpone = ''
				}*/
			}
			/*if (e != 'clear') {
				if (this.postpone.length <= 4) {
					this.postpone += e;
				}
			} else {
				this.postpone = this.postpone.substring(0, this.postpone.length - 1);
			}*/
		},
		setPostpone() {
			if (this.postpone.length > 4) {
				this.$emit('setPostpone', this.postpone);
				this.close();
			}
		}
	},
	watch: {
		postpone: function (e, o) {
			if (e.length === 2 && o.length < 2) {
				this.postpone += ':'
			}
		}
	}
}
</script>

<style scoped lang="less">
#postpone-modal {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	
	.header {
		font-weight: 400;
		font-size: 42px;
		line-height: 56px;
		color: #3F8EC5;
		margin-bottom: 43px;
		
	}
	
	.input-wrap {
		display: flex;
		flex-direction: column;
		justify-content: left;
		width: 363px;
		input {
			background: #FFFFFF;
			border: none;
			// border-bottom: 2px solid var(--theme_color);
			border-radius: 4px 4px 2px 2px;
			height: 32px;
			font-weight: 400;
			font-size: 18px;
			line-height: 20px;
			color: #1C282D;
			margin-top: 5px;
		}
		label {
			font-weight: bold;
			font-size: 14px;
			line-height: 16px;
			color: #1C282D;
			text-align: left;
			
		}
	}
	
	.keypad {
		background: #F5F9FC;
		border: none;
	}
	
	.buttons-navigate {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
		
		button {
			&:first-child {
				margin-right: 10px;
			}
		}
	}
}
</style>
