<template>
    <div class="wr">
	    <modal-component ref="modal" custom-class="sss">
		    <div class="close-order-container">
			    <div class="header">
				    <div class="header-text">Finalize order</div>
				    <div class="header-total">
					    <div class="total-title">Total Payable:</div>
					    <div class="total">${{ selectOrder.total_amount_with_discount ? selectOrder.total_amount_with_discount.toFixed(2) : selectOrder.total_amount.toFixed(2) }}</div>
				    </div>
			    </div>
			    <div class="payment">
				    <div class="payment-header">Payment method:</div>
				    <div class="payment-methods">
					    <div @click="chosePaymentMethod('cabinCharge')" class="method">
						    <div class="method-title">Cabin/Crew charge</div>
						    <div v-if="paymentMethods.cabinCharge.state" class="method-active"><img src="@/assets/img/24chosen.png"></div>
					    </div>
					    <div @click="chosePaymentMethod('houseAccount')" class="method">
						    <div class="method-title">House account</div>
						    <div v-if="paymentMethods.houseAccount.state" class="method-active"><img src="@/assets/img/24chosen.png"></div>
					    </div>
					    <!--<div @click="chosePaymentMethod('crewCharge')" class="method">
							<div class="method-title">Crew charge</div>
							<div v-if="paymentMethods.crewCharge.state" class="method-active"><img src="@/assets/img/24chosen.png"></div>
						</div>-->
				    </div>
			    </div>
			    <div class="payers" v-if="!paymentMethods.houseAccount.state">
				    <div class="payers-header">
					    Payer(s):
				    </div>
				    <div class="payers-list">
					    <table>
						    <tr>
							    <th>Name</th>
							    <th>Cabin</th>
							    <th>Payment</th>
							    <th>Delete</th>
						    </tr>
						    <tr v-for="(i, index) in selectOrder.payers">
							    <td>{{i.first_name + ' ' + i.last_name}}</td>
							    <td>{{i.cabin}}</td>
							    <td>{{i.person_type === 'C' ? 'Crew charge' : 'Cabin charge'}}</td>
							    <td><span @click="onDeletePayer(index)"><img src="../../assets/img/delete.svg" alt="delete"></span></td>
						    </tr>
					    </table>
				    </div>
				    <div class="def-btn secondary-action-btn icon left uppercase border-2 medium" @click="openSetPayerModal">
					    <img src="../../assets/img/24add.svg" alt="24add">
					    Add payer
				    </div>
			    </div>
			    <div class="buttons-navigate">
				    <button @click="close" class="def-btn cancel-action-btn uppercase  medium border-2">NO</button>
				    <button @click="closeOrder" class="def-btn main-action-btn uppercase medium border-2" :class="{'disabled': paymentMethods.cabinCharge.state && !selectOrder.payers.length}">YES</button>
			    </div>
		
		    </div>
	    </modal-component>
	    <set-the-payer-modal ref="set-the-payer-modal" :selected-order="selectOrder" @onSetPayer="onSetPayer" :new-order-creation="true"></set-the-payer-modal>
    </div>

</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import SetThePayerModal from "@/components/_modals/SetThePayerModal";

export default {
    name: "CloseOrderModal",
    emits:['closeOrder','upload','closeModal'],
    components: {SetThePayerModal, ModalComponent},
    props:{
        selectOrder: {
            type: Object,
	        required: true
        },
    },
    data() {
        return {
            paymentMethods:{
                cabinCharge: {state: true, id:1},
                houseAccount: {state: false, id:2}
            },
	        showSetThePayerModal: false
        }
    },
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
	methods: {
        openModal() {
            this.$refs['modal'].show();
        },
        close() {
	        this.$refs['modal'].close();
        },
	    openSetPayerModal() {
			this.showSetThePayerModal = true;
			this.$nextTick(() => {
				this.$refs['set-the-payer-modal'].openModal();
			})
	    },
        chosePaymentMethod(e) {
            for (let key in this.paymentMethods) {
                this.paymentMethods[key].state = false
            }
            this.paymentMethods[e].state = !this.paymentMethods[e.state]
        },
        closeOrder() {
			if ((this.paymentMethods.cabinCharge.state && this.selectOrder.payers.length) || this.paymentMethods.houseAccount.state) {
				let data = {
					id: this.selectOrder.id,
					payment_type: null
				}
				for (let key in this.paymentMethods) {
					if (this.paymentMethods[key].state){
						data.payment_type = this.paymentMethods[key].id;
						this.emmiter.emit('closeOrder', data);
					}
				}
			}
        },
	    onDeletePayer(index) {
			this.selectOrder.payers.splice(index, 1);
			this.$forceUpdate();
	    },
	    onSetPayer(data) {
			let index = this.selectOrder.payers.findIndex((i) => {return i.id === data.id});
			if (index === -1) {
				this.selectOrder.payers.push(data);
			}
	    }
    }
}
</script>

<style scoped lang="less">
.wr {
	:deep(.modal) {
		&.sss {
			.c-modal {
				&>.window {
					padding: 0;
				}
			}
		}
	}
}
.close-order-container {
    padding: 15px 55px 15px 55px;
	width: 650px;
	background-color: white;
    .header {
        margin: 30px 0 20px 0;
        &-text {
            font-weight: normal;
            font-size: 42px;
            line-height: 56px;
        }
        &-total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            margin-top: 24px;
            border-top: 1px solid rgba(53, 131, 197, .2);
            font-weight: bold;
            font-size: 14px;
            line-height: 148%;
            text-transform: uppercase;
            color: #1C282D;
            width: 100%;
        }
    }
	.payers {
		margin-bottom: 40px;
		.payers-header {
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			color: #1C282D;
			margin-bottom: 10px;
		}
		.payers-list {
			margin-bottom: 10px;
			table {
				width: 100%;
				table-layout: fixed;
				margin: 0;
				border-collapse: collapse;
				box-sizing: border-box;
				position: relative;
				tr {
					display: table;
					width: 100%;
					table-layout: fixed;
					&:nth-child(odd) {
						background-color: #FAF9FB;
					}
					th {
						border: 1px solid #e9ecef;
						vertical-align: middle;
						height: 30px;
						padding: 5px 10px;
						overflow-wrap: break-word;
						color: #14191f;
						font-size: 10px;
						text-align: center;
						text-transform: uppercase;
						&:nth-child(1) {
							width: 40%!important;
						}
						&:nth-child(2) {
							width: 25%!important;
						}
						&:nth-child(3) {
							width: 25%!important;
						}
						&:nth-child(4) {
							width: 10%!important;
						}
					}
					td {
						vertical-align: middle;
						height: 30px;
						padding: 5px 10px;
						overflow-wrap: break-word;
						color: #1a141f;
						font-size: 14px;
						text-align: center;
						border: 1px solid #e9ecef;
						&:nth-child(1) {
							width: 40%!important;
						}
						&:nth-child(2) {
							width: 25%!important;
						}
						&:nth-child(3) {
							width: 25%!important;
						}
						&:nth-child(4) {
							width: 10%!important;
							span {
								cursor: pointer;
								border: 1px solid #D9E8F3;
								border-radius: 12px;
								display: flex;
								justify-content: center;
								align-items: center;
								width: 40px;
								height: 30px;
								align-self: center;
								margin: 0 auto;
							}
						}
					}
				}
			}
		}
		.def-btn {
			width: 40%;
			margin: 0 auto;
		}
	}
    .payment {
	    margin-bottom: 50px;
        &-header {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #1C282D;
	        margin-bottom: 10px;
        }
        &-methods {
            .method {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 41px;
                border-bottom: 1px solid rgba(53, 131, 197, .2);
                padding-right: 15px;
                &:active{
                    background: #D9E8F3;
                    //border: none;
                }
            }

        }
    }
	.buttons-navigate {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		button {
			width: 45%;
		}
	}
}
</style>
