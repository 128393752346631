import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import httpInterceptor from "@/_helpers/http-interceptor";
httpInterceptor();
import clickOutside from "@/_directives/clickOutside";
import rippleEffect from "@/_directives/rippleEffect";
import mitt from "mitt";
import currencyFilter from "@/filters/currency.filter";

const app = createApp(App).use(store).use(router);
app.directive('click-outside', clickOutside);
app.config.globalProperties.$filters = {
    cur:currencyFilter
}
app.directive('ripple-effect', rippleEffect);
app.config.globalProperties.emmiter = mitt();
app.config.globalProperties.config = window.___env;
app.mount('#blast');
