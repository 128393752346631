<template>
	<div id="split-order-modal">
		<modal-component :custom-class="'split-order-modal'" ref="modal" @onModalClose="onModalClose">
			<div class="modal-title">Split order</div>
			<div class="modal-subtitle">select items you want to allocate to new order</div>
			<div class="payer-table-select-wrapper">
				<div class="payer-select-wrapper" :class="{'required': validation && getPosData.department.flow_ordering_guest && !newPayer}">
					<div class="payer-select-label">New payer <span v-if="getPosData.department.flow_ordering_guest">(required)</span></div>
					<div class="payer-select" :class="{'not-specified': !newPayer}" @click="openSetPayerModal">
						<span>{{ newPayer ? `${newPayer.first_name} ${newPayer.last_name}` : 'N/A' }}</span>
					</div>
				</div>
				<div class="table-select-wrapper">
					<div class="table-select-label">Table</div>
					<div class="table-select" @click="openSetTableModal" :class="{'not-specified': (!newTable ? (!selectedOrder.table) :  (!newTable.table))}">
						<span>{{ !newTable ? (selectedOrder.table ? selectedOrder.table : 'N/A') : (newTable.table ? newTable.table : 'N/A')}}</span>
					</div>
				</div>
			</div>
			<div class="items-list-wrapper">
				<table>
					<thead>
						<tr>
							<th></th>
							<th class="item">item</th>
							<th class="qty">qty</th>
							<th class="st">st.</th>
							<th class="seat">seat</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(i, index) in selectedOrder.items">
							<td @click="i.checked = !i.checked">
								<img :src="i.checked ? require('../../assets/img/split-order-checkbox-on.svg') : require('../../assets/img/split-order-checkbox-off.svg')" alt="split-order-checkbox-off.svg">
							</td>
							<td>{{i.name}}</td>
							<td>
								<div class="qty">
									<img v-if="design === 'ecruise'" src="../../assets/img/minus.svg" alt="minus" @click="changeQty(i, 'minus')">
									<img v-else-if="design === 'margaritaville'" src="../../assets/img/btnincell-m.svg" alt="minus" @click="changeQty(i, 'minus')">
									<span>{{ i.qty_to_split }}</span>
									<img v-if="design === 'ecruise'" src="../../assets/img/plus.svg" alt="plus" @click="changeQty(i, 'plus')">
									<img v-else-if="design === 'margaritaville'" src="../../assets/img/btnincell-plus-m.svg" alt="plus" @click="changeQty(i, 'plus')">
								</div>
							</td>
							<td><div class="state"><span :class="`circle status status-${i.status}`"></span></div></td>
							<td>{{(selectedOrder.table) ? (i.seat ? i.seat : '1') : 'N/A'}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="control-btns">
				<button @click="close"  class="def-btn cancel-action-btn border-2 uppercase medium">CANCEL</button>
				<button @click="splitOrder" class="def-btn main-action-btn border-2 uppercase medium" :class="{'disabled': !areAnySelected.length || (getPosData.department.flow_ordering_guest && !newPayer)}">split</button>
			</div>
		</modal-component>
		<set-the-payer-modal v-if="showSetPayerModal" ref="set-the-payer-modal" :selected-order="null" :new-order-creation="true" @onSetPayer="onPayerSelect"></set-the-payer-modal>
		<new-order-modal-component v-if="showSetTableModal" ref="new-order-modal-component" :is-edit="true" :no-business-logic="true" @onTableSetup="onTableSetup"/>
	</div>
</template>
<script>
import ModalComponent from "@/components/_shared/Modal";
import SetThePayerModal from "@/components/_modals/SetThePayerModal";
import NewOrderModalComponent from "@/components/_modals/NewOrderModal";

export default {
	name: "SplitOrderModal",
	props: {
		selectedOrder: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			newPayer: null,
			newTable: null,
			showSetPayerModal: false,
			showSetTableModal: false,
			items: [],
			validation: false
		}
	},
	emits: ['combineOrder', 'onModalClose'],
	components: {NewOrderModalComponent, SetThePayerModal, ModalComponent},
	computed: {
		design() {
			return this.$store.state.UI
		},
		areAnySelected() {
			return this.selectedOrder.items.filter((o) => {return o.checked})
		},
		getPosData: function () {
			return this.$store.state.auth.posData
		}
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
	methods: {
		onModalClose() {
			this.$emit('onModalClose')
		},
		openModal() {
			console.log(this.getPosData.department.flow_ordering_guest);
			this.$refs['modal'].show();
			this.selectedOrder.items.forEach((o) => {
				o['qty_to_split'] = 1;
			});
			this.items = JSON.parse(JSON.stringify(this.selectedOrder.items))
			/*if (this.selectedOrder.payers.length) {
				this.newPayer = this.selectedOrder.payers[0];
			}*/
		},
		openSetPayerModal() {
			this.showSetPayerModal = true;
			this.$nextTick(() => {
				this.$refs['set-the-payer-modal'].openModal();
				this.$refs['set-the-payer-modal'].selectPerson(this.newPayer);
			})
		},
		openSetTableModal() {
			this.showSetTableModal = true;
			this.$nextTick(() => {
				this.$refs['new-order-modal-component'].openModal(this.selectedOrder);
			})
		},
		close() {
			this.$refs['modal'].close()
		},
		onPayerSelect(data) {
			console.log(data);
			this.newPayer = data;
		},
		onTableSetup(data) {
			console.log(data);
			this.newTable = data;
		},
		changeQty(i, type) {
			console.log(i);
			if (type === 'minus') {
				if (i.qty_to_split > 1) {
					i.qty_to_split -= 1;
				} else {
					console.log('MINUS');
					/*if (this.selectOrder.items.length === 1) {
						this.$refs['selected-order-component'].openCancelOrderModal()
					}*/
				}
			} else {
				if (i.qty_to_split < i.count) {
					i.qty_to_split += 1;
				}
			}
		},
		splitOrder() {
			if ((this.areAnySelected.length && !this.getPosData.department.flow_ordering_guest) || (this.areAnySelected.length && this.getPosData.department.flow_ordering_guest && this.newPayer)) {
				//getPosData.department.flow_ordering_guest
				const itemsToSplit = JSON.parse(JSON.stringify(this.areAnySelected));
				itemsToSplit.forEach((o) => {
					o.count = o.qty_to_split
				});
				this.selectedOrder.items.forEach((o, index) => {
					itemsToSplit.forEach(oo => {
						if (oo.id === o.id) {
							if (o.count - o.qty_to_split === 0) {
								o.to_delete = true;
							} else {
								o.count -= o.qty_to_split
							}
						}
					})
				});
				
				this.selectedOrder.items = this.selectedOrder.items.filter((o, index, a2) => {if (!o.to_delete) {return o} })
				let orderSplitTo = {
					table: !this.newTable ? (this.selectedOrder.table ? this.selectedOrder.table : null) : (this.newTable ? this.newTable.table : null),
					is_split: true,
					payers: this.newPayer ? [this.newPayer] : [],
					seat: !this.newTable ? (this.selectedOrder.seat ? this.selectedOrder.seat : null) : (this.newTable ? this.newTable.seat : null),
					items: itemsToSplit
				};
				console.log(orderSplitTo);
				console.log(this.selectedOrder);
				this.emmiter.emit('splitOrder', {
					update: this.selectedOrder,
					create: orderSplitTo
				});
				this.close();
			} else {
				this.validation = true;
			}
		},
	}
}
</script>

<style scoped lang="less">

#split-order-modal {
	::v-deep(.split-order-modal) {
		.window {
			background-color: #F5F9FC!important;
			width: 500px;
		}
	}
	.modal-title {
		margin-bottom: 10px;
		font-size: 42px;
		font-weight: 300;
		color: #3F8EC5;
	}
	.modal-subtitle {
		margin-bottom: 20px;
		color: #1C282D;
		font-size: 14px;
		font-weight: 600;
	}
	.payer-table-select-wrapper {
		margin-bottom: 20px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		.payer-select, .table-select {
			position: relative;
			z-index: 2;
			border-radius: 4px;
			background: #ffffff;
			border: 1px solid lightgray;
			box-sizing: border-box;
			padding-left: 11px;
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			width: auto;
			height: auto;
			line-height: 30px;
			padding-right: 20px;
			display: flex;
			span {
				font-size: 18px;
				color: #1c282d;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			
			&:after {
				position: absolute;
				content: "▾";
				color: black;
				right: 1em;
				width: 6px;
				height: 6px;
				top: 0;
			}
			&.not-specified {
				span {
					font-size: 10px;
				}
			}
		}
		.payer-select-label, .table-select-label {
			text-align: left;
			color: #1C282D;
			font-size: 14px;
			font-weight: 600;
		}
		.payer-select-wrapper {
			width: 75%;
			&.required {
				.payer-select {
					border-color: red;
				}
			}
		}
		.table-select-wrapper {
			width: 20%;
		}
	}
	.items-list-wrapper {
		width: 100%;
		background-color: #ffffff;
		margin-bottom: 40px;
		table {
			width: 100%;
			table-layout: fixed;
			margin: 0;
			border-collapse: collapse;
			box-sizing: border-box;
			position: relative;
			tbody {
				overflow-y: scroll;
				max-height: 250px;
				display: block;
			}
			thead {
				display: table;
				width: 100%;
				table-layout: fixed;
			}
			tr {
				display: table;
				width: 100%;
				table-layout: fixed;
				&:nth-child(odd) {
					background-color: #FAF9FB;
				}
				th {
					border-top: 2px solid #e9ecef;
					border-bottom: 1px solid #e9ecef;
					vertical-align: middle;
					height: 30px;
					padding: 5px 10px;
					overflow-wrap: break-word;
					color: #14191f;
					font-size: 10px;
					text-align: center;
					text-transform: uppercase;
					&:nth-child(1) {
						width: 8%!important;
					}
					&:nth-child(2) {
						width: 42%!important;
						text-align: left;
					}
					&:nth-child(3) {
						width: 30%!important;
					}
					&:nth-child(4) {
						width: 10%!important;
					}
					&:nth-child(5) {
						width: 10%!important;
					}
				}
				td {
					vertical-align: middle;
					height: 30px;
					padding: 5px 10px;
					overflow-wrap: break-word;
					color: #1a141f;
					font-size: 14px;
					text-align: center;
					&:nth-child(1) {
						width: 8%!important;
					}
					&:nth-child(2) {
						width: 42%!important;
						text-align: left;
					}
					&:nth-child(3) {
						width: 30%!important;
					}
					&:nth-child(4) {
						width: 10%!important;
					}
					&:nth-child(5) {
						width: 10%!important;
					}
					.state {
						display: flex;
						align-items: center;
						justify-content: center;
						.status {
							background-color: red;
							&.status-1 {
								background-color: #EB262B;
							}
							&.status-2 {
								background-color: #FFCB05;
							}
							&.status-3 {
								background-color: #82B941;
							}
						}
						.circle {
							height: 12px;
							width: 12px;
							border-radius: 100%;
						}
					}
					.qty {
						display: flex;
						justify-content: center;
						align-items: center;
						img {
							cursor: pointer;
						}
						span {
							padding: 0 5px;
						}
					}
				}
			}
		}
	}
	.control-btns {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		button {
			width: 47%;
		}
	}
}
</style>
