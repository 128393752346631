<template>
	<div id="discount-details-modal-component">
		<modal-component ref="modal" @onModalClose="onModalClose()">
			<div class="container">
				<div class="content">
					<div class="title">
						Discount details
					</div>
					<div class="discounts-table">
						<table>
							<tr>
								<th>ITEM</th>
								<!--<th>count</th>
								<th>percentage</th>-->
								<th>AMOUNT</th>
							</tr>
							<tr v-for="i in itemsWithDiscount">
								<!--<td>{{i.reason}}</td>
								<td>{{i.count}}</td>-->
								<td>{{i.name}}</td>
								<td>{{'$' + i.total_amount_with_discount.toFixed(2)}}</td>
							</tr>
						</table>
						<div class="total">
							TOTAL DISCOUNT: <span>${{ totalDiscount }}</span>
						</div>
					</div>
				</div>
				<div class="controls">
					<button class="def-btn main-action-btn uppercase medium border-2" @click="closeModal()">ok</button>
				</div>
			</div>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";

export default {
	name: 'DiscountDetailsModalComponent',
	props: {
		selectedOrder: {
			default: null,
			required: true
		}
	},
	components: {ModalComponent},
	data() {
		return {
			mockingArr: [
				{
					reason: 'The order is made from 6:00 to 9:00',
					count: 'Unlimited',
					percentage: '',
					amount: '$1.00'
				},{
					reason: 'First order',
					count: '1',
					percentage: '10%',
					amount: '$1.34'
				},{
					reason: 'Personal discount',
					count: '2',
					percentage: '',
					amount: '$2.00'
				},
			]
		}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
		itemsWithDiscount() {
			return this.selectedOrder.items.filter((o) => {return o.discount})
		},
		totalDiscount() {
			let count = 0;
			this.itemsWithDiscount.forEach((o) => {
				count += o.discount
			})
			return count.toFixed(2)
		},
	},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {
		onModalClose() {},
		openModal() {
			this.$refs['modal'].show()
		},
		closeModal() {
			this.$refs['modal'].close()
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>
#discount-details-modal-component {
	.container {
		width: 600px;
		.content {
			margin-bottom: 40px;
			.title {
				font-size: 42px;
				font-weight: 300;
				color: #3F8EC5;
				margin-bottom: 40px;
			}
			.discounts-table {
				width: 100%;
				padding: 0 10%;
				box-sizing: border-box;
				table {
					width: 100%;
					table-layout: fixed;
					margin: 0;
					border-collapse: collapse;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 20px;
					tr {
						&:first-child {
							border-top: 1px solid #e9ecef;
						}
						&:not(:last-child) {
							border-bottom: 1px solid #e9ecef;
						}
						&:last-child {
							border-bottom: 3px solid #e9ecef;
						}
						th {
							font-weight: 500;
							padding: 10px 0;
							&:nth-child(1) {
								width: 40%!important;
								text-align: left;
							}
							&:nth-child(2) {
								width: 25%!important;
								text-align: right;
							}
							&:nth-child(3) {
								width: 20%!important;
								text-align: right;
							}
							&:nth-child(4) {
								width: 15%!important;
								text-align: right;
							}
						}
						td {
							padding: 10px 0;
							&:nth-child(1) {
								width: 40%;
								text-align: left;
							}
							&:nth-child(2) {
								width: 25%!important;
								text-align: right;
							}
							&:nth-child(3) {
								width: 20%!important;
								text-align: right;
							}
							&:nth-child(4) {
								width: 15%!important;
								text-align: right;
							}
						}
					}
				}
				.total {
					text-align: right;
					font-weight: bold;
					text-transform: uppercase;
					span {
						margin-left: 20px;
					}
				}
			}
		}
		.controls {
			width: 100%;
			button {
				margin: 0 auto;
				width: 30%;
			}
		}
	}
}

</style>
