<template>
    <div class="search">
	    <div id="search-bar" class="search-bar" @click="openKeyboard">
		    <input type="text" v-model="data" @change="onChange" @input="onInput" @keyup="onKeyup"
		           :placeholder="placeholder"/>
		    <div class="icon"><img src="@/assets/img/search.png"></div>
		    
	    </div>
	    <keyboard :show-by-default="false" :has-blur-action="true" :ref="parentComp" @clickOnKey="click" @clear="click"></keyboard>
    </div>
</template>

<script>
import Keyboard from "@/components/_shared/Keyboard";
export default {
    name: 'SearchBarComponent',
    props: {
        placeholder: {
            default: 'Type in ...',
            required: false
        },
        clearInput: {
            default: false,
            required: false
        },
	    parentComp: {
			type: String,
		    required: false,
		    default: 'keyboard'
	    }
    },
    components: {Keyboard},
    data() {
        return {
            data: '',
        }
    },
    computed: {},
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
	emits: ['onChange', 'onKeyup', 'onInput'],
    methods: {
        onChange(e) {
            this.$emit('onChange', this.data);
        },
        onKeyup(e) {
            this.$emit('onKeyup', this.data);
        },
	    onInput(e) {
            this.$emit('onInput', e)
        },
	    click(e) {
			console.log(e);
		    if (e === undefined) {
			    this.data = this.data.substring(0, this.data.length - 1);
			    let a = {
				    target: {
					    value: this.data
				    }
			    }
				this.onInput(a);
		    } else {
			    this.data += e;
			    let a = {
				    target: {
					    value: this.data
				    }
			    }
			    this.onInput(a);
		    }
	    },
	    openKeyboard() {
			if (this.$refs['ordersList']) {
				this.$refs['ordersList'].closeKeyboard();
			}
			if (this.$refs['menuList']) {
				this.$refs['menuList'].closeKeyboard();
			}
		    this.$refs[this.parentComp].showKeyboard();
	    }
    },
    watch: {
        'clearInput': function (e) {
            if (e) {
                this.data = '';
            }
        }
    }
}
</script>

<style lang="less" scoped>
.search {
	width: 100%;
	margin-bottom: 10px;
}
#search-bar {
    width: 100%;
    position: relative;
    // background: #F6F6F6;
    input {
        width: 100%;
        box-sizing: border-box;
        // background-color: transparent;
        border: 1px solid gray;
        padding: 10px;
        border-radius: 6px;
        outline: none;
	    background: #F6F6F6;
    }
    .icon {
        position: absolute;
        right: 15px;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -6px;
    }
}
</style>
