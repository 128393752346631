<template>
    <modal-component ref="modal" :has-keyboard="true" @clickOnKey="click">
        <div class="set-payer-container">
            <div class="header">
                <h1 class="header-title">Set the {{setWaiter ? 'waiter' : 'payer'}}</h1>
            </div>
            <div class="selected-user" v-if="selectedUser === null">
	            <div class="img"><img :src="require('@/assets/img/nophoto.png')" alt="user-image"></div>
                <div class="not-selected-user-text">Not specified yet</div>
            </div>
            <div class="selected-user" v-else>
                <div class="img"><img :src="selectedUser.photo ? 'data:image/png;base64,' + selectedUser.photo : require('@/assets/img/nophoto.png')" alt="user-image"></div>
                <div class="info">
                    <div class="label">Name</div>
                    <div class="name">{{setWaiter ? selectedUser.name : `${selectedUser.first_name} ${selectedUser.last_name}`}}</div>
                    <div class="label">Cabin</div>
                    <div class="cabin">{{calcCabin}}</div>
	                <div class="not-a-payer" v-if="!setWaiter && !selectedUser.posting_allowed">Not a payer</div>
                </div>
                <button @click="clearSelectedUser()" class="clear-btn">CLEAR</button>
            </div>
            <div class="search">
                <div class="search-header">
                    <div class="search-type">Search the passenger by name or cabin</div>
                    <div class="or">-- or --</div>
                    <div class="scan" @click="openBarcodeScannerModal">
                        <img v-if="design === 'ecruise'" src="@/assets/img/barcode.png" alt="group-icon">
                        <img v-else-if="design === 'margaritaville'" src="@/assets/img/barcode.svg" alt="group-icon">
                        <div class="scan-text">scan Passenger’s card</div>
                    </div>
                </div>
                <div class="search-input">
	                <!--@input="filterPersons"-->
                    <input v-model="searchInput" class="search-person" placeholder="Name, Cabin number, etc.">
                    <label>
                        <button @click="searchUsers">
                            <img src="@/assets/img/24search.png" alt="search-icon">
                            <span>Search</span>
                           </button>
                    </label>
                </div>
            </div>
            <div class="pas-list">
                <div class="nores" v-if="!usersList.length">No search results</div>
                <div class="users-wrapper" v-else>
                    <div class="user users-list-header bold">
                        <div class="cabin" @click="sortBy('cabin')">cabin</div>
                        <div class="name" @click="sortBy('name')">name</div>
                    </div>
                   <div class="scroll-wrapper" @scroll="infiniteScrollHandle">
                       <div class="user user-block" v-for="i in usersList" @click="selectPerson(i)" :class="{'is-selected': selectedUser !== null && (i.id === selectedUser.id)}">
                           <div class="cabin">{{ i.cabin || '-' }}</div>
                           <div class="name">{{ setWaiter ? i.name : `${i.first_name} ${i.last_name}` }}</div>
                       </div>
                   </div>

                </div>
            </div>
            <div class="buttons-navigate">
                <button @click="close" class="def-btn cancel-action-btn uppercase medium width100 border-2">CANCEL</button>
                <button @click="setPayer" class="def-btn main-action-btn uppercase medium width100 border-2">OK</button>
            </div>
        </div>
    </modal-component>
	<barcode-scanner-modal v-if="showBarcodeScannerModal" ref="barcode-scanner-modal" @submitBarcode="handleBarcode" @onModalClose="onBarcodeScannerModalHidden"></barcode-scanner-modal>
</template>
<script>
import ModalComponent from "@/components/_shared/Modal";
import {orderService} from "@/_api/order.api";
import Keyboard from "@/components/_shared/Keyboard";
import BarcodeScannerModal from "@/components/_modals/BarcodeScannerModal";

export default {
    components: {Keyboard, ModalComponent, BarcodeScannerModal},
	props: {
		selectedOrder: {
			required: true,
			default: null
		},
		newOrderCreation: {
			required: false,
			type: Boolean,
			default: false
		},
		setWaiter: {
			required: false,
			type: Boolean,
			default: false
		}
	},
    data() {
        return {
			usersList: [],
	        selectedUser: null,
	        page: 0,
	        per_page: 50,
	        sort: 'desc',
	        order_by: 'name',
	        search: '',
            searchInput:'',
            newPersons:[],
            selectedPerson:[],
	        showBarcodeScannerModal: false
        }
    },
	emits: ['setPayer', 'onSetPayer'],
	computed: {
		design() {
			return this.$store.state.UI
		},
		calcCabin() {
			if (this.selectedUser.cabin) {
				return this.selectedUser.cabin
			} else {
				if (this.selectedUser.pivot) {
					return this.selectedUser.pivot.cabin
				} else {
					return '-'
				}
			}
			// (selectedUser.pivot ? selectedUser.cabin : selectedUser.pivot.cabin) || '-'
		}
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close()
		})
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
	methods: {
	    infiniteScrollHandle(e) {
			if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
				this.getUsers(false);
			}
	    },
        openModal() {
	        this.selectedUser = null;
			if (!this.newOrderCreation) {
				if (this.setWaiter) {
					this.selectPerson(this.selectedOrder.opened_by);
				} else {
					if (this.selectedOrder.payers.length) {
						this.selectPerson(this.selectedOrder.payers[0]);
					}
				}
			}
	        console.log(this.selectedOrder);
	        console.log(this.selectedUser);
	        this.getUsers(true);
            this.$refs['modal'].show();
        },
        close() {
			if (this.$refs['modal']) {
				this.$refs['modal'].close();
				this.usersList = [];
				this.selectedUser = null;
				this.page = 0;
				this.per_page = 50;
				this.sort = 'desc';
				this.order_by = 'name';
				this.search = '';
				this.searchInput ='';
				this.newPersons =[];
				this.selectedPerson =[];
				this.showBarcodeScannerModal = false;
			}
        },
        selectPerson(data){
			this.selectedUser = data;
        },
	    setPayer() {
			if (!this.setWaiter && !this.selectedUser.posting_allowed) {
			
			} else {
				console.log('AAAAAAAAA');
				console.log(this.selectedUser);
				if (this.newOrderCreation) {
					this.$emit('onSetPayer', this.selectedUser);
					this.$refs['modal'].close();
				} else {
					if (this.setWaiter) {
						this.emmiter.emit('setWaiter', this.selectedUser);
					} else {
						this.emmiter.emit('setPayer', this.selectedUser);
					}
					
				}
			}
        },
	    clearSelectedUser() {
			this.selectedUser = null;
	    },
	    searchUsers() {
			this.search = this.searchInput;
			this.getUsers(true);
	    },
	    sortBy() {
		    this.getUsers(true);
	    },
		click(e) {
			console.log(e);
			if (e === undefined) {
				this.searchInput = this.searchInput.substring(0, this.searchInput.length - 1);
				if (!this.searchInput.length) {
					this.search = '';
					this.getUsers(true);
				}
			} else {
				this.searchInput += e;
			}
		},
	    getUsers(reset) {
			let method = this.setWaiter ? orderService.getPosUsers : orderService.getUsers;
			if (reset) {
				this.page = 0;
				this.usersList = [];
			}
		    method({
				search: this.search,
				order_by: this.order_by,
				sort: this.sort,
				page: !this.page ? this.page += 1 : this.page,
			    per_page: this.per_page
			}).then((res) => {
				res.data.forEach((obj) => {
					this.usersList.push(obj);
				});
				if (res.data.length > 0) {
					this.page += 1;
				}
			})
	    },
		openBarcodeScannerModal() {
			this.showBarcodeScannerModal = true;
			this.$nextTick(() => {
				this.$refs['barcode-scanner-modal'].openModal();
			})
		},
		onBarcodeScannerModalHidden() {
			this.showBarcodeScannerModal = true;
		},
		handleBarcode(data) {
			this.searchInput = data;
			this.search = data;
			this.getUsers(true);
		}
    }
}
</script>

<style lang="less" scoped>
.keyboard {
	z-index: 99999;
	bottom: 0;
	margin: 0 auto;
	left: 0;
	right: 0;
}
.set-payer-container{
    width: 500px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
    .header {
	    margin-bottom: 10px;
	    width: 100%;
        &-title{
            font-size: 42px;
            font-weight: normal;
            line-height: 56px;
            // color: var(--theme_color);
	        margin: 0;
        }
    }
    .selected-user {
        height: 140px;
        display: flex;
        align-items: flex-start;
	    width: 100%;
        position: relative;
	    margin-bottom: 10px;
	    .not-selected-user-text {
		    margin-top: 50px;
		    font-style: normal;
		    font-weight: normal;
		    font-size: 24px;
		    line-height: 32px;
		    color: #8DC7E1;
		    margin-left: 18px;
	    }
        img{
            width: 110px;
            height: 140px;
        }
        .info{
            // width: 240px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: self-start;
            margin-left: 18px;
	        
	        div {
		        text-align: left;
		        &:not(:last-child) {
			        margin-bottom: 10px;
		        }
		        &:nth-child(even) {
			        margin-left: 30px;
			        font-weight: normal;
			        font-size: 18px;
			        line-height: 24px;
			        color: #1C282D;
			        text-transform: uppercase;
		        }
		        &:nth-child(odd) {
			        font-weight: 600;
			        font-size: 14px;
			        line-height: 16px;
			        color: #1C282D;
		        }
		        &.not-a-payer {
			        color: red;
		        }
	        }
        }
        .clear-btn{
            width: 84px;
            height: 23px;
            background: #FFFFFF;
            //border: 1px solid var(--default_cancel_btn_color);
            box-sizing: border-box;
            border-radius: 8px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            //color: var(--default_cancel_btn_color);
            position: absolute;
	        right: 0;
	        top: 0;
            cursor: pointer;
        }
    }
    .search {
        border-top: 1px solid #E5E0EB;
        margin-bottom: 20px;
        padding-top: 12px;
	    width: 100%;
	    display: flex;
	    flex-wrap: wrap;
        &-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
	        width: 100%;
            .search-type{
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #1C282D;
            }
            .or{
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #777E81;
                margin: 0 34px;
            }
            .scan {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &-text{
                    margin-left: 15px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #1C282D;
                }
            }
        }
        &-input{
            display: flex;
            margin-top: 18px;
	        width: 100%;
	        justify-content: flex-start;
	        align-items: center;
	        flex-wrap: nowrap;
            .search-person{
                border: 1px solid #7B809A;
                box-sizing: border-box;
                border-radius: 4px 0 0 4px;
                font-weight: normal;
                font-size: 16px;
                align-items: center;
                color: #777E81;
	            width: 100%;
                height: 40px;
                padding-left: 13px;
            }
            label{
                button{
                    cursor: pointer;
                    border: none;
                    width: 120px;
                    height: 40px;
                    //background: var(--theme_color);
                    border-radius: 0 8px 8px 0;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    img{
                        width: 19px;
                        height: 19px;
                        color: white;
                        margin:0 8px;

                    }
                }

            }
        }
    }
    .pas-list{
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 20px 0;
	    width: 100%;
        .nores{
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: #8DC7E1;
        }
        .users-wrapper{
	        width: 100%;
	        .users-list-header {
		        div {
			        cursor: pointer;
		        }
	        }
            .scroll-wrapper{
                overflow: scroll;
                border: none;
                height: 150px;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            .bold{
                font-weight: bold;
                font-size: 12px;
                line-height: 148%;
                text-transform: uppercase;
            }
            .user{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-top:  1px solid rgba(53, 131, 197,.2);
	            min-height: 40px;
	            cursor: pointer;
	            transition: all 250ms;
	            &.is-selected {
		            background-color: #efefef;
	            }
                &:last-child{
                    border-bottom: 1px solid rgba(53, 131, 197,.2);
                }
                &:active{
                    // background: #8DC7E1;
                }
                .cabin{
                    width: 100px;
                    text-align: left;
	                padding: 0 10px;
                }
                .name{
                    text-transform: uppercase;
	                padding: 0 10px;
                }
            }

            .printer-block{
                cursor: pointer;
            }
        }
    }
    .buttons-navigate{
	    width: 80%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    flex-wrap: nowrap;
	    button {
		    &:first-child {
			    margin-right: 10px;
		    }
	    }
    }
}

@media (max-width: 1200px) {
	.set-payer-container {
		.search {
			&-input {
				label {
					button {
						font-size: 12px;
					}
				}
			}
		}
	}
}
</style>
