<template>
    <div id="keyboard" class="keyboard-wrapper" :class="{'external': !showByDefault}">
	    <div class="container" v-if="show" v-click-outside="blur">
		    <div class="cross" v-if="hasCloseBtn"><img class="close" src="@/assets/img/close.png " @click="closeKeyboard"></div>
		    <div class="keyboard">
			    <div class="row first-row">
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','q') : $emit('clickOnKey','1')" class="letter ">{{type === 'keyboard' ? 'q' : 1}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','w') : $emit('clickOnKey','2')" class="letter ">{{type === 'keyboard' ? 'w' : 2}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','e') : $emit('clickOnKey','3')" class="letter ">{{type === 'keyboard' ? 'e' : 3}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','r') : $emit('clickOnKey','4')" class="letter ">{{type === 'keyboard' ? 'r' : 4}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','t') : $emit('clickOnKey','5')" class="letter ">{{type === 'keyboard' ? 't' : 5}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','y') : $emit('clickOnKey','6')" class="letter ">{{type === 'keyboard' ? 'y' : 6}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','u') : $emit('clickOnKey','7')" class="letter ">{{type === 'keyboard' ? 'u' : 7}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','i') : $emit('clickOnKey','8')" class="letter ">{{type === 'keyboard' ? 'i' : 8}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','o') : $emit('clickOnKey','9')" class="letter ">{{type === 'keyboard' ? 'o' : 9}}</button>
				    <button @click="type === 'keyboard' ? $emit('clickOnKey','p') : $emit('clickOnKey','0')" class="letter ">{{type === 'keyboard' ? 'p' : 0}}</button>
				    <button @click="$emit('clear')" class="letter letter_del"><img src="@/assets/img/backSpaceIcon.png "></button>
			    </div>
			    <div class="row row_second">
				    <button @click="$emit('clickOnKey','a')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'a' : ''}}</button>
				    <button @click="$emit('clickOnKey','s')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 's' : ''}}</button>
				    <button @click="$emit('clickOnKey','d')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'd' : ''}}</button>
				    <button @click="$emit('clickOnKey','f')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'f' : ''}}</button>
				    <button @click="$emit('clickOnKey','g')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'g' : ''}}</button>
				    <button @click="$emit('clickOnKey','h')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'h' : ''}}</button>
				    <button @click="$emit('clickOnKey','j')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'j' : ''}}</button>
				    <button @click="$emit('clickOnKey','k')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'k' : ''}}</button>
				    <button @click="$emit('clickOnKey','l')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'l' : ''}}</button>
				    <button class="letter inactive"></button>
					<button class="letter inactive letter_enter"><!--<img src="@/assets/img/entericon.png ">--></button>
			    </div>
			    <div class="row row_third">
				    <button class="letter inactive"><!--<img src="@/assets/img/arrowShiftLeft.png ">--></button>
				    <button @click="$emit('clickOnKey','z')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'z' : ''}}</button>
				    <button @click="$emit('clickOnKey','x')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'x' : ''}}</button>
				    <button @click="$emit('clickOnKey','c')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'c' : ''}}</button>
				    <button @click="$emit('clickOnKey','v')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'v' : ''}}</button>
				    <button @click="$emit('clickOnKey','b')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'b' : ''}}</button>
				    <button @click="$emit('clickOnKey','n')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'n' : ''}}</button>
				    <button @click="$emit('clickOnKey','m')" class="letter " :class="{'inactive': type !== 'keyboard'}">{{type === 'keyboard' ? 'm' : ''}}</button>
				    <button class="letter inactive"></button>
					<button class="letter inactive"></button>
					<button class="letter inactive"></button>
				    <button class="letter inactive letter_shift"><!--<img src="@/assets/img/arrowShiftLeft.png ">--></button>
			    </div>
			    <div class="row row_fourth">
				    <!--$emit('clickSymbol')-->
				    <button @click="type === 'keyboard' ? changeType('keypad') : changeType('keyboard')" class="letter ">{{type === 'keyboard' ? '&123' : 'ABC'}}</button>
				    <button class="letter inactive"></button>
				    <button class="letter inactive"><!--<img src="@/assets/img/emojiIcon.png ">--></button>
				    <button class="letter letter_space"></button>
				    <button class="letter"> &lt; </button>
					<button class="letter"> > </button>
					<button class="letter inactive"></button>
			    </div>
		    </div>
		    <!--<keypad v-if="type === 'keypad'" :keyboardToggle="true" @toggleKeyboardType="changeType" @clickOnKey="keypadClick" @clear="$emit('clear')"></keypad>-->
	    </div>
    </div>
</template>

<script>
import Keypad from "@/components/_shared/Keypad";
export default {
    name: "Keyboard",
	components: {Keypad},
	emits: ['clickOnKey', 'clear'],
	data() {
		return {
			show: false,
			blurCanBeTriggered: false,
			type: 'keyboard'  // keyboard / keypad
		}
	},
	props: {
		hasCloseBtn: {
			type: Boolean,
			default: true,
			required: false
		},
		hasBlurAction: {
			default: false,
			required: false
		},
		showByDefault: {
			default: true,
			required: false
		}
	},
	created() {
		if (this.showByDefault) {
			this.show = this.showByDefault;
		}
	},
	methods: {
		showKeyboard() {
			this.show = false;
			setTimeout(() => {
				this.show = true;
				this.blurCanBeTriggered = true;
			}, 0)
		},
		changeType(type) {
			this.type = type;
		},
		closeKeyboard() {
			this.show = false;
			this.blurCanBeTriggered = false;
		},
		blur() {
			if (this.hasBlurAction) {
				if (this.show && this.blurCanBeTriggered) {
					this.closeKeyboard();
				}
			}
		},
		onClick(e) {
			this.$emit('clickOnKey', e);
		},
		keypadClick(e) {
			this.$emit('clickOnKey', e);
		}
	}
}
</script>

<style lang="less" scoped>
#keyboard {
	width: 100%;
	z-index: 1;
	position: absolute;
	&.external {
		bottom: 8px;
		left: 0;
		right: 0;
	}
	&.keypad-type {
		border: none;
		.container {
			box-shadow: none;
			border: none;
			background-color: unset;
			padding: 0;
			width: 100%;
			.keypad {
				margin: 0 auto;
			}
		}
	}
	.container{
		width: 1010px;
		padding: 10px;
		background: #FFFFFF;
		box-shadow: 0 0 10px 0 #bdbdbd;
		border-radius: 10px;
		margin: 0 auto;
		.cross {
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
			.close{
				padding: 15px;
			}
		}
		.row {
			grid-template-columns: repeat(10,75px) 162px;
			display: grid;
			height: 63px;
			grid-column-gap: 6px;
			margin: 6px 8px 0 8px;
			.letter{
				border: none;
				width: 75px;
				height: 63px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: normal;
				font-size: 30px;
				line-height: 40px;
				color: #FFFFFF;
				&.inactive {
					background-color: #A3BFDC;
				}
				&_del{
					width: 162px;
				}
				&_enter{
					width: 136px;
				}
				&_space{
					width: 486px;
				}
				&_shift{
					width: 81px;
				}
				&:active{
					background: #95c7ec;
					
				}
			}
			&_second{
				margin:6px 8px 0 34px;
			}
			&_third{
				grid-template-columns: repeat(12,75px) ;
			}
			&_fourth{
				grid-template-columns: repeat(3,75px) 486px repeat(3,75px) ;
			}
		}
	}
}
@media (max-width: 1200px) {
	#keyboard {}
}
@media (max-width: 850px) {
	#keyboard {
		.container {
			width: unset;
			.row {
				grid-template-columns: repeat(10, 55px) 134px;
				
				.letter {
					height: unset;
					width: unset;
				}
				&.row_second {
					grid-template-columns: repeat(10, 55px) 108px;
				}
				&.row_third {
					grid-template-columns: repeat(12, 56.5px);
				}
				&.row_fourth {
					grid-template-columns: repeat(3, 55px) 376px repeat(3, 55px)!important;
				}
			}
		}
	}
}
</style>
