<template>
    <div id="selected-order" class="selected-order">
        <div class="order" v-if="selectOrder">
            <div class="order-details">
                <div class="col waiter" @click="setWaiterModal">
                    <div class="title">
                        Waiter
                    </div>
	                <div class="col-data payer-data" :class="{'not-specified': !selectOrder.opened_by, 'disabled': isPayerFuncDisabled}">
		                <span>{{selectOrder.opened_by ? selectOrder.opened_by.name : 'Not specified yet'}}</span>
		                <img class='dropdown-icon' src="../../assets/img/dropdown-icon.svg" alt="dropdown-icon">
	                </div>
                </div>
                <div class="col table" @click="editOrderTable">
                    <div class="title">
                        Table
                    </div>
	                <div class="col-data" :class="{'not-specified': !selectOrder.table}">
		                <span>{{selectOrder.table ? selectOrder.table : 'N/A'}}</span>
		                <img class='dropdown-icon' src="../../assets/img/dropdown-icon.svg" alt="dropdown-icon">
	                </div>
                </div>
                <div class="col seat">
                    <div class="title">
                        Seat
                    </div>
                    <dropdown ref="seat-dd" :options="calcSeats" :has-input="false"
                                     :has-preselected-value="true" :placeholder="'N/A'" @onItemClick="seatFilterSelect"></dropdown>
                </div>
	            <div class="col payers" @click="setPayerModal">
		            <div class="title">
			            Payer
		            </div>
		            <div class="col-data" :class="{'not-specified': !selectOrder.payers.length}">
			            <span>{{selectOrder.payers.length ? `${selectOrder.payers[0].first_name} ${selectOrder.payers[0].last_name}` : 'N/A'}}</span>
			            <img class='dropdown-icon' src="../../assets/img/dropdown-icon.svg" alt="dropdown-icon">
		            </div>
	            </div>
	            <div class="col payer-cabin">
		            <div class="title">
			            Cabin
		            </div>
		            <div class="col-data disabled" :class="{'not-specified': !selectOrder.buyer_profile || !selectOrder.buyer_profile.cabin}">
			            <!--<span>{{selectOrder.payers.length && selectOrder.payers[0].pivot.cabin ? selectOrder.payers[0].pivot.cabin : 'Not specified yet'}}</span>-->
			            <span>{{(selectOrder.payers.length ? (selectOrder.payers[0].cabin ? selectOrder.payers[0].cabin : (selectOrder.payers[0].pivot.cabin ? selectOrder.payers[0].pivot.cabin : '')) : 'Not specified')}}</span>
		            </div>
	            </div>
            </div>
            <div class="items-list">
                <table>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
	                    <th>Disc.</th>
	                    <th>Total</th>
                        <th>QTY</th>
                        <th>St.</th>
                        <th>Seat</th>
                        <!--<th></th>-->
                    </tr>
                    <tr v-for="(i,index) in ((filterBySeats !== 'All') ? filterList : selectOrder.items)" :key="index">
                        <td @click="openEditModal(index)">{{ i.name }}</td>
                        <td>{{ i.price.toFixed(2) }}</td>
	                    <td>{{ i.disc ? i.disc : '0.00' }}</td>
	                    <td>{{ i.total = (i.count * i.price).toFixed(2) }}</td>
                        <td class="qty-td" style="margin-left: -20px;">
                            <div>
                                <img v-show="design === 'ecruise' && i.status === 1" src="../../assets/img/minus.svg" alt="minus" @click="changeQty(i, 'minus')">
	                            <img v-show="design === 'margaritaville' && i.status === 1" src="../../assets/img/btnincell-m.svg" alt="minus" @click="changeQty(i, 'minus')">
                                <span>{{ i.count }}</span>
                                <img v-show="design === 'ecruise' && i.status === 1" src="../../assets/img/plus.svg" alt="plus" @click="changeQty(i, 'plus')">
	                            <img v-show="design === 'margaritaville' && i.status === 1" src="../../assets/img/btnincell-plus-m.svg" alt="plus" @click="changeQty(i, 'plus')">
                            </div>
                        </td>
                        <td>
                            <div class="state" @click="openChangeItemStateModal(i)">
                                <div :class="`circle status status-${i.status}`"></div>
                            </div>
                        </td>
                        <td>{{ (selectOrder.table) ? (i.seat ? i.seat : '1') : 'N/A' }}</td>
                        <!--<td>
                            <button @click="openEditModal(index)" class="edit-btn">
	                            <img v-if="design === 'ecruise'" src="@/assets/img/Frame.png">
	                            <img v-else-if="design === 'margaritaville'" src="@/assets/img/item-edit-m.svg">
                            </button>
                        </td>-->
                    </tr>
                </table>
            </div>
            <div class="order-calculations">
                <div class="calc-wrapper">
	                <div class="row">
		                <div class="title">subtotal</div>
		                <div class="amount">{{ selectOrder.items.length > 0 ? selectOrder.total_amount.toFixed(2) : '0.00' }}</div>
	                </div>
	                <div class="row">
		                <div class="title">surcharge</div>
		                <div class="amount">0.00</div>
	                </div>
	                <div class="row">
		                <label class="switch">
			                <!--<input type="checkbox" v-model="discount">-->
			                <span :class="selectOrder.with_discount ? 'active' : null" class="slider round" @click="toggleDiscount">{{ selectOrder.with_discount ? 'Included' : 'Excerpted' }}</span>
		                </label>
		                <div class="title">discount</div>
		                <div class="amount">{{ selectOrder.with_discount ? calcDiscount : '0.00' }}</div>
		                <button v-if="selectOrder.with_discount" @click="openDiscountDetailsModal" class="def-btn secondary-action-btn uppercase medium border-2 discount-details">Discount details</button>
	                </div>
	                <div class="row">
		                <label class="switch">
			                <input type="checkbox" v-model="tax">
			                <span :class="tax ? 'active' : null"
			                      class="slider round">{{ tax ? 'Included' : 'Excerpted' }}</span>
		                </label>
		                <div class="title">tax</div>
		                <div class="amount">0.00</div>
	                </div>
	                <div class="row">
		                <label class="switch">
			                <input type="checkbox" v-model="autogratuity">
			                <span :class="autogratuity ? 'active' : null"
			                      class="slider round">{{ autogratuity ? 'Included' : 'Excerpted' }}</span>
		                </label>
		                <div class="title">autogratuity</div>
		                <div class="amount autogratuity">0.00</div>
	                </div>
	                <div class="row total">
		                <div class="title">total</div>
		                <div class="amount">{{ selectOrder.total_amount_with_discount ? selectOrder.total_amount_with_discount.toFixed(2) : selectOrder.total_amount.toFixed(2) }}</div>
	                </div>
                </div>
            </div>
            <div class="controls">
                <div class="row x3">
                    <button class="def-btn secondary-action-btn uppercase medium border-2 disabled" v-ripple-effect>add gratuity</button>
                    <button class="def-btn secondary-action-btn uppercase medium border-2" @click="addRound()" v-ripple-effect>add round</button>
                    <button @click="openSplitOrderModal" class="def-btn secondary-action-btn uppercase medium border-2" v-ripple-effect>split check</button>
                </div>
                <div class="row x2">
	                <!--@click="openFoodRunnerModal"-->
                    <button  class="def-btn secondary-action-btn uppercase medium border-2 icon left disabled" v-ripple-effect>
                        <img v-if="design === 'ecruise'" src="../../assets/img/print-food-runner-chit.svg" alt="print-food-runner-chit">
	                    <img v-else-if="design === 'margaritaville'" src="../../assets/img/print-food-runner-chit-m.svg" alt="print-food-runner-chit">
                        <span>print runner chit</span>
                    </button>
                    <button class="def-btn main-action-btn uppercase medium border-2 icon left" v-ripple-effect @click="openPrintModal">
                        <img src="../../assets/img/print-check-icon.svg" alt="print-check-icon">
                        <span>print check</span>
                    </button>
                </div>
                <div class="row x2">
                    <button @click="cancelOrder(false)" :class="{'disabled': isWaiterAssist}" class="def-btn cancel-action-btn uppercase medium border-2" v-ripple-effect>
	                    cancel order
                    </button>
                    <button @click="openCloseOrderModal"
                            class="def-btn main-action-btn uppercase medium border-2 icon left"
                            :class="{'disabled': isWaiterAssist}"
                            v-ripple-effect>
                        <img src="../../assets/img/close-order.svg" alt="close-order">
                        <span>close order</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="noOrders" v-else>
            No orders selected
        </div>
    </div>
    <print-check-modal
	    :selectOrder="selectOrder"
        ref="print-check-modal"
        v-if="showPrintCheck"
    ></print-check-modal>
    <new-order-modal-component
        v-if="editOrderTableModal"
        :is-edit="true"
        ref="edit-order-table-modal"
    ></new-order-modal-component>
    <cancel-order-modal
        ref="cancel-order-modal"
        v-if="showCancelOrderModal"
        @submitCancelOrder="cancelOrder(true)"
    />
    <close-order-modal
        :selectOrder="selectOrder"
        ref="close-order-modal"
        v-if="showCloseOrderModal"
        @upload="this.emmiter.emit('refreshOrderList')"
        @closeOrder="$emit('closeOrder')"
    />
    <add-edit-item-modal
        @addEditItem="editItem"
        :action="'edit'"
        :selectedItem="selectedItem"
        :selectedOrder="selectOrder"
        ref="add-new-item-modal"
        v-if="showAddNewOrderModal"
        @deleteItem="deleteItem"
    />
    <select-the-printer-modal
        ref="select-the-printer-modal"
        v-if="showSelectThePrinterModal"
        @printFoodRunner="openFoodRunnerModal"
        @selectedPrinter="selectPrinter"
    />
    <food-runner-modal
        :selectedOrder="selectOrder"
        ref="food-runner-modal"
        v-if="showFoodRunnerModal"
        :selectedPrinter="selectedPrinter"
        @selectPrinter="openSelectThePrinterModal"
    />
    <set-the-payer-modal
        ref="set-the-payer-modal"
        :selectedOrder="selectOrder"
        :set-waiter="setWaiter"
        v-if="showSetThePayerModal"
    />
    <change-item-state-modal
        ref="change-item-state-modal"
        :selectedOrder="selectOrder"
        v-if="showChangeItemStateModal"
    />
	<split-order-modal
			:selectedOrder="selectOrder"
			ref="split-order-modal"
			v-if="showSplitOrderModal"
	/>
	<discount-details-modal-component ref="discount-details-modal-component" v-if="showDiscountDetailsModal" :selectedOrder="selectOrder"/>
</template>

<script>
import Dropdown from "@/components/_shared/Dropdown";
import ModalComponent from "@/components/_shared/Modal";
import PrintCheckModal from "@/components/_modals/PrintCheckModal";
import NewOrderModalComponent from "@/components/_modals/NewOrderModal";
import CancelOrderModal from "@/components/_modals/CancelOrderModal";
import CloseOrderModal from "@/components/_modals/CloseOrderModal";
import AddEditItemModal from "@/components/_modals/AddEditItemModal";
import SelectThePrinterModal from "@/components/_modals/SelectThePrinterModal";
import FoodRunnerModal from "@/components/_modals/FoodRunnerModal";
import SetThePayerModal from "@/components/_modals/SetThePayerModal";
import ChangeItemStateModal from "@/components/_modals/ChangeItemStateModal";
import SplitOrderModal from "@/components/_modals/SplitOrderModal";
import {orderService} from "@/_api/order.api";
import DiscountDetailsModalComponent from "@/components/_modals/DiscountDetailsModal";

export default {
    name: 'SelectedOrderComponent',
    props: {
        selectOrder: {
            require: true,
            type: Object
        }
    },
    emits: ['upload', 'closeOrder', 'addEditItem', 'deleteItem', 'printFoodRunner', 'selectedPrinter', 'print', 'editItems', 'changeItemQty', 'addRound'],
    components: {
	    DiscountDetailsModalComponent,
	    SplitOrderModal,
        ChangeItemStateModal,
        SetThePayerModal,
        FoodRunnerModal,
        SelectThePrinterModal,
        CloseOrderModal, AddEditItemModal,
        CancelOrderModal, ModalComponent, PrintCheckModal, Dropdown, NewOrderModalComponent
    },
    data() {
        return {
            showSetThePayerModal: false,
	        setWaiter: false,
            showFoodRunnerModal: false,
            showSelectThePrinterModal: false,
            showPrintCheck: false,
            showAddNewOrderModal: false,
            showCancelOrderModal: false,
            showCloseOrderModal: false,
	        showSplitOrderModal: false,
	        editOrderTableModal: false,
            showChangeItemStateModal: false,
	        showDiscountDetailsModal: false,
            selectedPayer: null,
            tax: false,
            autogratuity: false,
            discount: false,
            stateColor: {
                1: '#EB262B',
                2: '#FFCB05',
                3: '#82B941',
            },
            waitersList: [
                {
                    id: 1,
                    name: 'Pedro gonsalez'
                }, {
                    id: 2,
                    name: 'Pedro gonsalez 2'
                }, {
                    id: 3,
                    name: 'Pedro gonsalez 3'
                },
            ],
            payersList: [],
            tablesList: [],
            seatsList: [],
            selectedItem: {},
            selectedPrinter: [],
	        filterBySeats: 'All'
        }
    },
    computed: {
	    design() {
		    return this.$store.state.UI
	    },
		calcSeats() {
			let t = [... new Set(this.selectOrder.items.map((o) => {return o.seat}))];
			let tt = t.map((o) => {return {
				name: o
			}});
			tt.unshift({name: 'All'});
			if (this.selectOrder.table) {
				return tt
			} else {
				return  [];
			}
		},
	    filterList() {
			return this.selectOrder.items.filter((o) => {return o.seat === this.filterBySeats})
	    },
	    getUser() {
			return this.$store.state.auth.user
	    },
	    isManager() {
		    return this.$store.state.auth.user.user.role === 'manager'
	    },
	    isWaiterAssist() {
			return this.$store.state.auth.user.user.role === 'waiter assist'
	    },
	    calcDiscount() {
		    let count = 0;
			this.selectOrder.items.forEach(o => {
				count += o.discount
			});
			return count.toFixed(2)
	    },
	    isPayerFuncDisabled() {
			if (this.getUser.user.role === 'waiter' && this.selectOrder.opened_by.id !== this.getUser.user.id) {
				return true
			} else if (this.getUser.user.role === 'waiter assist') {
				return true
			} else {
				return false
			}
		    // (getUser.user.role === 'waiter' && selectOrder.opened_by.id !== getUser.user.id) || getUser.user.role === 'waiter assist'
	    }
    },
    created() {},
    mounted() {},
    beforeUnmount() {},
    methods: {
	    openDiscountDetailsModal() {
		    if (this.selectOrder.items.length > 0) {
			    this.showDiscountDetailsModal = true
			    this.$nextTick(() => {
				    this.$refs['discount-details-modal-component'].openModal();
			    })
		    }
	    },
        openFoodRunnerModal() {
            if (this.selectOrder.items.length > 0) {
                this.showFoodRunnerModal = true
                this.$nextTick(() => {
                    this.$refs['food-runner-modal'].openModal();
                })
            }
        },
	    openSplitOrderModal() {
            if (this.selectOrder.items.length > 0) {
                this.showSplitOrderModal = true;
                this.$nextTick(() => {
                    this.$refs['split-order-modal'].openModal();
                })
            }
        },
        openSelectThePrinterModal() {
            if (this.selectOrder.items.length > 0) {
                this.showSelectThePrinterModal = true
                this.$nextTick(() => {
                    this.$refs['select-the-printer-modal'].openModal();
                })
            }
        },
        openEditModal(index) {
	        // this.selectedItem = null;
	        // let data = [this.selectOrder.items.find(el => el.id === id)];
	        this.selectedItem = this.selectOrder.items[index];
	        this.showAddNewOrderModal = true;
	        this.$nextTick(() => {
		        this.$refs['add-new-item-modal'].openModal();
	        })
        },
        openPrintModal() {
            if (this.selectOrder.items.length > 0) {
                this.showPrintCheck = true
                this.$nextTick(() => {
                    this.$refs['print-check-modal'].openModal(this.selectOrder);
                })
            }
        },
	    editOrderTable() {
			this.editOrderTableModal = true;
		    this.$nextTick(() => {
			    this.$refs['edit-order-table-modal'].openModal(this.selectOrder);
		    })
	    },
	    setPayerModal() {
		    this.showSetThePayerModal = true;
		    this.setWaiter = false;
		    this.$nextTick(() => {
			    this.$refs['set-the-payer-modal'].openModal();
		    })
        },
	    setWaiterModal() {
			if (this.getUser.user.role === 'manager' || (this.getUser.user.role === 'waiter' && this.selectOrder.opened_by.id === this.getUser.user.id)) {
				console.log('aaaaaaaaaa')
				this.showSetThePayerModal = true;
				this.setWaiter = true;
				this.$nextTick(() => {
					this.$refs['set-the-payer-modal'].openModal();
				})
			}
        },
        cancelOrder(cancel) {
	        if (this.isWaiterAssist) {} else {
		        if (cancel || !this.selectOrder.items.length) {
			        this.emmiter.emit('cancelOrder', this.selectOrder);
		        } else {
			        this.showCancelOrderModal = true;
			        this.$nextTick(() => {
				        this.$refs['cancel-order-modal'].openModal(this.selectOrder);
			        })
		        }
	        }
        },
        openCloseOrderModal() {
	        if (this.isWaiterAssist) {} else {
		        if (this.selectOrder.items.length > 0) {
			        this.showCloseOrderModal = true
			        this.$nextTick(() => {
				        this.$refs['close-order-modal'].openModal();
			        })
		        }
	        }
        },
        openChangeItemStateModal(data){
			if (data.status === 1) {
				this.showChangeItemStateModal = true;
				this.$nextTick(() => {
					this.$refs['change-item-state-modal'].openModal(data);
				})
			} else if (data.status === 2) {
				this.showChangeItemStateModal = true;
				this.$nextTick(() => {
					this.$refs['change-item-state-modal'].openModal(data, true, 0);
				})
			}
        
        },
        changeQty(i, type) {
			console.log(i);
			this.$emit('changeItemQty', i, type);
        },
        editItem(item) {
            const id = this.selectOrder.items.findIndex(el => el.id === item.id);
            this.selectOrder.items[id] = item;
            this.$emit('editItems', id, item);
        },
        deleteItem(item) {
            const id = this.selectOrder.items.findIndex(el => el.id === item.id);
	        if (this.selectOrder.items.length === 1) {
		        this.openCancelOrderModal();
	        } else {
		        this.$emit('deleteItem', id)
	        }
		       
        },
        selectPrinter(el) {
            this.selectedPrinter = []
            this.selectedPrinter.push(el)
        },
	    seatFilterSelect(data) {
		    this.filterBySeats = data;
	    },
	    addRound() {
		    this.$emit('addRound');
	    },
	    toggleDiscount() {
			this.emmiter.emit('toggleDiscount');
	    }
    },
    watch: {
        'selectOrder': function (el) {
            if (this.selectOrder) {
				if (this.$refs['seat-dd']) {
					this.filterBySeats = 'All';
					this.$refs['seat-dd'].refresh();
				}
            }
        }
    }
}
</script>

<style lang="less" scoped>

// SAFARI SUPPORT ONLY!
@supports (-webkit-hyphens:none) {
	.calc-wrapper {
		&:after {
			right: -2px!important;
		}
	}
	
}

#selected-order {
	height: 100%;
	width: 35%;
	min-width: 624px;
	border-radius: 10px;
	box-shadow: 0 1px 3px -1px #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	margin-right: 10px;
	.noOrders {
		font-weight: 400;
		font-size: 24px;
		line-height: 32px;
		// color: #e86d32;
		text-transform: uppercase;
	}
	.order {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		flex-direction: column;
		.order-details {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			// flex: 1;
			box-sizing: border-box;
			padding: 10px;
			.col {
				width: 25%;
				/*&:not(:last-child) {
					margin-right: 10px;
				}*/
				&:first-child {
					width: 50%;
					margin-right: 3%;
					margin-bottom: 10px;
				}
				&:nth-child(2) {
					width: 22%;
					margin-right: 3%;
					margin-bottom: 10px;
				}
				&:nth-child(3) {
					width: 22%;
					margin-right: 0;
					margin-bottom: 10px;
				}
				&:nth-child(4) {
					margin-right: 3%;
					width: 50%;
				}
				&:nth-child(5) {
					width: 22%;
				}
				
				.title {
					color: #1c282d;
					font-size: 10px;
					font-weight: 600;
					text-align: left;
				}
				::v-deep(.custom-select) {
					width: auto;
					height: auto;
					line-height: 30px;
					.selected {
						border-color: lightgray !important;
						&.no-items {
							.selected-output {
								font-size: 10px;
							}
						}
						.selected-output {
							font-size: 18px;
							color: #1c282d;
							font-weight: 400;
						}
					}
					
					.items {
						border-color: lightgray !important;
						.item {
							.text {
								font-size: 18px;
								color: #1c282d;
								font-weight: 400;
							}
						}
					}
					
					input {
						width: 100%;
						height: auto;
						box-sizing: border-box;
					}
				}
				.col-data {
					position: relative;
					z-index: 2;
					border-radius: 4px;
					background: #ffffff;
					border: 1px solid lightgray;
					box-sizing: border-box;
					padding-left: 11px;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					width: auto;
					height: auto;
					line-height: 30px;
					padding-right: 20px;
					display: flex;
					span {
						font-size: 18px;
						color: #1c282d;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.dropdown-icon {
						position: absolute;
						right: 7px;
						width: 7px;
						height: 7px;
						top: 50%;
						margin-top: -3.5px;
					}
					
					/*&:after {
						position: absolute;
						content: "▾";
						color: black;
						right: 1em;
						width: 6px;
						height: 6px;
						top: 0;
					}*/
					
					&.disabled {
						padding-right: 0;
						background-color: transparent;
						
						&:after {
							content: '';
						}
					}
					
					&.not-specified {
						span {
							font-size: 10px;
						}
					}
				}
			}
		}
		.items-list {
			width: 100%;
			flex: 2;
			overflow-y: scroll;
			// margin-bottom: 10px;
			background-color: #ffffff;
			position: relative;
			&:after {
				content: '';
				width: 2px;
				height: 100%;
				background-color: #e5e5e5;
				position: absolute;
				left: 55%;
				top: 0;
				// box-shadow: 1px 0 2px 0 #c3c3c3;
			}
			table {
				width: 100%;
				table-layout: fixed;
				margin: 0;
				border-collapse: collapse;
				box-sizing: border-box;
				position: relative;
				tr {
					&:nth-child(odd) {
						background-color: #FAF9FB;
					}
					th,td {
						&:nth-child(4) {
							position: relative;
							/*&:after {
								content: '';
								width: 2px;
								height: 100%;
								background-color: #e5e5e5;
								position: absolute;
								right: 5px;
								top: 0;
								// box-shadow: 1px 0 2px 0 #c3c3c3;
							}*/
						}
					}
				}
				th {
					border-top: 1px solid #e9ecef;
					border-bottom: 1px solid #e9ecef;
					vertical-align: middle;
					height: 30px;
					padding: 5px 10px;
					overflow-wrap: break-word;
					color: #14191f;
					font-size: 10px;
					text-align: center;
					text-transform: uppercase;
					
					&.left {
						text-align: left;
					}
					
					&:nth-child(1) {
						width: 25%!important;
						text-align: left;
						cursor: pointer;
					}
					&:nth-child(2) {
						width: 10%!important;
					}
					&:nth-child(3) {
						width: 10%!important;
					}
					&:nth-child(4) {
						width: 10%!important;
					}
					&:nth-child(5) {
						width: 23%!important;
					}
					&:nth-child(6) {
						width: 15%!important;
					}
					&:nth-child(7) {
						width: 7%!important;
					}
					/*&:nth-child(8) {
						width: 5%!important;
					}*/
				}
				td {
					vertical-align: middle;
					height: 30px;
					padding: 5px 10px;
					overflow-wrap: break-word;
					color: #1a141f;
					font-size: 14px;
					text-align: center;
					&:first-child {
						text-align: left;
						cursor: pointer;
					}
					.state {
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						.status {
							background-color: red;
							&.status-1 {
								background-color: #EB262B;
							}
							&.status-2 {
								background-color: #FFCB05;
							}
							&.status-3 {
								background-color: #82B941;
							}
						}
						.circle {
							height: 12px;
							width: 12px;
							border-radius: 100%;
						}
						
						.text {
							font-weight: normal;
							font-size: 12px;
							line-height: 148%;
							align-items: center;
							color: #1a141f;
							margin-left: 8px;
						}
						
					}
					
					&:nth-child(3) {
						padding: 5px 0;
						
					}
					
					&.qty-td {
						div {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							flex-wrap: nowrap;
							width: 80%;
							margin: 0 auto;
							span {
								padding: 0 10px;
								margin: 0 auto;
							}
							img {
								cursor: pointer;
							}
						}
					}
				}
				.edit-btn {
					border: none;
					background: white;
				}
			}
		}
		.order-calculations {
			width: 100%;
			flex: 1;
			box-sizing: border-box;
			// padding: 0 10px;
			padding: 0;
			border-radius: 8px;
			background-color: #fff;
			.calc-wrapper {
				width: 55%;
				position: relative;
				&:after {
					content: '';
					width: 2px;
					height: 100%;
					background-color: #e5e5e5;
					position: absolute;
					right: 0;
					top: 0;
					// box-shadow: 1px 0 2px 0 #c3c3c3;
				}
				&:before {
					content: '';
					width: 100%;
					height: 2px;
					background-color: #e5e5e5;
					position: absolute;
					left: -1px;
					bottom: -2px;
				}
				.row {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: nowrap;
					padding: 0 0 0 40%;
					box-sizing: border-box;
					background-color: white;
					position: relative;
					.discount-details {
						position: absolute;
						font-size: 14px;
						height: 30px;
						right: -180px;
					}
					.switch {
						position: absolute;
						width: 100px;
						height: 26px;
						left: 10px;
						top: 50%;
						margin-top: -13px;
						.slider {
							position: absolute;
							cursor: pointer;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							background-color: #cccccc;
							box-shadow: 0 0 1px #3f8ec5;
							border-radius: 34px;
							font-weight: 600;
							font-size: 12px;
							display: flex;
							align-items: center;
							text-transform: uppercase;
							justify-content: flex-end;
							padding-right: 10px;
							-webkit-transition: .5s;
							transition: .5s;
							&.active {
								color: #ffffff!important;
								justify-content: flex-start!important;
								padding-left: 10px;
								background: #3f8ec5;
								&:before {
									-webkit-transform: translateX(72px);
									-ms-transform: translateX(72px);
									transform: translateX(72px);
								}
							}
							&:before {
								position: absolute;
								content: "";
								height: 19px;
								width: 19px;
								left: 4px;
								bottom: 4px;
								border-radius: 50%;
								background-color: white;
								-webkit-transition: .4s;
								transition: .4s;
							}
						}
					}
					&:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}
					&:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}
					&:last-child {
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					}
					&.total {
						border-top-width: 2px;
						div {
							font-weight: 600;
						}
					}
					div {
						text-align: right;
						text-transform: uppercase;
						color: #14191f;
						font-size: 14px;
						padding: 5px 0;
					}
					.title {
						width: 60%;
						border-top: 1px solid #e3e3e3;
					}
					.amount {
						width: 40%;
						border-top: 1px solid #e3e3e3;
						padding-right: 15px;
					}
					.autogratuity {
						text-decoration-line: line-through;
					}
				}
			}
		}
		.controls {
			width: calc(100% - 20px);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			padding: 10px;
			box-sizing: border-box;
			flex: 1;
			
			.row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: nowrap;
				
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				
				&.x3 {
					button {
						width: 32%;
					}
				}
				
				&.x2 {
					button {
						width: 49%;
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	#selected-order {
		min-width: 440px;
		.order {
			.items-list {
				table {
					th {
						height: 30px;
						padding: 0 10px;
						font-size: 7px;
						&:nth-child(1) {
							width: 20%!important;
						}
						&:nth-child(2) {
							width: 12%!important;
						}
						&:nth-child(3) {
							width: 12%!important;
						}
						&:nth-child(4) {
							width: 12%!important;
						}
						&:nth-child(5) {
							width: 23%!important;
						}
						&:nth-child(6) {
							width: 13%!important;
						}
						&:nth-child(7) {
							width: 8%!important;
						}
					}
					td {
						font-size: 12px;
						height: 30px;
						padding: 0 10px;
						&.qty-td {
							padding: 0;
						}
					}
				}
			}
			.order-calculations {
				.calc-wrapper {
					.row {
						.switch {
							width: 80px;
							.slider {
								font-size: 8px;
								padding-right: 4px;
								&.active {
									padding-left: 7px;
									&:before {
										-webkit-transform: translateX(55px);
										-ms-transform: translateX(55px);
										transform: translateX(55px);
									}
								}
								&:before {
									left: 3px;
								}
							}
						}
						.discount-details {
							font-size: 10px;
						}
						div {
							font-size: 12px;
						}
					}
				}
			}
			.controls {
				button {
					font-size: 11px;
					height: 40px;
					padding: 5px 10px;
				}
			}
		}
	}
}
@media (max-width: 850px) {
	#selected-order {
		min-width: 480px;
	}
}

</style>
