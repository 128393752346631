import axios from "axios";
import store from "@/store";
import Router from "@/router";
export default function () {
	axios.interceptors.request.use(function (config) {
		if ( store.state.auth.user  ) {
			config.headers.Authorization = 'Bearer '+store.state.auth.user.token;
		}
		config.baseURL = store.state.API_BASE;
		// config.baseURL = process.env.VUE_APP_BASE_URL;
		return config
	}, function (err) {
		return Promise.reject(err)
	})
	
	axios.interceptors.response.use(function (response) {
		return response;
	}, function (err) {
		if (err.response.status === 401) {
			if (store.state.auth.user) {
				store.dispatch('logoutUser').then(() => {
					Router.replace({path: '/auth/login'})
				})
			}
		} else if (err.response.status === 403) {
			console.log(err.response);
			store.dispatch('alert/error', err.response.data.message ? err.response.data.message : err.response.data);
			if (err.response.data === 'User without POS' || err.response.data === 'User without POS and Department') {
				store.dispatch('logoutUser').then(() => {
					Router.replace({path: '/auth/login'});
					store.dispatch('terminalReset');
				})
			}
		}
		return Promise.reject(err)
	})
}
