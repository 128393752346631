import axios from "axios";

export const menuService ={
    getMenuItems,
    getSingleMenuItem,
    getMenuCategories,
    getSingleCategory,
    getSingleComboCategory,
}

function getMenuItems(data){
    return axios.get(
        `/menu/items`, {
            params:data
        }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function getSingleMenuItem(data) {
    return axios.get(`/menu/items${data.id}`).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function  getMenuCategories(data){
    return axios.get(
        `/menu/categories`, {
            params:data
        }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}
function  getSingleCategory(data){
    return axios.get(
        `/menu/categories/${data}`).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}
function  getSingleComboCategory(data){
    return axios.get(
        `/menu/combo/items`, {
            params: data
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return Promise.reject(err)
        })
}
