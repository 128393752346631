<template>
	<div id="refresh-btn" class="refresh-btn square-btn" :class="{'animation': animation}" @click="clickEvent">
		<img  src="../../assets/img/refresh.svg" alt="logout-btn" v-if="!close && design==='ecruise'"/>
		<img  src="../../assets/img/refresRed.png" alt="logout-btn" v-if="!close && design==='margaritaville'"/>
		<img  src="../../assets/img/close.jpg" alt="logout-btn" v-if="close"/>
	</div>
</template>

<script>
export default {
	name: 'RefreshBtnComponent',
	props: {
	    close: {
            type: Boolean,
            default: false
        }
    },
	components: {},
	data() {
		return {
			animation: false
		}
	},
	computed: {
        design(){
            return this.$store.state.UI
        }
    },
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {
		onAnimate() {
			this.animation = true;
			setTimeout(() => {this.animation = false}, 1000);
		},
		stopAnimation() {
			this.animation = false;
		},
		clickEvent() {
			this.$emit('clickEvent');
			this.onAnimate();
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>
#refresh-btn {
	&.animation {
		img {
			animation: spin 1s infinite;
		}
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
	/*100% {
		transform: rotate(0deg);
	}*/
}
</style>
