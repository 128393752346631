<template>
    <div id="orders-list" class="orders-list">
        <div class="controls-wrapper" v-if="combineOrder">
            <div class="def-btn main-action-btn big icon left align-left btn-next " @click="combineOrders" :class="{'disabled': !hasCheckedOptions}" v-ripple-effect>
                <span>Combine orders</span>
            </div>
            <refresh-btn-component :close="true" @click="combineOrder = false"
                                   class="refresh-btn"></refresh-btn-component>
        </div>
        <div class="controls-wrapper" v-else>
            <div class="def-btn main-action-btn big icon left align-left btn-next" @click="openNewOrderModal" v-ripple-effect >
                <img src="../../assets/img/logout-icon.svg" alt="logout-btn"/>
                <span>New order</span>
            </div>
            <refresh-btn-component ref="refresh-btn" @click="emmiter.emit('refreshOrderList', true)" class="refresh-btn"></refresh-btn-component>
        </div>
        <search-bar-component @onInput="(e)=>this.emmiter.emit('debounceSearch', e)" parentComp="menuList" :placeholder="'Table, Waiter, etc.'"></search-bar-component>
        <div class="list" @scroll="(e) => $emit('infinityScroll',e)">
            <div v-for="(i, index) in orders" :key="index" @click="onOrderSelect(i,index)" class="order" :class="{'active-order': i.active, 'highlighted': isHighlighted(i)}">
                <div class="table">
	                <div class="table-wrapper">
	                    <img :src="configureOrderStatus(i)" v-if="i.status === 1" alt="order-status">
                        <div class="table-number">{{ i.table ? i.table : null }}</div>
	                </div>
	                <div class="checkbox-wrap" v-if="combineOrder" @click.stop.prevent="i.idCheck ? null : i.checked = !i.checked">
		                <img v-if="i.idCheck" src="../../assets/img/checked-checkbox.svg" alt="checked-checkbox">
		                <img v-else-if="i.checked" src="../../assets/img/24chboxon.svg" alt="checked-checkbox">
		                <img v-else src="../../assets/img/unchecked-checkbox.svg" alt="unchecked-checkbox">
                    </div>
                    <img class="order-options" src="@/assets/img/dots.jpg" @click.stop.prevent="openOrderOptionsModal(i)" v-else alt="order-options">
                </div>
                <div class="text waiter">WAITER: <span>{{ i.opened_by.name }}</span></div>
                <div class="text guest">GUEST:
                    <span>{{(i.payers.length ? `${i.payers[0].first_name} ${i.payers[0].last_name}` : 'Not specified')}}</span>
                </div>
                <div class="text cabin">CABIN:
                    <span>{{ (i.payers.length ? (i.payers[0].cabin ? i.payers[0].cabin : (i.payers[0].pivot.cabin ? i.payers[0].pivot.cabin : '')) : 'Not specified') }}</span>
                </div>
            </div>
        </div>
    </div>

    <new-order-modal-component
        v-if="showNewOrderModal"
        ref="new-order-modal"
        :set-payer-after="getPosData.department.flow_ordering_guest"
    ></new-order-modal-component>
    <order-options-modal
        v-if="showOrderOptionsModal"
        ref="order-options-modal"
        @combineOrder="combineOrder = true"
        @onModalClose="onCombineOrdersModalClose"
    ></order-options-modal>
	<set-the-payer-modal ref="set-the-payer-modal" :new-order-creation="true" :selected-order="null" @onSetPayer="setPayer"></set-the-payer-modal>
	<combine-order-modal-component ref="combine-order-modal-component" ></combine-order-modal-component>
</template>

<script>
import SearchBarComponent from "@/components/_shared/SearchBar";
import RefreshBtnComponent from "@/components/_shared/RefreshButton";
import ModalComponent from "@/components/_shared/Modal";
import Keypad from "@/components/_shared/Keypad";
import NewOrderModalComponent from "@/components/_modals/NewOrderModal";
import OrderOptionsModal from "@/components/_modals/OrderOptionsModal";
import CombineOrderModalComponent from "@/components/_modals/CombineOrdersModal";
import SetThePayerModal from "@/components/_modals/SetThePayerModal";

export default {
    name: 'OrdersListComponent',
    props: {
        orders: {
            type: Array
        },
        selectOrder: {
            type: Object
        }
    },
    emits: ['infinityScroll', 'onOrderSelect', 'refresh'],
    components: {
	    SetThePayerModal,
	    CombineOrderModalComponent,
	    OrderOptionsModal, NewOrderModalComponent, Keypad, ModalComponent, RefreshBtnComponent, SearchBarComponent},
    data() {
        return {
            showNewOrderModal: false,
            showOrderOptionsModal: false,
            showSetThePayerModal: false,
            combineOrder: false,
            active: null,
	        highlighted: null,
	        combineOrdersIn: null
        }
    },
    computed: {
	    hasCheckedOptions: function () {
			return this.orders.filter((o) => {return o.checked}).length
	    },
	    getPosData: function () {
			return this.$store.state.auth.posData
	    }
    },
    watch: {
		'orders': function (data) {},
        'selectOrder': function (el) {
            if (el === null) {
                this.active = null
            }
        }
    },
    created() {
        this.emmiter.emit('refreshOrderList')
    },
    mounted() {},
    beforeUnmount() {
    },
    methods: {
		idCheck(i) {
			if (i.id && (i.id === this.combineOrdersIn.id)) {
				return true
			} else if ((i.tempId && (i.tempId === this.combineOrdersIn.tempId))) {
				return true
			}
			return false
		},
	    isHighlighted(i) {
		    if (this.highlighted !== null) {
			    if (this.highlighted.id !== null && (this.highlighted.id === i.id)) {
				    return true
			    } else if (this.highlighted.id === null && (this.highlighted.tempId === i.tempId)) {
				    return true
			    }
		    } else {
			    return false
		    }
	    },
		turnOffCombineOrder() {
			this.combineOrder = false;
		},
        onOrderSelect(i, index) {
            this.active = index;
            this.$emit('onOrderSelect', i);
        },
        openNewOrderModal() {
			let guestRequired = this.getPosData.department.flow_ordering_guest;
			let tableRequired = this.getPosData.department.flow_ordering_table;
			
			if (guestRequired && tableRequired) {
				this.showNewOrderModal = true;
				this.$nextTick(() => {
					this.$refs['new-order-modal'].openModal();
				})
			} else if (guestRequired && !tableRequired) {
				this.showSetThePayerModal = true;
				this.$nextTick(() => {
					this.$refs['set-the-payer-modal'].openModal();
				})
			} else if (!guestRequired && tableRequired) {
				this.showNewOrderModal = true;
				this.$nextTick(() => {
					this.$refs['new-order-modal'].openModal();
				})
			} else if (!guestRequired && !tableRequired) {
				this.emmiter.emit('addTempOrder', {
					table: '',
					seat: null
				});
			}
        },
	    setPayer(data) {
		    this.emmiter.emit('addTempOrder', {
			    table: '',
			    seat: null,
			    payers: [data]
		    });
	    },
	    configureOrderStatus(data) {
			let orderedStatus = data.items.filter((i) => {return i.status === 1});
			let placesStatus = data.items.filter((i) => {return i.status === 2});
			if (!data.items.length || orderedStatus.length) {
				return require('@/assets/img/redtable.png')
			} else if (!orderedStatus.length && placesStatus.length) {
				return require('@/assets/img/yellowtable.png')
			} else if (!orderedStatus.length && !placesStatus.length) {
				return require('@/assets/img/greentable.png')
			}
	    },
	    openOrderOptionsModal(order) {
			this.highlighted = order;
			this.combineOrdersIn = order;
            this.showOrderOptionsModal = true;
		    this.orders.forEach((i) => {
			    i['idCheck'] = this.idCheck(i);
		    })
            this.$nextTick(() => {
                this.$refs['order-options-modal'].openModal(order);
            })
        },
	    combineOrders() {
			if (this.hasCheckedOptions) {
				let arr = this.orders.filter((o) => {return o.checked});
				this.emmiter.emit('combineOrders', {
					ordersToCombine: arr,
					ordersCombineDetails: this.combineOrdersIn
				});
			}
	    },
	    onCombineOrdersModalClose() {
		    this.highlighted = null;
	    }
    },
}
</script>

<style lang="less" scoped>
input[type="checkbox"] {
    display: none;
}
/* check icon styles */
label {
    color: #000;
    cursor: default;
    font-weight: normal;
}
/* checkbox styles */
label:before {
    content: " ";
    color: #000;
    display: inline-block;
    position: relative;
    text-align: center;
    text-indent: 0px;
    width: 24px;
    height: 24px;
    background: #FFF;
    border: 1px solid #e3e3e3;
    border-image: initial;
    vertical-align: middle;
}
input:checked + label:before {
    content: "\2714";
    background: #3F8EC5;
    color: white;
    border: 1px solid #3F8EC5;


}
input:disabled + label:before {
    background: #eee;
    color: #aaa;
}

#orders-list {
	height: 100%;
	width: 15%;
	margin-right: 5px;
	display: flex;
	// flex-direction: column;
	align-items: flex-end;
	flex-wrap: wrap;
	// box-shadow: 0 1px 3px -1px #000000;
	.controls-wrapper {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		box-sizing: border-box;
		padding: 0 5px;
		width: 100%;
		// height: 70px;
		.def-btn, .refresh-btn {
			height: 60px;
		}
		div {
			&:first-child {
				margin-right: 12px;
			}
		}
		.btn-next {
			box-sizing: border-box;
			width: calc(100% - 72px);
		}
	}
	::v-deep(#search-bar) {
		// margin-bottom: 10px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 5px;
	}
	.list {
		width: 100%;
		height: calc(100% - 119px);
		// height: 100%;
		overflow-y: scroll;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		box-sizing: border-box;
		padding: 0 5px;
		&::-webkit-scrollbar {
			display: none;
		}
		.order {
			padding: 12px;
			min-height: 94px;
			background-color: #ffffff;
			margin-bottom: 10px;
			border-radius: 6px;
			box-shadow: 0 1px 4px 0 #a7a7a7;
			cursor: pointer;
			transition: all 200ms;
			box-sizing: border-box;
			width: 100%;
			position: relative;
			overflow: hidden;
			&.highlighted {
				z-index: 99999;
			}
			&.active-order {
				box-shadow: 0 1px 2px 1px #a7a7a7;
			}
			&:before {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
				content: '';
				width: 4px;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				transition: opacity 500ms;
			}
			.table {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.table-wrapper{
					display: flex;
				}
				.checkbox-wrap {
					img {
						width: 18px;
						height: 18px;
					}
				}
				.order-options{
					height: 4px;
					padding: 5px;
				}
				&-number {
					margin-left: 12px;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: -0.025em;
					color: #000000;
					font-weight: 600;
				}
			}
			.text {
				font-weight: 600;
				font-size: 10px;
				line-height: 18px;
				text-transform: uppercase;
				color: #1c282d;
				text-align: left;
				margin: 3px 0;

				span {
					font-weight: normal;
					font-size: 12px;
					line-height: 16px;
					color: #000000;
				}
			}
		}
		.active-order {
			&:before {
				opacity: 1;
			}
		}
	}
}

@media (max-width: 1200px) {
	#orders-list {
		min-width: 150px;
		.controls-wrapper {
			.def-btn {
				padding: 0;
			}
			img {
				display: none;
			}
		}
	}
}
@media (max-width: 850px) {
	#orders-list {
		min-width: 160px;
	}
}
</style>
