import {alert} from "@/store/alert.module";
import {auth} from "@/store/auth.module";
import Vuex from 'vuex'

export default new Vuex.Store({
	state: {
		API_BASE: window.___env.apiBase,
		UI: window.___env.UI
	},
	mutations: {},
	getters:{},
	actions: {},
	modules: {
		alert,
		auth,
	}
})
