<template>
	<nav id="nav" :class="design">
        <div class="content left-content" v-if="design==='ecruise'">
            <img class="logo" src="../../assets/img/eCruise_logo.png" alt="logo">
            <div class="department-title" v-if="getPosId && posData && posData.department">{{ posData.department.name }}</div>
        </div>
		<div class="content left-content" v-if="design==='margaritaville'">
			<img style="margin: 20px 0 0 -20px " src="../../assets/img/logoTrees.png" alt="logo" >
			<img style="margin-left: -110px" src="../../assets/img/margaritaville-logo-header.png" alt="logo" >
		</div>
		<div class="content right-content">
			<div class="pos-title" v-if="isLoggedIn">{{ getAuthUser.user.first_name }} {{getAuthUser.user.last_name}} <span v-if="getAuthUser.user.role === 'waiter assist'">(assistant)</span></div>
			<button @click="logout()" :class="'def-btn main-action-btn big border-2 medium icon left uppercase ' + design">
				<img src="../../assets/img/logout-icon.svg" alt="logout-btn"/>
				<span>log out</span>
			</button>
		</div>
	</nav>
</template>

<script>
import {authService} from "@/_api/auth.api";
import store from "@/store";

export default {
	name: 'HeaderComponent',
	props: {},
	components: {},
	data() {
		return {
			posData: null
		}
	},
	computed: {
		/*g() {
			return this.$store.state
		},*/
        design() {
            return this.$store.state.UI
        },
		getPosId() {
			return this.$store.state.auth.posId
		},
		getAuthUser() {
			return this.$store.state.auth.user
		},
		isLoggedIn() {
			return this.$store.state.auth.status.loggedIn
		},
    },
	created() {
		if (this.isLoggedIn && this.getPosId) {
			this.getPos();
		}
	},
	mounted() {},
	beforeUnmount() {},
	methods: {
		getPos() {
			authService.getPos({
				id: this.getPosId
			}).then((res) => {
				if (res) {
					this.posData = res;
					store.dispatch('savePosData', this.posData);
				}
			})
		},
		logout() {
			this.$store.dispatch('logoutUser').then(() => {
				this.$router.replace({path: '/auth/login'})
			})
		}
	},
	watch: {
		getPosId(res) {}
	}
}
</script>

<style lang="less" scoped>

#nav {
	display: none;
	height: 80px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	padding: 10px 18px;
	.content {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		&.right-content {
			.def-btn {
				padding: 30px 40px;
				&.margaritaville {
					/*background-color: var(--second_theme_color);
					border-color: var(--second_theme_color);*/
				}
			}
		}
		.logo {
			height: 70px;
			margin-right: 35px;
		}
		.department-title {
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			text-transform: uppercase;
			color: #3f8ec5;
		}
		.pos-title {
			margin-right: 35px;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 21px;
			text-align: right;
			text-transform: uppercase;
		}
		span {
			margin-left: 5px
		}
	}
}

@media (max-width: 1200px) {
	#nav {
		height: 50px;
		.content {
			.logo {
				height: 50px;
			}
			.def-btn {
				padding: 5px 25px!important;
			}
		}
	}
}
@media (max-width: 850px) {

}
</style>
