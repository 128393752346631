<template>
	<div id="barcode-scanner-modal">
		<modal-component ref="modal" @onModalClose="onModalClose">
			<div class="loading" v-if="loading"><img src="../../assets/img/Ripple-1s-200px.svg"></div>
			<StreamBarcodeReader ref="stream-barcode-reader" :style="!loading ? 'display:flex' : 'display:none' " @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
		</modal-component>
	</div>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import {StreamBarcodeReader} from "vue-barcode-reader";

export default {
	name: "BarcodeScannerModal",
	data() {
		return {
			loading: true
		}
	},
	emits: ['submitBarcode', 'onModalClose'],
	components: {ModalComponent, StreamBarcodeReader},
	created() {
		console.log(this.$refs['stream-barcode-reader']);
	},
	mounted() {},
	beforeUnmount() {},
	methods: {
		onLoaded() {
			this.loading = false;
		},
		onDecode (result) {
			this.$emit('submitBarcode', result);
			this.close();
		},
		onModalClose() {
			this.$emit('onModalClose');
			this.loading = true;
		},
		openModal() {
			if (navigator.mediaDevices === undefined) {
				alert('WebRTC is not supported. Website should have a valid certificate.')
			} else {
				navigator.mediaDevices.getUserMedia({video: true})
				.then((stream) => {
					this.loading = true;
					this.$refs['modal'].show();
				}, (err) => {
					alert('No devices found')
				})
			}
		},
		close() {
			this.$refs['modal'].close()
		},
	}
}
</script>

<style scoped lang="less">

#barcode-scanner-modal{
	:deep(#modal) {
		.window {
			padding: 0;
			height: auto;
			width: auto;
			max-width: 90%;
			min-width: 320px;
			.close-btn {
				top: 0;
				right: 0;
				padding: 30px;
			}
		}
	}
	:deep(.scanner-container) {
		width: 100%;
		height: 100%;
		&>div {
			width: 100%;
			height: 100%;
			video {
				width: 100%;
				height: 100%;
			}
			.overlay-element {
				height: 99.4%;
			}
		}
	}
	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
}
/*#barcode-scanner-modal {
	
	display: flex;
	justify-content: center;
	align-content: center;
	
	::v-deep (.scanner-container) {
	
	}
}*/

</style>
