<template>
    <modal-component ref="modal" @onModalClose="onModalClose">
        <div class="container">
            <button @click="combineOrderHandler"  class="def-btn secondary-action-btn uppercase border-2  medium">COMBINE ORDER</button>
            <button @click="cancelOrder(false)" class="def-btn delete-action-btn uppercase  border-2  medium" :class="{'disabled': isWaiterAssist}">Cancel order</button>
            <button @click="openCloseOrderModal" class="def-btn main-action-btn uppercase  border-2 medium" :class="{'disabled': isWaiterAssist}">Close order</button>
        </div>
    </modal-component>
    <close-order-modal
        :selectOrder="data"
        ref="close-order-modal"
        v-if="showCloseOrderModal"
        @closeModal="close"
    />
	<cancel-order-modal
		ref="cancel-order-modal"
		v-if="showCancelOrderModal"
		@submitCancelOrder="cancelOrder(true)"
	/>
	<combine-order-modal-component ref="combine-order-modal-component"></combine-order-modal-component>
</template>
<script>
import ModalComponent from "@/components/_shared/Modal";
import CloseOrderModal from "@/components/_modals/CloseOrderModal";
import CombineOrderModalComponent from "@/components/_modals/CombineOrdersModal";
import CancelOrderModal from "@/components/_modals/CancelOrderModal";

export default {
    name: "OrderOptionsModal",
    data() {
        return {
            data: null,
            showCloseOrderModal: false,
	        showCancelOrderModal: false
        }
    },
	emits: ['combineOrder', 'onModalClose', 'showCancelOrderModal'],
    components: {CombineOrderModalComponent, CloseOrderModal, ModalComponent, CancelOrderModal},
	computed: {
		isWaiterAssist() {
			return this.$store.state.auth.user.user.role === 'waiter assist'
		},
	},
	mounted() {
		this.emmiter.on('closeModal', () => {
			this.close();
		});
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
    methods: {
	    onModalClose() {
			this.$emit('onModalClose')
	    },
        openModal(data) {
            this.$refs['modal'].show();
            this.data = data;
        },
        close() {
            this.$refs['modal'].close();
        },
        openCloseOrderModal() {
	        if (this.isWaiterAssist) {} else {
		        if (this.data.items.length > 0) {
			        this.showCloseOrderModal = true
			        this.$nextTick(() => {
				        this.$refs['close-order-modal'].openModal();
			        })
		        }
	        }
        },
        cancelOrder(cancel) {
	        if (this.isWaiterAssist) {} else {
		        if (cancel || !this.data.items.length) {
			        this.emmiter.emit('cancelOrder', this.data);
		        } else {
			        this.showCancelOrderModal = true;
			        this.$nextTick(() => {
				        this.$refs['cancel-order-modal'].openModal(this.data);
			        })
		        }
	        }
        },
        combineOrderHandler() {
            this.$emit('combineOrder', this.data);
            this.close();
        }
    }
}
</script>

<style scoped lang="less">
.container{
    padding: 42px;
    button{
        width: 373px;
        &:not(:last-child){
            margin-bottom: 24px;
        }
    }
}

</style>
