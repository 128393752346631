<template>
	<modal-component ref="modal" @onModalClose="onModalClose()">
		<div id="combine-order-modal">
			<div class="modal-title">Combine orders details</div>
			<div class="table-select-wrapper" :class="{'no-payer': !payersSelect.length}">
				<div class="section-title">Table:</div>
				<div class="item" v-for="i in ordersToCombine" @click="selectTable(i.table)" :class="{'active': i.table === selectedData.table}">
					{{i.table}}
				</div>
			</div>
			<div class="payer-select-wrapper" v-if="payersSelect.length">
				<div class="section-title">Payer:</div>
				<div class="item" v-for="i in payersSelect" @click="selectPayer(i.payers[0])" :class="{'active': i.payers[0].id === (selectedData.payers[0] && selectedData.payers[0].id)}">
					{{i.payers[0].first_name}} {{i.payers[0].last_name}}
				</div>
			</div>
			
			<div class="control-btns">
				<button @click="closeModal"  class="def-btn cancel-action-btn border-2 uppercase medium">CANCEL</button>
				<button @click="submitCombineOrders" class="def-btn main-action-btn border-2 uppercase medium">COMBINE</button>
			</div>
		</div>
	</modal-component>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";

export default {
	name: 'CombineOrderModalComponent',
	props: {},
	components: {ModalComponent},
	data() {
		return {
			ordersToCombine: [],
			selectedData: {
				table: null,
				payer: null
			}
		}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
		payersSelect() {
			return this.ordersToCombine.filter((o) => {return o.payers.length})
		}
	},
	created() {},
	mounted() {
		this.emmiter.on('closeModal', () => {
			if (this.$refs['modal']) {
				this.$refs['modal'].close()
			}
		});
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
	methods: {
		onModalClose() {},
		openModal(data) {
			this.ordersToCombine = data;
			console.log(this.payersSelect.length);
			if (this.payersSelect.length === 1) {
				this.selectPayer(this.payersSelect[0].payers[0]);
			}
			this.selectedData.table = this.ordersToCombine[0].table;
			this.$refs['modal'].show();
		},
		closeModal() {
			this.$refs['modal'].close()
		},
		selectTable(data) {
			this.selectedData.table = data;
		},
		selectPayer(data) {
			if (data.id === (this.selectedData.payer && this.selectedData.payer.id)) {
				this.selectedData.payer = null;
			} else {
				this.selectedData.payer = data;
			}
		},
		submitCombineOrders() {
			this.emmiter.emit('combineOrders', {
				ordersToCombine: this.ordersToCombine,
				ordersCombineDetails: this.selectedData
			});
		}
	},
	watch: {}
}
</script>

<style lang="less" scoped>

#combine-order-modal {
	width: 500px;
	.modal-title {
		margin-bottom: 20px;
		font-size: 42px;
		font-weight: normal;
		color: var(--theme_color);
	}
	.table-select-wrapper {
		margin-bottom: 10px;
		&.no-payer {
			margin-bottom: 30px;
		}
	}
	.payer-select-wrapper {
		margin-bottom: 30px;
	}
	.section-title {
		text-align: left;
		margin-bottom: 10px;
		font-weight: bold;
	}
	.item {
		padding: 10px;
		text-align: left;
		background-color: #fff;
		color: var(--theme_color);
		border-radius: 10px;
		border: 1px solid var(--theme_color);
		margin-bottom: 10px;
		cursor: pointer;
		transition: all 250ms;
		&.active {
			background-color: var(--theme_color);
			color: #fff;
		}
	}
	.control-btns {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		margin: 0 auto;
		button {
			width: 48%;
		}
	}
}

</style>
