<template>
    <modal-component ref="modal">
        <div id="change-item-state-modal" class="change-item-state-modal">
            <div class="state-wrapper">
                <div class="header">Change the item state</div>
                <div class="states">
                    <div v-for="(s,i) in states" :class="s.selected ? 'selected' : 'state'" @click="changeStatus(i)" v-show="s.show">
                        <div class="circle" :style="{'background-color': circleColors[s.status-1]} "></div>
                        <div class="title">{{ s.name }}</div>
                    </div>
                </div>
            </div>
            <div class="control-btns">
                <button @click="close" class="def-btn cancel-action-btn uppercase medium border-2">NO</button>
                <button @click="submit" class="def-btn main-action-btn uppercase medium border-2">YES</button>
            </div>
        </div>
    </modal-component>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";

export default {
    name: "ChangeItemStateModal",
    emits: ['closeOrder', 'upload'],
    components: {ModalComponent},
    props: {
        selectedOrder: {
            type: Object
        },
    },
    data() {
        return {
            states: [
	            {name: 'Ordered', status: 1, show: true},
	            {name: 'Placed on the food runner chit', status: 2, show: true},
                {name: 'Served', status: 3, show: true},
            ],
            circleColors: ['#EB262B', '#FFCB05','#82B941'],
	        item: null
        }
    },
	created() {
		this.emmiter.on('closeModal', (reset) => {
			this.close();
		});
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
	computed: {},
    methods: {
        openModal(data, spliceOptionsArr, indexToSplice) {
			console.log(data);
			this.states.map((i) => {return i.show = true})
			if (spliceOptionsArr) {
				this.states[indexToSplice]['show'] = false;
			}
			
			this.item = data;
			if (data.status) {
				this.states.map((o) => {return o.status === data.status ? o.selected = true : o.selected = false})
			} else {
				this.states[2].selected = true;
			}
            this.$refs['modal'].show();
        },
        close() {
            this.$refs['modal'].close();
        },
	    submit() {
            console.log(this.states);
		    this.item['status'] = this.states.filter((o) => {return o.selected})[0].status;
			this.emmiter.emit('changeItemStatus', this.item);
        },
        changeStatus(i) {
            this.states.forEach(el => el.selected = false);
            this.states[i].selected = true;
        },
    }
}
</script>

<style scoped lang="less">
#change-item-state-modal {
    .header {
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 56px;
        color: #3F8EC5;
	    margin-bottom: 30px;
    }

    .states {
	    margin-bottom: 30px;
        .state {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 41px;
            padding-right: 15px;
            padding-left: 7px;
            width: 464px;
        }

        .selected {
            border: 1px solid #3F8EC5;
            box-sizing: border-box;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 41px;
            padding-right: 15px;
            padding-left: 6px;
            width: 100%;
        }

        .circle {
            border-radius: 100%;
            height: 12px;
            width: 12px;
            background: #FFCB05;
        }

        .title {
            padding-left: 7px;
        }
    }
	.control-btns {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		button {
			width: 45%;
		}
	}
	

}

</style>
