import axios from "axios";
import store from "@/store";
import Router from "@/router";

export const authService ={
    login,
    getDepartments,
    getPoses,
    getPos,
    setup
}
function login(obj){
    console.log(store.state.auth.posId);
    console.log(obj);
    let objToSend = {
        crew_internal_id: obj.crewId,
        pin_code: obj.pinCode,
        barcode: obj.barcode
    }
    
    if (store.state.auth.posId) {
        objToSend['pos_id'] = store.state.auth.posId
    }
    // alert(JSON.stringify(objToSend))
    return axios.post(`/login`, objToSend)
        .then(res => {
            // alert(res);
            return res
        }, err => {
            if (err.response && err.response.status === 401) {
                console.log(err.response);
                store.dispatch('alert/error', err.response.data.message ? err.response.data.message : err.response.data)
            } else if (err.response && err.response.data.errors) {
                for (var key in err.response.data.errors) {
                    if (err.response.data.errors.hasOwnProperty(key)) {
                        store.dispatch('alert/error', err.response.data.errors[key].join(','))
                    }
                }
            }
    
            return Promise.reject(err)
        })
}

function  getDepartments(data){
    return axios.get(
        `/departments`, {
            params:data
        }).then((res) => {
            return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}


function getPoses(data){
    return axios.get(
        `/pos`, {
            params:data
        }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}
function getPos(data){
    return axios.get(
        `/pos/${data.id}`).then((res) => {
        return res.data
    }).catch((err) => {
        console.log(err.response);
        /*if (err.response.status === 404) {
            store.dispatch('logoutUser').then(() => {
                Router.replace({path: '/auth/login'});
                store.dispatch('terminalReset');
            })
        }*/
        return Promise.reject(err)
    })
}

function  setup(data){
    return axios.patch(
        `/pos/${data.id}/setup`, {
            params:data
        }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}


