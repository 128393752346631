export default  {
    beforeMount: function (el, binding, vnode) {
        window.event = function (event) {
            if (!(el == event.target || el.contains(event.target) )) {
                binding.value( el)
                binding.value(event )
            }
        };
        document.body.addEventListener('click', window.event)
    },
    unmounted: function (el) {
        document.body.removeEventListener('click', window.event)
    },
};
