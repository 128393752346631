<template>
	<transition name="fade-scale">
		<div id="confirmation-modal" class="confirmation-modal c-modal-backdrop-wr" v-if="isVisible">
			<div class="c-modal">
				<div class="window">
					<img class="close-btn" src="../../assets/img/close.svg" alt="close" @click="close">
					<!--<h2 style="margin-top: 0">{{ title }}</h2>-->
					<div class="message">{{ message }}</div>
					<div class="btns">
						<button class="def-btn cancel-action-btn" @click="_cancel" v-if="hasCancelBtn">{{ cancelButton }}</button>
						<button class="def-btn main-action-btn" @click="_confirm">{{ okButton }}</button>
					</div>
				</div>
			</div>
			<div class="c-modal-backdrop"></div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'ConfirmationModalComponent',
	props: {
		hasCancelBtn: {
			default: true,
			required: false
		}
	},
	components: {},
	data() {
		return {
			isVisible: false,
			title: undefined,
			message: undefined,
			okButton: undefined,
			cancelButton: 'Нет',
			
			// Private variables
			resolvePromise: undefined,
			rejectPromise: undefined,
		}
	},
	computed: {},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {
		open() {
			this.isVisible = true
		},
		close() {
			this.isVisible = false
		},
		show(opts = {}) {
			this.title = opts.title;
			this.message = opts.message;
			this.okButton = opts.okButton;
			if (opts.cancelButton) {
				this.cancelButton = opts.cancelButton
			}
			// Once we set our config, we tell the popup modal to open
			this.open();
			// Return promise so the caller can get results
			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			})
		},
		
		_confirm() {
			this.close();
			this.resolvePromise(true);
		},
		
		_cancel() {
			this.close();
			this.resolvePromise(false);
		},
	},
	watch: {
		isVisible: function(res) {
			if (res) {
				document.body.classList.add("modal-open");
			} else {
				setTimeout(() => {
					document.body.classList.remove("modal-open");
				}, 300)
			}
		},
	}
}
</script>

<style lang="less" scoped>
/* css class for the transition */
.fade-scale-enter-active, .fade-scale-leave-active {
	transition: all 0.3s;
	.window {
		transition: all 0.3s;
	}
}
.fade-scale-enter-from,
.fade-scale-leave-to {
	opacity: 0;
	.window {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}

.c-modal-backdrop-wr {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	.c-modal {
		overflow-x: auto;
		display: flex;
		flex-direction: column;
		z-index: 1041;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		.window {
			background: #fff;
			border-radius: 5px;
			box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
			width: 500px;
			height: auto;
			min-height: 150px;
			margin-left: auto;
			margin-right: auto;
			padding: 1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
			flex-wrap: wrap;
			position: relative;
			box-sizing: border-box;
			.close-btn {
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;
			}
			.message {
				margin-bottom: 30px;
				color: #3F8EC5;
				font-size: 42px;
				text-align: center;
				font-weight: 400;
			}
			.btns {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				justify-content: center;
				width: 100%;
				button {
					width: 100%;
				}
			}
		}
	}
	.c-modal-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1040;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 0;
	}
}
</style>
