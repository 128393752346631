<template>
    <div class="wr">
	    <modal-component ref="modal" :custom-class="'ss'">
		    <div id="add-edit-item-modal" class="add-edit-container" >
			    <h1 v-if="action === 'add'">Add new item</h1>
			    <h1 v-else>Edit item</h1>
			    <div class="block top-block ">
				    <img v-if="design === 'ecruise'" :src="selectedItem.photo_url || require('../../assets/img/dinner-serving-dish-svgrepo-com.png')">
				    <img v-else-if="design === 'margaritaville'" :src="selectedItem.photo_url || require('../../assets/img/72category.svg')">
				    <div class="top-block-text">{{ selectedItem.name }}</div>
			    </div>
			    <div class="block price-block">
				    <div class="text price-text">Price:</div>
				    <div class="price">${{ parseFloat(selectedItem.price).toFixed(2) }}</div>
			    </div>
			    <div class="block quantity-block">
				    <div class="text quantity-text">Quantity:</div>
				    <div class="changer">
					    <button @click="count > 1 ? count-- : null">
						    <img v-if="design === 'ecruise' && selectedItem.status === 1" src="@/assets/img/minus-big.svg">
						    <img v-else-if="design === 'margaritaville' && selectedItem.status === 1" src="@/assets/img/btninmodal-m-big.svg">
					    </button>
					    <span>{{count}}</span>
					    <button @click="count++">
						    <img v-if="design === 'ecruise' && selectedItem.status === 1" src="@/assets/img/plus-big.svg">
						    <img v-else-if="design === 'margaritaville' && selectedItem.status === 1" src="@/assets/img/btninmodal-plus-m-big.svg">
					    </button>
				    </div>
			    </div>
			
			    <div class="condiments" v-if="selectedItem.condiments_parsed && selectedItem.condiments_parsed.length">
				    <div class="condiments-title">
					    Condiment(s):
				    </div>
				    <div class="condiments-list-wrapper">
					    <div class="condiments-item" v-for="(i,index) in selectedItem.condiments_parsed">
						    <div class="item-content">
							    <div class="left" @click="i.type === 2 ? checkItems(i, false) : null">
								    <img v-if="i.type === 1" class="checkbox-image" :src="require('../../assets/img/checked-checkbox.svg')" alt="checkbox-image">
								    <img v-else class="checkbox-image" :src="i.checked ? require('../../assets/img/split-order-checkbox-on.svg') : require('../../assets/img/split-order-checkbox-off.svg')" alt="checkbox-image">
								    <div class="item-title">{{i.name}}</div>
							    </div>
							    <div class="right">
								    <div class="counter" v-if="i.type === 2">
									    <button @click="i.checked ? changeCondimentsCount(i, 'minus') : null" :class="{'disabled': !i.checked}">
										    <img v-if="design === 'ecruise'" src="@/assets/img/minus-big.svg">
										    <img v-else-if="design === 'margaritaville'" src="@/assets/img/btninmodal-m-big.svg">
									    </button>
									    <span>{{i.count}}</span>
									    <button @click="i.checked ? changeCondimentsCount(i, 'plus') : null" :class="{'disabled': !i.checked}">
										    <img v-if="design === 'ecruise'" src="@/assets/img/plus-big.svg">
										    <img v-else-if="design === 'margaritaville'" src="@/assets/img/btninmodal-plus-m-big.svg">
									    </button>
								    </div>
								    <div class="price" :class="{'not-selected': !i.checked && i.type === 2}">
									    {{(i.price) ? '$'+parseFloat(i.price).toFixed(2) : 'FREE'}}
								    </div>
							    </div>
						    </div>
						    <div class="sub-item-content" v-if="i.type === 1">
							    <div class="sub-item" v-for="ii in i.items" @click="selectedItem.status === 1 ? checkItems(i, ii) : null" :class="{'disabled': selectedItem.status !== 1}">
								    <img class="sub-item-radio" :src="ii.checked ? require('../../assets/img/radio-selected.svg') : require('../../assets/img/radio-unselected.svg')" alt="checkbox-image">
								    <div class="sub-item-title">{{ii.name}}</div>
							    </div>
						    </div>
					    </div>
				    </div>
			    </div>
			
			    <div class="block discount-block" :class="{'has-border': !selectedItem.condiments_parsed || !selectedItem.condiments_parsed.length}" v-if="action !== 'add'">
				    <div class="text discount:-text">Discount:</div>
				    <div class="discount">{{ '$'+calcDiscount }}</div>
			    </div>
			    <div class="block total-block">
				    <div class="text total-text">total:</div>
				    <div class="total">${{ selectedItem.discount !== null ? (calcTotal-calcDiscount).toFixed(2) : calcTotal }}</div>
			    </div>
			    <div class="block seat-block ">
				    <div class="text seat-text">seat:</div>
				    <div class="changer">
					    <button @click="changeSeatNumber('decrease')" v-if="selectedOrder.table">
						    <img v-if="design === 'ecruise'" src="@/assets/img/minus-big.svg">
						    <img v-else-if="design === 'margaritaville'" src="@/assets/img/btninmodal-m-big.svg">
					    </button>
					    <!--<input type="number" v-model="seat" step="1" min="1" max="100" id="age"/>-->
					    <span>{{selectedOrder.table ? seat : 'N/A'}}</span>
					    <button @click="changeSeatNumber('increase')" v-if="selectedOrder.table">
						    <img v-if="design === 'ecruise'" src="@/assets/img/plus-big.svg">
						    <img v-else-if="design === 'margaritaville'" src="@/assets/img/btninmodal-plus-m-big.svg">
					    </button>
				    </div>
			    </div>
			    <div class="control-btns" :class="{'add': action === 'add' || isWaiterAssist || selectedOrder.items.length <= 1}">
				    <button v-if="action==='edit' && !isWaiterAssist && selectedOrder.items.length > 1" @click="delItem" class="def-btn delete-action-btn uppercase medium border-2">Delete</button>
				    <button @click="close" class="def-btn cancel-action-btn uppercase medium border-2">CANCEL</button>
				    <button @click="addEditItem" class="def-btn main-action-btn uppercase medium border-2">{{action === 'add' ? 'ADD ITEM' : "CONFIRM"}}</button>
			    </div>
		    </div>
	    </modal-component>
	    <item-deletion-notice-modal ref="item-deletion-notice-modal" :selectedItem="selectedItem" @confirmDelete="confirmDelete"></item-deletion-notice-modal>
    </div>
</template>
<script>
import ModalComponent from "@/components/_shared/Modal";
import ItemDeletionNoticeModal from "@/components/_modals/ItemDeletionNoticeModal";
import CancelOrderModal from "@/components/_modals/CancelOrderModal";

export default {
	name: 'AddEditItemModal',
    components: {ModalComponent,ItemDeletionNoticeModal,CancelOrderModal},
    props: {
	    selectedOrder: {
            type: Object,
            require: true
        },
	    selectedItem: {
            type: Object,
            require: true
        },
        action: {
            type: String,
            require: true,
            default: 'add'
        },
    },
    emits:['addEditItem','deleteItem'],
    data() {
        return {
            seat: null,
	        count: 1,
	        showCancelOrderModal: false,
        }
    },

    computed: {
	    design() {
		    return this.$store.state.UI
	    },
        total() {
            return ((this.selectedItem.count * this.selectedItem.price) - (this.selectedItem.discount ? this.selectedItem.discount : 0)).toFixed(2)
        },
	    isWaiterAssist() {
		    return this.$store.state.auth.user.user.role === 'waiter assist'
	    },
	    calcTotal() {
			let total = 0;
		    if (this.selectedItem.condiments_parsed && this.selectedItem.condiments_parsed.length) {
			    this.selectedItem.condiments_parsed.forEach((o) => {
				    if (o.type === 2 && o.checked) {
					    total += o.count*o.price
				    }
			    })
		    }
			
			return parseFloat((this.selectedItem.price*this.selectedItem.count) + total).toFixed(2)
	    },
	    calcDiscount() {
		    if (this.selectedItem.percentage_discount) {
				return ((this.selectedItem.price*this.selectedItem.count)*this.selectedItem.percentage_discount/100).toFixed(2)
		    } else if (this.selectedItem.amount_discount) {
				return ((this.selectedItem.price*this.selectedItem.count)-this.selectedItem.amount_discount).toFixed(2)
		    } else {
				return '0.00'
		    }
	    }
    },
	mounted() {
		this.emmiter.on('closeModal', () => {
			if (this.$refs['modal']) {
				this.close();
			}
		});
	},
	beforeUnmount() {
		this.emmiter.off('closeModal');
	},
    methods: {
        openModal() {
			if (this.selectedItem.seat) {
				this.seat = this.selectedItem.seat;
			} else {
				this.seat = this.selectedOrder.seat;
			}
			this.count = this.selectedItem.count;
            this.$refs['modal'].show();
        },
        addEditItem() {
            let newItems = {
	            ...this.selectedItem,
	            count: this.count,
	            seat: this.seat,
	            total: this.total,
            };
            this.$emit('addEditItem', newItems);
            this.close();
        },
        close() {
            this.$refs['modal'].close()
            this.seat = 1;
	        this.count = 1;
        },
        delItem() {
			if (this.selectedItem.status !== 1) {
				this.$refs['item-deletion-notice-modal'].openModal();
			} else if (this.selectedOrder.items.length === 1) {
				this.openCancelOrderModal()
			} else {
				this.confirmDelete();
			}
        },
	    confirmDelete() {
		    this.$emit('deleteItem',this.selectedItem)
		    this.close();
	    },
		changeSeatNumber(type) {
			if (type === 'decrease') {
				this.seat > 1 ? this.seat -= 1 : null
			} else {
				this.seat += 1;
			}
		},
	    openCancelOrderModal() {
		    this.showCancelOrderModal = true
		    this.$nextTick(() => {
			    this.$refs['cancel-order-modal'].openModal(this.selectOrder);
		    })
	    },
	    checkItems(parent, child) {
		    if (child) {
			    this.selectedItem.condiments_parsed.forEach((o) => {
				    if (parent.id === o.id) {
					    o.items.forEach(oo => {
						    o['checked'] = false;
						    oo['checked'] = false;
						    if (oo.id === child.id) {
							    o.checked = true;
							    oo.checked = true;
						    }
					    })
				    }
			    })
		    } else {
				parent.checked = !parent.checked;
				if (!parent.checked) {
					parent.items.forEach((o) => {
						o.checked = false;
					})
				} else {
					parent.items[0].checked = true;
				}
		    }
	    },
	    changeCondimentsCount(data, type) {
			if (type === 'minus') {
				data.count > 1 ? data.count-- : null
			} else {
				data.count++;
				this.$forceUpdate();
			}
	    },
    }
}
</script>

<style lang="less" scoped>
.wr {
	:deep(.ss) {
		.window {
			padding: 0;
		}
	}
}
#add-edit-item-modal {
	width: 480px;
	background-color: white;
	padding: 15px;
	h1 {
		font-weight: normal;
		font-size: 42px;
		text-align: center;
		// color: var(--theme_color);
		margin-top: -10px;
	}
	.text {
		font-weight: normal;
		font-size: 14px;
		// line-height: 148%;
		align-items: center;
		text-align: right;
		text-transform: uppercase;
		color: #000;
		
	}
	.block {
		// border-bottom: 2px solid rgba(53, 131, 197, .2);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 47px;
		&:not(.top-block) {
			div {
				&:last-child {
					width: 35%;
					text-align: right;
					justify-content: flex-end;
				}
			}
		}
	}
	.top-block {
		justify-content: flex-start;
		height: 108px;
		img {
			max-width: 156px;
			max-height: 108px;
			margin-right: 30px;
		}
		.top-block-text {
			color: #1A141F;
			font-weight: 600;
			font-size: 18px;
		}
	}
	.quantity-block, .price-block {
		border-top: 1px solid #e0e0e0;
	}
	.has-border {
		border-top: 1px solid #e0e0e0;
	}
	.total-block {
		border-top: 2px solid #e0e0e0;
		div {
			font-weight: bold;
		}
	}
	.quantity-block, .seat-block {
		height: 71px;
		button {
			border: none;
			background: white;
			cursor: pointer;
			&:last-of-type {
				padding-right: 0;
			}
		}
		.changer {
			display: flex;
			align-items: center;
			span {
				padding: 0 15px;
				background: #ECF4F9;
				border-radius: 5px;
				color: #1C282D;
			}
		}
	}
	.seat-block {
		margin-bottom: 20px;
	}
	
	.condiments {
		div {
			box-sizing: border-box;
		}
		.condiments-title {
			color: #1C282D;
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 10px;
		}
		.condiments-list-wrapper {
			max-height: 150px;
			overflow-y: auto;
			.condiments-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				width: 100%;
				// border-bottom: 1px solid black;
				.item-content {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: nowrap;
					border-bottom: 1px solid #e0e0e0;
					padding: 10px;
					.left {
						display: flex;
						flex-wrap: nowrap;
						justify-content: flex-start;
						align-items: center;
						.checkbox-image {
							margin-right: 10px;
						}
						.item-title {
							font-size: 16px;
							font-weight: 400;
							color: #000;
						}
					}
					.right {
						display: flex;
						flex-wrap: nowrap;
						justify-content: center;
						align-items: center;
						.counter {
							display: flex;
							align-items: center;
							margin-right: 10px;
							button {
								border: none;
								background: white;
								cursor: pointer;
								&.disabled {
									filter: invert(32%) brightness(1.6);
									cursor: not-allowed;
								}
								img {
									width: 20px;
									height: 20px;
								}
							}
							span {
								padding: 0 5px;
								// background: #ECF4F9;
								border-radius: 5px;
								color: #1C282D;
							}
						}
						.price {
							&.not-selected {
								color: red
							}
						}
					}
				}
				.sub-item-content {
					width: 100%;
					padding-left: 10%;
					.sub-item {
						width: 100%;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: wrap;
						border-bottom: 1px solid #e0e0e0;
						padding: 10px;
						&.disabled {
							opacity: 0.6;
							cursor: not-allowed;
						}
						.sub-item-radio {
							margin-right: 10px;
						}
						.sub-item-title {
							font-size: 16px;
							font-weight: 400;
							color: #000;
						}
					}
				}
				
			}
		}
	}
	
	.control-btns {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		button {
			width: 30%;
		}
		&.add {
			button {
				width: 45%;
			}
		}
		
	}
}

@media (max-width: 1200px) {
	#add-edit-item-modal {
		h1 {
			font-size: 36px;
		}
	}
}
@media (max-width: 850px) {
	#add-edit-item-modal {
		min-width: 480px;
	}
}
</style>
