import axios from "axios";

export const orderService = {
    getOrders,
    createNewOrder,
    closeOrder,
    cancelOrder,
    updateOrder,
    getOccupiedTables,
    printInvoiceOrder,
    downloadInvoiceOrder,
    getUsers,
    getPosUsers,
    getCheckPrint,
    deletionReason
}

function getCheckPrint(data) {
    return axios.post(`/orders/${data.id}/print`, {}, {
        responseType: 'arraybuffer'
    })
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function getOrders(data) {
    return axios.get(
        `/orders`, {
            params: data
        }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function getOccupiedTables(data) {
    return axios.get(
        `/orders/occupied-tables`, {
            params: data
        }).then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function createNewOrder(id, items, selected_order, is_split) {
    let data = {
        table: selected_order.table,
        payers: [],
        seat: selected_order.seat ? selected_order.seat : 1,
        items: items,
        is_split: is_split,
        with_discount: selected_order.with_discount ? selected_order.with_discount : false
    }
    if (selected_order.payers.length) {
        data.payers.push({user_profile_id: selected_order.payers[0].id})
    }
    if (selected_order.opened_by) {
        data['opened_by'] = selected_order.opened_by.id
    }
    return axios.post(
        `/orders`, data).then(res => {
            return res
        }).catch((err) => {
            return Promise.reject(err)
        })
}

function closeOrder(data) {
    return axios.post(
        `orders/${data.id}/close`,
        {
            payment_type: data.payment_type
        }).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}
function cancelOrder(data) {
    return axios.delete(
        `orders/${data.id}`)
    .then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function updateOrder(id, items, selected_order, change_table) {
    let data = {
        payers: [],
        items: items,
        seat: selected_order.seat ? selected_order.seat : 1,
        with_discount: selected_order.with_discount
    }
    console.log(selected_order.opened_by !== null);
    if (selected_order.payers.length) {
        selected_order.payers.forEach((o) => {
            data.payers.push({user_profile_id: o.id})
        })
    }
    if (change_table) {
        data['table'] = selected_order.table;
    }
    if (selected_order.seat) {
        data['seat'] = selected_order.seat;
    }
    if (selected_order.opened_by !== null) {
        data['crew_id'] = selected_order.opened_by.id
    }
    
    return axios.put(`/orders/${id}`, data).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}

function printInvoiceOrder(id) {
    return axios.post(
        `orders/${id}/print`,
    ).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}
function downloadInvoiceOrder(id) {
    return axios.post(
        `orders/${id}/download`,
    ).then(res => {
        return res
    }).catch((err) => {
        return Promise.reject(err)
    })
}
function getUsers(data) {
    return axios.get(`/user-profiles`, {
        params: data
    })
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}
function deletionReason(data) {
    return axios.patch(`/orders/items/${data.id}/comment`, {
        comment: data.comment
    })
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}
function getPosUsers(data) {
    return axios.get(`/users`, {
        params: data
    })
    .then((res) => {
        return res.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}
