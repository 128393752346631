<template>
	<aside id="sidebar" :class="design">
		<div class="menu">
			<!--<router-link to="/auth/login">
				<img class="icon" src="../../assets/img/sidebar-dashboard-icon.svg" alt="sidebar-dashboard">
				<span class="text">LOGIN</span>
			</router-link>
			<router-link to="/auth/terminal-setup">
				<img class="icon" src="../../assets/img/sidebar-dashboard-icon.svg" alt="sidebar-dashboard">
				<span class="text">TERMINAL SETUP</span>
			</router-link>-->
			<router-link to="/">
				<img class="icon" src="../../assets/img/posIcon.svg" alt="sidebar-dashboard">
				<span class="text">POS</span>
			</router-link>
			<router-link to="/checks" class="disabled" >
				<img class="icon" src="../../assets/img/checksIcon.svg" alt="sidebar-dashboard">
				<span class="text">Checks</span>
			</router-link>
			<router-link to="/dashboard" class="disabled">
				<img class="icon" src="../../assets/img/dashboardIcon.svg" alt="sidebar-dashboard">
				<span class="text">Dashboard</span>
			</router-link>
            <router-link to="/settings" class="disabled">
                <img class="icon" src="../../assets/img/settingsIcon.svg" alt="sidebar-dashboard">
                <span class="text">Settings</span>
            </router-link>
		</div>

	</aside>
</template>

<script>
export default {
	name: 'SidebarComponent',
	props: {},
	components: {},
	data() {
		return {}
	},
	computed: {
		design() {
			return this.$store.state.UI
		},
	},
	created() {},
	mounted() {},
	beforeUnmount() {},
	methods: {},
	watch: {}
}
</script>

<style lang="less" scoped>
#sidebar {
	display: none;
	width: 100px;
	position: absolute;
	top: 100px;
	height: calc(100% - 120px);
	overflow-x: auto;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	padding: 10px 0;
	&.ecruise {
		.menu {
			a {
				&.router-link-active {
					.icon {
						filter: invert(0%) sepia(6%) saturate(24%) hue-rotate(268deg) brightness(0%) contrast(107%);
					}
					.text {
						color: #000;
					}
				}
				.icon {
					filter: invert(43%) sepia(98%) saturate(301%) hue-rotate(162deg) brightness(97%) contrast(97%);
				}
				.text {
					color: #3F8EC5;
				}
			}
		}
	}
	&.margaritaville {
		.menu {
			a {
				&.router-link-active {
					.icon {
						filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(156deg) brightness(500%) contrast(108%);
					}
					.text {
						color: #fff;
					}
				}
				.icon {
					filter: invert(0%) sepia(97%) saturate(585%) hue-rotate(143deg) brightness(96%) contrast(99%);
				}
				.text {
					color: #017E8D;
				}
			}
		}
	}
	.menu {
		display: flex;
		justify-content: left;
		align-items: center;
		flex-wrap: wrap;
		&:first-child {
			a {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
				
				&:last-child {
					margin-top: 106px;
				}
			}
		}
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 78px;
			height: 78px;
			font-size: 10px;
			font-weight: 600;
			text-align: center;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			text-decoration: none;
			transition: all 200ms;
			&.disabled {
				pointer-events: none;
			}
			&.router-link-active {
				width: 90px;
				height: 78px;
				.text {
					color: #ffffff;
				}
			}
			.text {
				font-weight: 600;
				font-size: 14px;
				line-height: 120%;
				transition: all 500ms;
			}
			.icon {
				margin-bottom: 4px;
				filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(124deg) brightness(108%) contrast(105%);
			}
		}
	}
}

@media (max-width: 1200px) {
	#sidebar {
		top: 80px;
		height: calc(100% - 100px);
	}
}
@media (max-width: 850px) {

}

</style>
