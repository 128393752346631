const initialState = [];

export const alert = {
    namespaced: true,
    state: initialState,
    actions: {
        success({commit}, message) {
            commit('changeData', message);
        },
        changeData({commit}, message) {
            commit('success', message);
        },
        error({commit, state}, message) {
            let index = state.findIndex((o) => {return o.message === message});
            if (index === -1) {
                commit('error', message);
            }
        },
        warning({commit}, message) {
            commit('warning', message);
        },
        clear({commit}, data) {
            commit('clear', data);
        }
    },
    mutations: {
        success(state, message) {
            state.push({
                type: 'alert-success',
                message: message
            });
        },
        error(state, message) {
            state.push({
                type: 'alert-danger',
                message: message
            });
        },
        warning(state, message) {
            state.push({
                type: 'alert-warning',
                message: message
            });
        },
        clear(state, data) {
            state.splice(state[data], 1);
        }
    },
    getters: {
        alertData(state) {
            return state
        }
    }
};
