<template>
	<modal-component ref="modal">
		<div id="print-check-modal">
			<!--<embed :src="checkImage+'#toolbar=0&navpanes=0&scrollbar=0'" style="overflow:hidden; width: 700px; height: 500px"/>-->
			<h1 class="modal-title">Print check</h1>
			<div class="container">
				<div class="header">
					<h3 class="sub-header">{{ getPosData.department.name }}</h3>
					<p class="header-date">DATE: {{date}}</p>
				</div>
				<div class="configured-header">{{getPosData.department.check_header}}</div>
				
				<div class="department-info">
					<p class="text">Payer: {{selectOrder.payers.length ? `${selectOrder.payers[0].first_name} ${selectOrder.payers[0].last_name}` : 'Not specified yet'}}</p>
					<p class="text">Waiter: {{ selectOrder.opened_by.name }}</p>
					<p class="text">UDID: 6b3aff05-8e64-4edf-8531-9e088abf01a7</p>
				</div>
				<div class="check-info">
					<div class="items-list">
						<div class="item-wrapper" v-for="(i,index) in selectOrder.items" :key="index">
							<div class="item">
								<div class="name">{{ i.name }} <span v-if="i.count > 1">(x{{i.count}}) </span></div>
								<div class="sum">{{ '$' + i.price.toFixed(2) }}</div>
							</div>
							<div class="condiments" v-if="i.condiments_parsed.length">
								<div class="item" v-for="ii in i.condiments_parsed">
									<div class="name">- {{ ii.name }} <span v-if="ii.count > 1">(x{{ii.count}}) </span></div>
									<div class="sum">{{ '$' + ii.price.toFixed(2) }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="info">
						<div class="subtotal" >
							<div class="title">subtotal:</div>
							<div class="sum">{{'$' + selectOrder.total_amount.toFixed(2)}}</div>
						</div>
						<div class="surcharge">
							<div class="title">surcharge:</div>
							<div class="sum">{{'$' + surcharge.toFixed(2)}}</div>
						</div>
						<div class="discount">
							<div class="title">discount:</div>
							<div class="sum">${{selectOrder.with_discount ? calcDiscount : '0.00'}}</div>
						</div>
						<div class="tax">
							<div class="title">tax:</div>
							<div class="sum">{{'$' + tax.toFixed(2)}}</div>
						</div>
						<div class="autogratuity">
							<div class="title">autogratuity:</div>
							<div class="sum">{{'$' + autogratuity.toFixed(2)}}</div>
						</div>
						<div class="total">
							<div class="title ">total:</div>
							<div class="sum">${{selectOrder.total_amount_with_discount ? selectOrder.total_amount_with_discount.toFixed(2) : selectOrder.total_amount.toFixed(2)}}</div>
						</div>
					</div>
				</div>
				<div class="configured-footer">
					{{getPosData.department.check_footer}}
				</div>
			</div>
			<div class="print">
				<div class="icon"><img src="@/assets/img/36printer.png" alt="printer-icon"></div>
				<div class="text">Kitchen Second Floor HP DeskJet 3755 MX</div>
			</div>
			<div class="control-btns">
				<button @click="close"  class="def-btn cancel-action-btn border-2 uppercase medium">CANCEL</button>
				<button @click="" class="def-btn main-action-btn border-2 uppercase medium disabled">PRINT</button>
			</div>
		</div>
	</modal-component>
</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import {orderService} from "@/_api/order.api";

export default {
    name: "PrintCheckModal",
    components:{ModalComponent,},
    props:{
	    selectOrder:{
            type:Object,
            require:true
        }
    },
    data(){
        return{
            subtotal: 0,
            surcharge:0,
            discount:0,
            tax:0,
            autogratuity:0,
            total:0,
            date:null,
	        checkImage: null
			
        }
    },
	computed: {
		getPosData() {
			return this.$store.state.auth.posData
		},
		calcDiscount() {
			let count = 0;
			this.selectOrder.items.forEach(o => {
				count += o.discount
			});
			return count.toFixed(2)
		}
	},
    methods:{
		getCheck(data) {
			orderService.getCheckPrint(data).then((res) => {
				console.log(res);
				const blob = new Blob([res], { type: 'application/pdf' });
				this.checkImage = URL.createObjectURL(blob);
				console.log(this.checkImage)
				// this.checkImage = 'data:image/jpeg;base64,' + btoa(res);
			})
		},
        openModal(data) {
            this.date = new Date().toLocaleString().slice(0,-3);
            this.subtotal = 0;
            this.surcharge = 0;
            this.discount = 0;
            this.tax = 0;
            this.autogratuity = 0;
            this.total = 0;
	        console.log(this.selectOrder);
            /*this.selectOrder.items.forEach(el =>{
                this.subtotal += +el.price
                el.surcharge  ? this.surcharge += +el.surcharge : this.surcharge = 0
                el.discount  ? this.discount += +el.discount : this.discount =0
                el.tax  ? this.tax += +el.tax : this.tax = 0
                el.autogratuity  ? this.autogratuity += +el.autogratuity : this.autogratuity = 0
                el.total  ? this.total += +el.total : this.total = 0
            })*/
	        // this.getCheck({id: data.id})
	        console.log(this.getPosData);
            this.$refs['modal'].show()
        },
        close() {
            this.$refs['modal'].close()
        },
        printCheck() {
            orderService.printInvoiceOrder(this.selectedOrderId)
            this.$refs['modal'].close()
        },
    },
    mounted() {}
}
</script>

<style scoped lang="less">
#print-check-modal {
	width: 500px;
	.modal-title {
		font-size: 42px;
		font-weight: normal;
		line-height: 56px;
		margin-top: 0;
	}
	.container {
		padding: 0 60px;
		margin-bottom: 40px;
		max-height: 300px;
		overflow-y: scroll;
		.header {
			margin-bottom: 20px;
			.sub-header{
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 32px;
				text-align: center;
				text-transform: uppercase;
				color: #1C282D;
				margin: 0 0 10px 0;
			}
			&-date{
				font-weight: normal;
				font-size: 14px;
				line-height: 148%;
				align-items: center;
				text-align: center;
				text-transform: uppercase;
				color: #000000;
				margin: 0;
			}
		}
		.configured-header {
			margin-bottom: 40px;
		}
		.department-info {
			margin-bottom: 40px;
			.text{
				text-transform: uppercase;
				margin: 0;
				text-align: left;
			}
		}
		.check-info {
			margin-bottom: 40px;
			.items-list {
				margin-bottom: 30px;
				.item-wrapper {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: wrap;
					// height: 30px;
					width: 100%;
					.item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-wrap: wrap;
						width: 100%;
					}
					.condiments {
						width: 100%;
						.item {
							padding-left: 10px;
						}
					}
				}
			}
			.info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				&>div {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					align-content: center;
					flex-wrap: nowrap;
					padding: 5px 0;
					&:not(:last-child){
						border-bottom: 1px solid rgba(0,0,0,.2);
					}
				}
				.total {
					font-weight: bold;
				}
				
				.title{
					font-size: 14px;
					text-transform: uppercase;
					color: #000000;
					// margin-left: 50%;
				}
				.sum {
					width: 30%;
					text-align: right;
				}
			}
		}
	}
	.print {
		display: flex;
		// width: 492px;
		height: 48px;
		border: 1px solid #3F8EC5;
		box-sizing: border-box;
		border-radius: 8px;
		align-items: center;
		justify-content: flex-start;
		// margin-top: 86px;
		padding: 0 10px;
		width: 80%;
		margin: 0 auto 20px auto;
		.icon {
			margin-right: 20px;
		}
	}
	.control-btns {
		width: 80%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		margin: 0 auto;
		button {
			width: 45%;
		}
	}
}
</style>
