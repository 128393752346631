<template>
	
	<transition>
		<div class="main-loader" v-if="loading">
			<img src="./assets/img/Ripple-1s-200px.svg" alt="loader">
		</div>
	</transition>
	<header-component v-if="!isAuthRoute"></header-component>
	<div class="wrapper">
        <the-alert></the-alert>
		<sidebar-component v-if="!isAuthRoute"></sidebar-component>
		<main id="content">
			<router-view></router-view>
		</main>
	</div>
	
	<teleport to="#confirmation-modal-target">
		<confirmation-modal-component ref="confirmation-modal" :has-cancel-btn="false"></confirmation-modal-component>
	</teleport>
</template>

<script>
import SidebarComponent from "@/components/_global/Sidebar";
import HeaderComponent from "@/components/_global/Header";
import ConfirmationModalComponent from "@/components/_shared/ConfirmationModal";
import ModalComponent from "@/components/_shared/Modal";
import TheAlert from "@/components/_global/TheAlert";
import Keyboard from "@/components/_shared/Keyboard";
export default {
	name: 'AppRoot',
	components: {
		Keyboard,
		TheAlert, ModalComponent, ConfirmationModalComponent, HeaderComponent, SidebarComponent},
	data() {
		return {
			loading: true
		}
	},
	computed: {
		design(){
			return this.$store.state.UI
		},
		isAuthRoute() {
			// using this param to completely delete from dom sidebar when user is unauthorized
			return this.$route.path.includes('auth')
		},
		isLoggedIn() {
			return this.$store.state.auth.status.loggedIn
		},
	},
	created() {
		setTimeout(() => {
			this.loading = false;
			document.body.classList.remove('loading');
		}, 1000);
		if (this.design === 'margaritaville') {
			require('@/assets/styles/margaritaville-design/margaritaville-design.less');
		} else {
			require('@/assets/styles/ecruise-design/ecruise-design.less');
		}
		
		this.emmiter.on('openConfirmationModal', (data) => {
			this.$refs['confirmation-modal'].show(data).then((res) => {
				console.log(res)
			})
		});
		this.emmiter.on('openModal', (data) => {
			this.$refs['modal'].show(data).then((res) => {
				console.log(res)
			})
		})

	},
	mounted() {},
	beforeUnmount() {
		this.emmiter.off('openConfirmationModal');
		this.emmiter.off('openModal');
	},
	methods: {},
	watch: {}
}

</script>

<style lang="less">
@import "assets/styles/inputs";
@import "assets/styles/btns";
@import "assets/styles/fonts";

.v-enter-active, .v-leave-active {
	transition: opacity 0.5s ease;
}
.v-enter-from, .v-leave-to {
	opacity: 0;
}

* {
	font-family: "OS";
}
div {
	box-sizing: border-box;
}
&::-webkit-scrollbar {
	height: 4px;
	width: 4px;
	background: gray;
	border-radius: 10px;
}
&::-webkit-scrollbar-thumb {
	background: lightgrey;
	border-radius: 10px;
}
html {
	scroll-behavior: smooth;
	// box-sizing: border-box;
	body {
		height: 100vh;
		width: 100vw;
		margin: 0;
		overflow-x: hidden;
		background-color: #ffffff;
		&.loading {
			overflow: hidden;
		}
		.main-loader {
			display: flex;
			justify-content: center;
			align-items: center;
			background: white;
			width: 100vw;
			height: 100vh;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 99999999999;
		}
		&.unauth-ui {
			background-color: #8dc7e1;
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-position: bottom;
			background-size: cover;
			#blast {
				.wrapper {
					height: 100%;
					#content {
						width: 100vw;
						margin-left: 0;
						background-color: unset;
					}
				}
			}
		}
		#sidebar, #nav {
			display: flex !important;
		}
		#blast {
			font-family: OS, sans-serif;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			text-align: center;
			color: #2c3e50;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			.wrapper {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-wrap: nowrap;
				height: calc(100vh - 100px);
				#content {
					width: calc(100vw - 112px);
					margin-left: 112px;
					height: 100%;
					border-top-left-radius: 15px;
					border-bottom-left-radius: 15px;
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	html {
		body {
			#blast {
				.wrapper {
					height: calc(100vh - 70px);
				}
			}
		}
	}
}
@media (max-width: 850px) {}

</style>
