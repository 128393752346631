<template>
	<modal-component ref="modal">
		<div id="item-deletion-notice" class="content-wrapper">
			<h1 class="title">Deletion reason</h1>
			<div class="content">
				<textarea class="input" v-model="noticeText"></textarea>
			</div>
			<div class="control-btns">
				<button @click="close" class="def-btn cancel-action-btn uppercase medium border-2">CANCEL</button>
				<button @click="deleteItem" class="def-btn main-action-btn uppercase medium border-2">OK</button>
			</div>
		</div>
	</modal-component>

</template>

<script>
import ModalComponent from "@/components/_shared/Modal";
import {orderService} from "@/_api/order.api";

export default {
	name: 'ItemDeletionNoticeModal',
	components: {ModalComponent},
	props: ['selectedItem'],
	emits: ['confirmDelete'],
	data() {
		return {
			noticeText: ''
		}
	},
	computed: {},
	mounted() {
	
	},
	methods: {
		openModal() {
			this.$refs['modal'].show();
			console.log(this.selectedItem)
		},
		close() {
			this.noticeText = '';
			this.$refs['modal'].close();
		},
		deleteItem() {
			orderService.deletionReason({
				id: this.selectedItem.id,
				comment: this.noticeText
			}).then((res) => {
				if (res) {
					this.$emit('confirmDelete');
					this.close();
				}
			})
		}
	}
}
</script>

<style lang="less">
	#item-deletion-notice {
		width: 400px;
		.title {
			// color: var(--theme_color);
			font-weight: 500;
		}
		.content {
			.input {
				width: 100%;
				border: 1px solid gainsboro;
				resize: none;
				height: 120px;
				border-radius: 4px;
				font-size: 16px;
				color: #202020;
				box-sizing: border-box;
				margin-bottom: 20px;
				&:focus {
					outline: none;
				}
			}
		}
		.control-btns {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
			width: 100%;
			.def-btn {
				width: 45%;
			}
		}
	}
</style>
