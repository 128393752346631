<template>
    <modal-component ref="modal">
        <div class="container">
            <div class="header">
                <div class="header-text">Select the printer</div>
            </div>
            <div class="selected-printer">
                    <div class="printer" v-for="i in selectedPrinter" >
                        <div class="icon"><img src="@/assets/img/36printer.png"></div>
                        <div class="circle" :class="i.state == 'Off' ? 'red': 'green'"></div>
                        <div class="state state-on" v-if="i.state === 'On'">On</div>
                        <div class="state state-off" v-else>Off</div>
                        <div class="name">{{ i.name }}</div>
                    </div>
            </div>
            <div class="printers">
                <div class="printer printer-header bold">
                    <div class="state">state</div>
                    <div class="name">name</div>
                </div>
                <div class="printer printer-block" v-for="i in printers" @click="selectPrinter(i)">
                    <div class="circle" :class="i.state == 'Off' ? 'red': 'green'"></div>
                    <div class="state state-on" v-if="i.state === 'On'">On</div>
                    <div class="state state-off" v-else>Off</div>
                    <div class="name">{{ i.name }}</div>
                </div>

            </div>

            <div class="buttons-navigate">
                <button  @click="close"  class="btn danger-btn">CANCEL</button>
                <button   @click="next" class="btn primary-btn">NEXT</button>
            </div>

        </div>
    </modal-component>

</template>

<script>
import ModalComponent from "@/components/_shared/Modal";

export default {
    name: "SelectThePrinterModal",
    components: {ModalComponent, },
    emits:['printFoodRunner','selectedPrinter'],
    props:{

    },
    data() {
        return {
            printers:[
                {
                    name:'Kitchen Second Floor HP DeskJet 3755 MX',
                    state: 'On'
                },
                {
                    name:'Kitchen Second Floor HP DeskJet 3755 MX',
                    state: 'Off'
                }
            ],
            selectedPrinter:[]
        }
    },
    methods: {
        openModal() {
            this.$refs['modal'].show()
        },
        close() {
            this.$refs['modal'].close()
        },
        next(){
            if(this.selectedPrinter.length >0){
                this.$emit('printFoodRunner')
                this.$refs['modal'].close()
            }
        },
        selectPrinter(el){
            this.selectedPrinter = []
            this.selectedPrinter.push(el)
            this.$emit('selectedPrinter',el)
        }


    }
}
</script>

<style scoped lang="less">

.container {
    .buttons-navigate {
        margin-top: 70px;

        .btn {
            width: 180px;
            height: 48px;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            text-transform: uppercase;
            border: 2px solid #3F8EC5;
            box-sizing: border-box;
            border-radius: 8px;
            margin: 0px 6px;

            &-primary {
                background: #FFFFFF;
                color: #3F8EC5;
            }

            &-danger {
                background: #EB262B;
                color: #FFFFFF;
            }

        }
    }

    .header {
        margin: 0 0 20px 0;

        &-text {
            font-weight: normal;
            font-size: 42px;
            line-height: 56px;
            color: #3F8EC5;
        }
    }
    .printers{
        margin-left: 30px;
        .bold{
            font-weight: bold;
            font-size: 12px;
            line-height: 148%;
            text-transform: uppercase;
        }
        .printer{
            width: 490px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-top:  1px solid rgba(53, 131, 197,.2);
            &:last-child{
                border-bottom: 1px solid rgba(53, 131, 197,.2);
            }
            height: 41px;

            &:active:not(:first-child){
                background: #8DC7E1;
            }
        }
        .printer-block{
            cursor: pointer;

        }

    }
    .selected-printer{
        margin: -25px 0 30px 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .printer{
            display: flex;
            align-items: center;
            margin-top: 40px;
        }


        .printer-name{
            .header{
                font-size: 16px;
                line-height: 148%;
                color: #000000;
            }
            .subheader{
                font-weight: normal;
                font-size: 12px;
                line-height: 148%;
                margin-top: -10px;
                text-align: left;
            }
        }

    }
    .state{
        margin-right: 15px;
    }
    .circle{
        height: 12px;
        width: 12px;
        border-radius: 100%;
        margin-right: 2px;
    }
    .red{
        background: #EB262B;;
        margin: 0 5px;
    }
    .green{
        background: #82B941;
        margin: 0 5px;
    }
    .buttons-navigate {
        margin-top: 24px;
        .btn{
            width: 176px;
            height: 48px;
        }
        .primary-btn {
            cursor: pointer;
            background: #3F8EC5;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            margin-left: 5px;
            color: #FFFFFF;
            border: none;
        }
        .danger-btn{
            cursor: pointer;
            background: #FFFFFF;
            border: 2px solid #EB262B;
            box-sizing: border-box;
            border-radius: 8px;
            margin-left: 5px;

            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            text-transform: uppercase;
            color: #EB262B;

        }
        button {
            margin: 5px;
        }
    }

}

</style>
