<template>
    <modal-component ref="modal" @onModalClose="onModalClose">
        <div class="cancel-order-container">
            <div class="header">
                <div class="text">Order is not empty!</div>
                <div class="text">Delete the order?</div>
            </div>
            <div class="control-btns">
                <button @click="close"  class="def-btn secondary-action-btn uppercase  medium border-2">NO</button>
                <button @click="cancelOrder" class="def-btn delete-action-btn uppercase medium border-2">YES</button>
            </div>
        </div>
    </modal-component>

</template>

<script>
import ModalComponent from "@/components/_shared/Modal";

export default {
    name: "CancelOrderModal",
	data() {
		return {}
	},
	emits: ['submitCancelOrder', 'onModalClose'],
    components: {ModalComponent},
    methods: {
		onModalClose() {
			this.$emit('onModalClose');
		},
        openModal() {
            this.$refs['modal'].show();
        },
        close() {
            this.$refs['modal'].close()
        },
		cancelOrder() {
			this.$emit('submitCancelOrder');
			this.close();
		}
    }
}
</script>

<style scoped lang="less">
.cancel-order-container {
	.header {
		margin: 30px 24px 68px 24px;
		color: #e86d32;
		span {
			&.ripple {
				background-color: rgba(221, 82, 82, 0.7);
			}
		}
		.text {
			font-style: normal;
			font-weight: normal;
			font-size: 42px;
			line-height: 56px;
			text-align: center;
		}
	}
	.control-btns {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		button {
			width: 49%;
		}
	}
}

</style>
